@import "../../../node_modules/react-input-range/lib/css/index.css";

.ReactCollapse--collapse {
  transition: height 0.3s ease-in-out;
}

.input-range__label--max,
.input-range__label--min {
  display: none;
}

.input-range__label-container {
  background: white;

  &:after {
    content: "m";
  }
}

.input-range__label--value {
  top: auto;
  bottom: -30px;
  //font-family: MontserratRegular;
  font-weight: 400;
  font-size: 11px;
  color: $gray;
}

.input-range__slider-container,
.input-range__track {
  transition: none;
}

.input-range__slider {
  width: 14px;
  height: 14px;
  border-radius: 7px;
  background-color: $green;
  border: none;
  margin-left: -7px;
  margin-top: -8px;

  &:active {
    transform: none;
  }
}

.input-range__track {
  height: 2px;
  background-color: rgba($gray, 0.3);
  border-radius: 1px;
}

.input-range__track--active {
  background-color: $green;
}
.bottom-padding {
  padding-bottom: 36px !important;
}
.plans--week {
  margin-top: 39px;
  // margin-bottom: 20px;
  &--fixheight {
    margin-top: 0;
    min-height: 150px;
  }

  @media (max-width: $breakpoint-mobile) {
    padding-bottom: 20px !important;
  }
}
#beacon-container {
  visibility: hidden;
  &.active {
    visibility: visible;
  }
}
.BeaconFabButtonFrame {
  // width: 40px !important;
  // height: 40px !important;
  // visibility: hidden;

  &.showHelper {
    visibility: visible;
  }

  @media (max-width: $breakpoint-mobile) {
    bottom: 50px !important;
    right: 5px !important;
    transform: scale(0.4) !important;
  }
}
