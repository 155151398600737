.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-size: cover;
  background-position: center;
  max-width: 100vw;
  position: relative;
  // overflow: hidden;

  &.noScroll {
    max-height: 700px !important;
    overflow: hidden !important;
  }
  &.auth {
    align-items: normal;
  }
  &.sign-up {
    align-items: normal;
  }
  @media print {
    max-width: 100%;
    width: 100%;
    margin: 0;
    min-height: 100% !important;
  }
}



.container {
  width: 100%;
  height: 100%;
  flex-grow: 1;
  max-width: 1000px;
  padding: 20px 0 60px;
  z-index: 0;
  margin: 0 auto;
  &.lessPadding {
    padding: 0 0 60px;
  }
  &.morePadding {
    padding: 20px 0 60px;
  }

  &.wo-spacing {
    padding: 0 !important;
    margin: 0 !important;
  }

  @media print {
    padding-top: 30px !important;
  }

  &.fluid-full {
    @media (max-width: 767px) {
      width: 100%;
    }
  }

  &.fluid {
    max-width: 100%;
  }

  &.wide {
    max-width: 1200px;

    @media screen and (min-width: 2000px) {
      max-width: 1920px;
    }
  }

  &.medium {
    max-width: 1080px;
  }

  &.center {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: 1260px) {
    width: calc(100% - 80px);
    padding: 20px 40px 40px;

    &.lessPadding {
      padding: 0 40px 40px;
      &.full {
        padding: 0;
        width: 100%;
        margin-top: 0;
      }
    }
    &.morePadding {
      padding: 20px 40px 40px;
    }
  }

  @media (max-width: 767px) {
    width: calc(100% - 40px);
    padding: 30px 20px 93px;

    &.lessPadding {
      padding: 0 20px 93px;
      &.full {
        padding: 0;
        width: 100%;
        margin-top: 0;
      }
    }
    &.morePadding {
      padding: 20px 20px 93px;
    }
  }
}
.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  h2 {
    min-width: 25%;
    font-size: 24px;
  }
}

.header {
  z-index: 5;
  position: sticky;
  top: 0;
  transition: 0.4s ease-in-out;
  width: 100vw;
  background-color: white;

  &--left {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 33px;

    
    @media (max-width: 767px) {
      gap: 0;
    }
  }

  &--close {
    display: none;
  }

  &--hidden {
    visibility: hidden;
  }
  &--absolute {
    position: absolute;
  }
  &__slide {
    &--in {
      top: 0;
      visibility: visible;
    }
    &--out {
      top: -100px;
      visibility: hidden;
    }
  }
  &.go-to-back {
    z-index: 0;
  }
  @media print {
    display: none;
  }

  &.menu-active {
    @media (max-width: 767px) {
      background: white;

      .logo {
        .logo-default,
        .logo-mobile {
          display: none !important;
        }

        .logo-black {
          display: block !important;
        }
      }
    }
  }
  .header-with-loginas {
    display: flex;
    flex-direction: column;
  }
  .header-content {
    // width: 100%;
    max-width: 1200px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    background-color: white;

    box-shadow: 0px 32px 24px -24px rgba(0, 0, 0, 0.05);
    @media (max-width: 1260px) {
      padding: 0 40px;
      height: 85px;
    }

    @media screen and (min-width: 2000px) {
      max-width: 1920px;
    }

    @media (max-width: 767px) {
      padding: 0 20px;
      height: 56px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }

    .logo {
      .logo-black,
      .logo-mobile {
        display: none;
      }

      @media (max-width: 767px) {
        order: 2;
        transform: translateX(-30px);

        .logo-mobile {
          display: block;
        }

        .logo-default {
          display: none;
        }
      }

      img {
        height: 46px;
        margin-top: 5px;

        @media (max-width: 1260px) {
          height: 24px;
          margin-right: 15px;
        }
      }
    }

    .links {
      display: flex;
      button {
        background: transparent;
        border: none;
      }
      @media (max-width: 767px) {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background: #2E4741;

        height: 61px;
        box-shadow: 0 2px 20px 10px rgba(#244810, 0.1);
        padding: 0 30px;
        justify-content: space-between;

        &.hidden {
          display: none;
        }

        a,
        button {
          margin: 0 !important;
          height: 100%;
          align-items: center;
          justify-content: center;
          text-align: center;
          &.active {
            border-color: $green;
          }
          img {
            margin: 0 0 5px 0 !important;
          }
        }
      }

      @media (min-width: 768px) and (max-width: 1260px) {
        a,
        button {
          &:not(:last-child) {
            margin-right: 30px !important;
          }
        }
      }

      a,
      button {
        display: flex;
        align-items: center;
        text-decoration: none;
        color: $main-black;
        font-style: normal;
        font-weight: 700;

        /* identical to box height, or 75% */
        letter-spacing: 2px;
        text-transform: uppercase;
        font-size: 16px;
        line-height: 12px;
        // margin-left: 37px;

        @media (max-width: 767px) {
          color: white;
          font-size: 13px;
        }

        @media screen and (min-width: 2000px) {
          font-size: 20px;
        }

        img {
          height: 20px;
          margin-right: 15px;
        }

        .icon-active {
          display: none;
        }

        &.active {
          color: $green;

          .icon-default {
            display: none;
          }

          .icon-active {
            display: block;
          }
        }

        &:not(:last-child) {
          margin-right: 60px;
        }
      }
    }

    .user {
      position: relative;
      height: 56px;
      border-left: 2px solid black;
      padding-left: 30px;
      align-items: center;
      display: none;

      @media (min-width: 1261px) {
        display: flex;
      }

      @media (max-width: 767px) {
        padding-left: 0;
      }

      &.user-tablet {
        border-left: none;

        @media (min-width: 1261px) {
          display: none;
        }

        @media (min-width: 768px) and (max-width: 1260px) {
          display: flex;
        }

        .toggle-button {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          width: 18px;
          height: 14px;

          &:hover {
            cursor: pointer;
          }
          .svg-button {
            fill: $main-black;
            width: 18px;
            height: 14px;
            object-fit: cover;
          }
        }

        .user-block {
          margin-bottom: 20px;

          .avatar {
            width: 60px;
            height: 60px;
            border-radius: 30px;
            object-fit: cover;
          }
        }

        .user-menu {
          width: 250px;
          left: auto;
          right: -40px;
          border-bottom-right-radius: 0;
          transform: translateY(calc(100% + 13px));
        }
      }

      &.user-mobile {
        border-left: none;

        @media (max-width: 768px) {
          display: flex;
        }

        @media (min-width: 768px) {
          display: none;
        }

        .toggle-button {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: 18px;
          height: 14px;

          &:hover {
            cursor: pointer;
          }

          .svg-button {
            fill: $main-black;
            width: 18px;
            height: 14px;
            object-fit: cover;
          }
        }

        .user-block {
          margin-top: 20px;
          order: 1;
          justify-content: center;

          .avatar {
            width: 60px;
            height: 60px;
            border-radius: 30px;
            object-fit: cover;
          }

          .name {
            color: $main-black;
          }
        }

        .user-menu {
          height: calc(100vh - 86px);
          padding: 15px 20px;
          width: calc(100vw - 40px);
          transform: translateY(100%);
          right: 0;
          left: -20px;
          display: flex;
          flex-direction: column;
          font-size: 14px;
          background: #EEEEEE;
          .user-links {
            border-bottom: 2px solid rgba(white, 1);
           
            a,
            .subscription-link {
              color: $main-black;
              font-size: 14px;
            }
          }

          .button {
            height: 20px;
            background: transparent;
            padding: 0;
            width: auto;
            color: $main-black;

            .img-default {
              display: none;
            }

            .img-black {
              display: block;
            }
          }
        }
      }

      .user-block {
        display: flex;
        align-items: center;
        color: $gray;

        &:hover {
          cursor: pointer;
        }
      }

      .avatar {
        height: 40px;
        width: 40px;
        border-radius: 20px;
        object-fit: cover;

        @media screen and (min-width: 2000px) {
          width: 60px;
          height: 60px;
        }
      }

      .user-info {
        margin-left: 15px;
        margin-right: 30px;

        p {
          font-size: 12px;

          &:not(.name) {
            // font-family: MontserratMedium;
            font-weight: 500;
          }

          @media screen and (min-width: 2000px) {
            font-size: 16px;
          }
        }

        .name {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 15px;
          color: $main-black;
          margin-bottom: 2px;

          @media screen and (min-width: 2000px) {
            font-size: 20px;
            line-height: 22px;
          }
        }

        .name-wrapper {
          display: flex;
          align-items: center;
          gap: 5px;

          .icon {
            width: 14px;
            height: 14px;
          }
        }
      }

      .arrow {
        transition: 0.3s ease-in-out;
      }

      &.open {
        .user-menu {
          opacity: 1;
          visibility: visible;
        }

        // .arrow {
        //   transform: rotate(180deg);
        // }
      }

      .user-menu {
        transition: 0.3s ease-in-out;
        padding: 15px 30px 30px;
        position: absolute;
        bottom: 0;
        left: 0;
        transform: translateY(calc(100% + 22px));
        width: calc(100% - 30px);
        background-color: white;
        box-shadow: 0 16px 32px rgba(0, 0, 0, 0.1);
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        visibility: hidden;
        opacity: 0;

        .user-links {
          display: flex;
          flex-direction: column;
          border-bottom: 1px solid rgba(white, 0.15);
          // margin-bottom: 20px;

          a,
          .subscription-link {
            padding: 18px 0;
            display: flex;
            align-items: center;
            justify-content: center;
            text-decoration: none;
            color: $main-black;
            font-size: 15px;
            cursor: pointer;
            text-transform: uppercase;
            font-weight: 700;
            letter-spacing: 1px;

            & > img {
              width: 15px;
              height: 15px;
              margin-left: 5px;
              transform: translateY(-2px);
            }
          }
        }

        .button {
          height: 40px;
          font-size: 12px;
          text-transform: none;
          letter-spacing: 0;
          border: 1px solid rgba(white, 0.5);
          // font-family: MontserratSemiBold;
          font-weight: 600;
        }
      }
    }
  }
}
