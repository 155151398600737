$main-black: #0D0C0C;
$gray: #B9B8B8;
$light-gray: #eee;
$green: #2C854C;
$green-black: #2E4741;

$breakpoint-mobile-s: 375px;
$breakpoint-mobile-m: 475px;
$breakpoint-mobile: 768px;
$breakpoint-tablet: 993px;
$breakpoint-laptop: 1200px;

$green-100: #C4DBCF;
$green-200: #4F7862;
$green-300: #415B54;
$green-400: #3A5F4C;
$green-500: #284E3B;
$green-600: #334641;
$green-700: #517169;
$green-800: #47536A;