.small-uppercase {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 15px;
  letter-spacing: 2px;
}
p.light-text {
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 20px;
}
