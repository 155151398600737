.login-header {
  max-width: 100vw;
  height: 100px;

  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  .logo {
    width: 165px;
    cursor: pointer;
  }
  @media (max-width: $breakpoint-mobile) {
    height: 65px;
    padding: 11px 30px;

    .logo {
      width: 140px;
    }
  }
}
.signup-header {
  max-width: 100vw;
  height: 100px;
  @media (max-width: $breakpoint-mobile) {
    height: 60px;
  }
  background-color: white;
  .header-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1440px;
    padding: 25px 90px;
    margin: 0 auto;
    .logo {
      width: 165px;
    }
    .account {
      width: 42px;
    }
    @media (max-width: $breakpoint-mobile) {
      height: 100%;
      padding: 0 16px;
      .logo {
        width: 88px;
      }
      .account {
        width: 36px;
      }
    }
  }
}

.login-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  background-image: url("../images/login-bg.jpg");
  background-size: cover;
}

.signup-content {
  flex: 1;

 * {
    box-sizing: border-box;
  }
}
.signup-footer {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 23px;
  display: flex;
  @media (max-width: $breakpoint-mobile) {
    margin-top: 27px;
  }
  /* identical to box height, or 144% */
  text-align: center;

  color: #9C9C9C;
  .footer-left {
    display: flex;
    align-items: center;
    gap: 8px;
    padding-right: 8px;
    border-right: 1px #9C9C9C solid;
    text-decoration: none;
    color: #9C9C9C;
    @media (max-width: $breakpoint-mobile) {
      font-size: 12px;
    }
  }
  .footer-right {
    display: flex;
    gap: 8px;
    padding-left: 8px;
    & > a {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 23px;
      display: flex;

      /* identical to box height, or 144% */
      text-align: center;

      color: #9C9C9C;
      text-decoration: none;
      @media (max-width: $breakpoint-mobile) {
        font-size: 12px;
      }
    }
  }
}

.login-box {
  width: 100%;
  max-width: 460px;
  border-radius: 10px;
  padding: 80px;
  background: white;
  display: flex;
  flex-direction: column;
  margin: 50px 0;

  @media (max-width: $breakpoint-mobile) {
    margin: 0;
    padding: 25px 20px 50px 20px !important;
    width: 100vw;
    max-width: none !important;
    min-height: calc(100vh - 125px);
    border-radius: 0 !important;
    align-items: center;

    .form-buttons > * {
      text-align: center;
      max-width: 280px;
    }

    .button-limited {
      min-width: 100%;
    }
  }

  .section-header {
    min-width: 100%;
    margin-top: 0;
  }

  .input-container {
    width: 100%;
  }

  .form-buttons {
    display: flex;
    flex-direction: column;
    margin-top: 30px;

    & > *:not(:last-child) {
      margin-bottom: 20px;
      
    }

    & > * {
      @media screen and (min-width: 2000px) {
        font-size: 20px;
      }
    }
  }

  a {
    color: $green;
    text-decoration: underline;
  }

  .socials {
    width: 100%;
    margin-top: 60px;

    .links {
      display: flex;

      a {
        width: 50px;
        height: 50px;
        border-radius: 25px;
        background-color: rgba($green, 0.1);
        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width: $breakpoint-mobile-m) {
          width: 40px;
          height: 40px;
        }

        &:not(:last-child) {
          margin-right: 25px;

          @media (max-width: $breakpoint-mobile-m) {
            margin-right: 15px;
          }
        }
      }
    }
  }
}

.sub-loader {
  width: fit-content;
  margin: 200px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    margin: 10px auto;
  }
}

.action-button {
  margin-top: 15px;
  width: 215px;
  border: none;

  &.sign-up {
    width: 100%;
    max-width: 100%;
    background-color: $green;
    border-radius: 5px;
    text-transform: none;
    border: none;
  }
}
.save-button {
  margin: 15px auto 0;
  width: 215px;
  border-radius: 5px !important;
  border: none;
  font-weight: 600 !important;
  font-size: 15px !important;
  line-height: 20px !important;
  letter-spacing: 1px !important;
  @media (max-width: $breakpoint-mobile-m) {
    position: fixed;
    bottom: 70px;
    left: 0;
    right: 0;
    z-index: 2;
  }
}
.button-field {
  @media (max-width: $breakpoint-mobile-m) {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    width: 100%;
    height: 130px;
    background-color: white;
  }
}

.password-button {
  padding: 0;
  margin: 0 5px 0 0 !important;
  border: none;
  background: none;
  //font-family: MontserratRegular;
  font-weight: 400;
  color: $green;
}

.text-muted {
  color: $gray;
  //font-family: MontserratRegular;
  font-weight: 400;
}

.button {
  background-color: #000;
  height: 50px;
  // border-radius: 5px;
  border: none;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 15px;
  font-weight: 800;
  letter-spacing: 1px;
  text-transform: uppercase;

  img {
    margin-right: 15px;
  }

  &-outlined {
    background: white;
    color: $main-black;
  }

  &-transparent {
    background: transparent;
    padding: 0;
    margin: 0;
    border: none;
    width: auto;
    height: auto;

    img {
      margin: 0;
    }
  }

  &-primary {
    background: $green;
    border-color: $green;
    font-weight: 600;
  }

  &-green {
    background-color: $green-200;
    border-radius: 6px;
  }

  &-loader {
    margin-right: unset !important;
    width: 25px;
  }

  &-small {
    height: 40px;
    width: 160px;
    font-size: 13px;
  }

  &-limited {
    max-width: 250px;
  }

  &-text {
    width: fit-content;
    height: fit-content;
    background-color: transparent;
    border: none;
    color: $main-black;
    font-size: 16px;
  }

  &-link {
    height: auto;
    width: auto;
    text-transform: none;
    font-size: 14px;
    color: $green;
    letter-spacing: 0;
    text-decoration: underline;
    border: none;
    background: white;
  }

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }

  &-disabled {
    pointer-events: none;
    opacity: 0.3;
  }
}

.section-header {
  color: $main-black;
  font-size: 30px;
  line-height: 37px;
  margin-bottom: 30px;
  margin-top: 60px;

  &.spaceless {
    margin: 0;
  }

  @media (max-width: $breakpoint-laptop) {
    font-size: 26px;
  }

  @media (max-width: 767px) {
    margin-bottom: 15px;
    line-height: 27px;
    margin-top: 30px;
  }
}

.label {
  margin-bottom: 10px;
  // font-family: MontserratSemiBold;
  font-weight: 600;
  font-size: 14px;
}

.quote-container {
  // background-image: url("../images/common/quote-background.png");
  background-size: cover;
  background-position: center;
  // height: 390px;
  aspect-ratio: 1440/389;
  display: flex;
  justify-content: center;
  align-items: center;
  // margin-top: 100px;
  position: relative;
  width: 100%;
  @media (max-width: 1260px) {
    // margin-top: 85px;
    aspect-ratio: 8/4;
  }
  @media (max-width: 767px) {
    // margin: 56px 0 0 0;
    border-radius: 0;
    min-width: 100%;
  }
  @media (max-width: $breakpoint-mobile-m) {
    // margin: 56px 0 0 0;
    border-radius: 0;
    aspect-ratio: 8/4;
    max-height: 181px;
    min-width: 100%;
  }

  .quote {
    padding: 70px 0px 50px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-self: center;
    max-width: 1200px;
    margin: 0 20px;
    border-radius: 10px;
    font-weight: 800;
    min-height: 256px;
    row-gap: 20px;

    // aspect-ratio: 1/1;
    color: black;
    width: 100%;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.35);
    @media (max-width: 767px) {
      // margin: 56px 0 0 0;
      border-radius: 0;
      max-height: 80%;
      padding-bottom: 10px;
    }

    @media (max-width: 767px) {
      padding: 20px 0px;
      width: 100%;
    }

    & > .search-input-home {
      max-width: 408px;
      // margin-bottom: 78px;
      @media (max-width: 767px) {
        width: 100%;
      }
      & > img {
        width: 20px;
      }
      font-family: JosefinSans;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 14px;

      /* identical to box height, or 78% */

      color: #000000;

      & > .input-container > .wrapper {
        & > input {
          &,
          &::placeholder {
            font-family: JosefinSans;
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 14px;
            opacity: 1;
            /* identical to box height, or 78% */

            color: #000000;
          }
        }
      }
    }

    .text {
      // margin: 20px 0;
      font-size: 16px;
      font-family: JosefinSans;
      line-height: 10px;
      font-weight: 400;
      text-align: center;
    }

    .author {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 20px;
      margin: 0;
      display: flex;
      justify-content: center;
      font-style: italic;
      @media (max-width: 767px) {
        font-size: 16px;
      }
      // .author-name {
      //   margin-left: 15px;
      // }
    }
  }
}
.quote-info {
  width: 100%;
  &-content {
    background: #2e4741;
    display: flex;
    flex-direction: column;
    padding: 14px 10px;
    align-items: center;
    justify-content: center;
    row-gap: 15px;
    // width: 100%;
    font-family: JosefinSans;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 10px;
    color: white;
    /* identical to box height, or 62% */
    .text {
      font-family: JosefinSans;
      font-size: 16px;
    }
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    // max-width: 1000px;
    @media (max-width: $breakpoint-mobile) {
      padding: 10px 25px;
      text-align: center;
      line-height: 20px;
    }
  }
}
.plan-card {
  position: relative;

  &.with-caption {
    &::before {
      position: absolute;
      top: -40px;
      left: 0px;
      font-size: 22px;
      font-weight: 800;
      line-height: 30px;
      letter-spacing: 0.54px;

      color: #0d0c0c;
    }
  }
  &.this-week::before {
    content: "Latest Plans";
  }
  &.saved-plans::before {
    content: "Saved Plans";
  }
  // margin-top: 40px;
  &.create .plan-image {
    transition: 0.15s;
    border: 1px dashed $gray;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $gray;
    font-size: 64px;
    //font-family: MontserratRegular;
    font-weight: 400;

    &:hover {
      color: $main-black;
      border-color: $green;
    }
  }

  .plan-image {
    height: 150px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 5px;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    @media (max-width: 1260px) {
      height: 150px;
    }

    @media screen and (min-width: 2000px) {
      height: 200px;
    }

    @media (max-width: 767px) {
      height: 150px;
      border-radius: 10px;
    }

    @media (max-width: $breakpoint-mobile-m) {
      height: calc((100vw - 40px) * 9 / 16);
    }
  }
  .plan-tooltip-button {
    position: relative;
    width: 80%;

    .button {
      background: #fff !important;
    }

    .tooltip {
      position: absolute;
      right: 8px;
      top: 16px;
    }
  }
  .plan-name {
    // margin-top: 20px;
    // padding-right: 20px;
    display: flex;
    // flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;

    .button {
      text-align: left;
      width: 100%;
      display: flex;
      position: relative;

      background: rgba(255, 255, 255, 0.85);
      padding: 12px 28px;
      border-radius: 3.52381px;

      @media screen and (min-width: 2000px) {
        font-size: 20px;
      }
    }
  }
  .button {
    // margin-top: 20px;
    background: white;
    color: $main-black;
    border: none;
    // width: auto;
    // height: auto;
  }
}

.meal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
  position: relative;
  z-index: 35;
  padding-top: 30px;

  &_wrapper {
    display: flex;
    flex-direction: column;
  }

  &_days {
    width: 100%;
    top: 100%;
    left: 0;
    position: absolute;
    transform: translate(6px,0px);

    &__sticky {
      position: fixed;
      transform: translate(6px,0px);
    }

    @media screen and (max-width: 1150px) {
      display: none !important;
    }
  }

  &.sticky {
    margin-top: 100px;
    @media (max-width: (1260px)) {
      margin-top: 80px;
    }
    @media (max-width: ($breakpoint-mobile - 1)) {
      margin-top: 56px;
    }

    @media (max-width: $breakpoint-mobile-m) {
      margin-top: 55px;
      height: 135px;
    }
  }

  // @media (max-width: 1260px) {
  //   padding-top: 20px;
  //   margin-top: 49px;
  // }

  &::before {
    content: "";
    position: absolute;
    width: 100vw;
    height: calc(100% + 16px);
    background-color: #EEEEEE;
    z-index: -1;
    left: 50%;
    top: 0;
    transform: translateX(-50vw);
    @media (max-width: $breakpoint-mobile-m) {
      height: calc(100% - 3px);
      background-color: #2E4741;
    }
  }

  @media (max-width: 767px) {
    padding-top: 20px;
  }

  @media (max-width: $breakpoint-mobile-m) {
    flex-direction: column;
    gap: 20px;
    padding-top: 30px;
    padding-bottom: 0;
    height: 135px;
  }

  .header-container {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    // margin-left: -13px;

    & img {
      width: 19px;
      height: 19px;
    }

    &_active {
      cursor: default;
    }

    @media (max-width: $breakpoint-mobile-m) {
      width: 100%;
      margin: 0px;
      justify-content: center;
    }

    .section-header {
      margin: 0 15px;
      // font-family: AvenirHeavy;
      font-weight: 700;

      @media (max-width: $breakpoint-mobile-m) {
        margin: 0 6px;
        font-size: 18px;
      }
    }

    .button {
      img {
        height: 21px;

        @media (max-width: $breakpoint-mobile-s) {
          height: 23px;
        }
      }
    }
    .plan {
      position: relative;
      .plan-block {
        width: 238px;
        display: flex;
        cursor: pointer;
        justify-content: space-between;
        .arrow {
          margin-left: 12px;
        }
        .title {
          width: 220px;
          font-size: 20px;
          line-height: 26px;
          font-weight: 700;
          color: $green;
          white-space: nowrap;
          text-overflow: ellipsis;
          padding: 0;
          overflow: hidden;
          @media (max-width: $breakpoint-laptop) {
            font-size: 20px;
          }
          @media (max-width: $breakpoint-mobile-m) {
            font-size: 18px;
            width: 190px;
          }
        }
        // @media (max-width: $breakpoint-laptop) {
        //   width: 100%;
        // }
        @media (max-width: $breakpoint-laptop) {
          width: 208px;
        }
      }
      .plan-menu {
        overflow: scroll;
        scrollbar-width: none;
        -ms-overflow-style: none;
        position: absolute;
        width: 220px;
        max-height: 360px;
        left: -10px;
        top: 30px;
        background-color: white;
        visibility: hidden;
        display: flex;
        flex-direction: column;
        box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.1);
        border-radius: 7px;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        white-space: nowrap;
        overflow-x: hidden;
        @media (max-width: $breakpoint-laptop) {
          // left: 15px;
        }

        &.visible {
          z-index: 3;
          visibility: visible;
          // @media (min-width: ($breakpoint-mobile)) {
          //   position: relative;
          // }
        }
        &::-webkit-scrollbar {
          display: none;
        }
        .group-title {
          padding: 10px;
          margin: 0 0 5px 0;
          font-weight: 700;
          background-color: #ededed;
          font-size: 20px;
          @media (max-width: $breakpoint-mobile-m) {
            font-size: 18px;
          }
        }
        button {
          width: 100%;
          padding: 0 20px;
          text-align: left;
          margin: 7px 0;
          border: none;
          background-color: transparent;
          cursor: pointer;
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          .plan-title {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
    }

    .plan-detail-title {
      font-weight: 600;
      font-size: 30px;
      line-height: 30px;
      white-space: nowrap;
      text-overflow: ellipsis;

      @media screen and (max-width: 475px) {
        color: white;
      }
    }
  }

  .plan-detail_icon {
    width: 19px;
    height: 19px;

    @media screen and (max-width: 475px) {
      display: none;
    }
  }

  .buttons-container {
    @media (max-width: $breakpoint-mobile-m) {
      margin-top: 5px;
      width: 100%;
    }
    .buttons {
      display: flex;
      align-items: center;
      width: 100%;
      .action-button-container {
        justify-content: space-between;
        display: flex;
        width: 100%;
        align-items: center;

        @media (max-width: $breakpoint-mobile-m) {
          justify-content: center;
          gap: 30px;
        }

        .action-button {
          background: transparent;
          border: none;
          color: #000;
          width: auto;
          margin: 0 0 0 16px;
          padding-left: 16px;
          height: 20px;
          font-weight: 700;
          font-size: 13px;
          &.disabled {
            color: #bdbdbd;
          }
          
          img {
            margin: 0 auto;
            width: 25px;
            height: 21px;
          }
          @media (max-width: $breakpoint-mobile-m) {
            border: none;
            background-color: transparent;
            color: white;
            border-radius: 5px;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            padding: 0;
            margin: 0 6px;
            &.disabled {
              background-color: #bdbdbd;
            }
            img {
              margin: 0 auto;
              width: 25px;
              height: 25px;
            }
          }
        }
        .action-button:first-child {
          border: none;
        }
      }

      // .action-button {
      //   padding: 0 !important;
      //   background: transparent;
      //   margin: 0 !important;
      //   border: none;
      //   flex: 0 !important;
      //   margin-left: 8px !important;

      //   img {
      //     margin: 0;
      //     width: 30px;

      //     @media (max-width: 500px) {
      //       width: 25px;
      //     }
      //   }
      // }

      .button {
        width: auto;
        padding: 0 15px;
      }

      @media (max-width: 500px) {
        width: 100%;

        .button {
          flex: 1;
          width: auto;
        }
      }

      .button:first-child {
        margin-right: 10px;

        // @media (max-width: 500px) {
        //   margin-right: 0;
        // }
      }
    }
  }
}

.day {
  display: flex;
  flex-direction: column;

  @media (max-width: 450px) {
    &.word {
      text-transform: uppercase;
      transform: translate(10px, -34px);
      position: relative;
      z-index: 41;
      pointer-events: none;
    }
  }

 

  &.sticky {
    z-index: 10;
    @media (max-width: 1260px) {
      margin-top: 172px;
    }

    @media (max-width: ($breakpoint-mobile - 1)) {
      margin-top: 148px;
    }

    @media (max-width: $breakpoint-mobile-m) {
      margin-top: 162px;
    }
  }

  .progress {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 45px;
    z-index: -1;

    @media (max-width: 450px) {
      display: none;
    }

    .dot {
      width: 11px;
      height: 11px;
      border-radius: 6px;
      background-color: $green;
    }

    .line {
      left: 16px;
      position: absolute;
      width: calc(100% + 4px);
      border-bottom: 1px dashed rgba($green, 0.3);
    }
  }

  .date {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 100%;
    position: relative;

    // &:before {
    //   content: "";
    //   background-color: white;
    //   position: absolute;
    //   z-index: -1;
    //   height: 100%;
    //   right: 0;

    //   @media (max-width: $breakpoint-mobile) {
    //     right: -50px !important;
    //     left: -50px !important;
    //   }
    // }

    &.first {
      &:before {
        left: -15px;
      }
    }

    @media (max-width: 450px) {
      justify-content: center;
    }

    .name,
    .name-mobile {
      font-size: 22px;
      line-height: 22px;
      // font-family: AvenirHeavy;
      font-weight: 700;
      margin-right: 8px;
      color: #2E4741;
      border-radius: 50%;
      background-color: #fff;
      width: 39px;
      height: 39px;
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: 450px) {
        font-size: 20px;
        width: auto;
        color: black;
        margin-right: 15px;
      }
    }

    // @media (max-width: 450px) {
    //   .name {
    //     display: none;
    //   }
    // }

    @media (min-width: 451px) {
      .name-mobile {
        display: none;
      }
    }

    .number {
      // font-family: MontserratLightItalic;
      font-weight: 300;
      font-style: italic;
      font-size: 9px;
      text-align: center;
      letter-spacing: 0.6px;
      margin-top: 3px;

      @media (max-width: 450px) {
        // font-family: AvenirBook;
        font-weight: 400;
        font-size: 10px;
        letter-spacing: 1px;
      }
    }
  }
}

.counter {
  display: flex;
  align-items: center;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &-button {
    border: none;
    border-radius: 7px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #efefef;

    span {
      // font-family: MontserratMedium;
      font-weight: 500;
      font-size: 18px;

      @media screen and (min-width: 2000px) {
        font-size: 22px;
      }
    }

    &:hover {
      background: black;
      color: white;
      cursor: pointer;
    }
  }

  &-value {
    min-width: 12px;
    text-align: center;
    font-size: 14px;
    margin: 0 10px;
    // font-family: MontserratMedium;
    font-weight: 500;

    @media screen and (min-width: 2000px) {
      font-size: 22px;
    }
  }
}

.tooltip {
  position: relative;
  padding: 10px;
  margin: -10px;

  &.no-padding {
    padding: 5px;
    margin: -5px;
  }

  &-dots {
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin: -10px;

    &.no-padding {
      padding: 5px;
      margin: -5px;
    }

    &:hover {
      cursor: pointer;
    }

    * {
      min-width: 4px;
      min-height: 4px;
      border-radius: 2px;
      background-color: $main-black;

      &:not(:last-child) {
        margin-bottom: 3px;
      }
    }
  }

  &-extrabody {
    border-bottom: 1px solid rgba($gray, 0.2);
    margin-bottom: 15px;
    padding-bottom: 15px;
  }

  &-body {
    z-index: 200;
    transition: 0.2s ease-in-out;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    min-width: 110px;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 4px 10px 4px rgba(#254215, 0.06);
    border-radius: 10px;
    transform: translateX(calc(-100% + 26px));
    top: 45px;
    padding: 20px;
    display: flex;
    flex-direction: column;

    &.open {
      visibility: visible;
      opacity: 1;
    }

    &:after {
      position: absolute;
      transform: rotate(45deg);
      right: 15px;
      top: -9px;
      content: "";
      width: 15px;
      height: 15px;
      background: #ffffff;
      border-left: 1px solid rgba(0, 0, 0, 0.1);
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    }
  }

  &-button {
    // font-family: AvenirHeavy;
    font-weight: 700;
    font-size: 11px;
    letter-spacing: 1.7px;
    border: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: white;
    text-transform: uppercase;

    img {
      margin-right: 15px;
      margin-left: 0 !important;
      width: 20px;
      height: 20px;
    }

    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
}

.card-border.active .card {
  border: 1px solid $green;
}

.card {
  box-sizing: border-box;
  background-color: #EEEEEE;
  margin-top: 30px;
  padding: 5px;
  height: 200px;
  width: 100%;
  border: 1px solid transparent;
  display: flex;
  flex-direction: column;
  // box-shadow: 0 4px 10px 4px rgba(#254215, 0.06);
  position: relative;
  cursor: pointer;
  @media (max-width: 450px) {
    height: 110px;
    flex-direction: row;

    .tooltip {
      position: absolute;
      right: -20px;
      top: -80px;
      z-index: 1;
    }
  }

  &--empty {
    justify-content: center;
    overflow: hidden;
    padding: 0;

    .card-button {
      font-size: 11px;
      color: $green;

      img {
        transition: 0.3s ease-in-out;
        margin: 0;

        @media (max-width: 450px) {
          width: 45px;
        }
      }

      &:hover img {
        opacity: 0.7;
      }
    }

    @media (max-width: 450px) {
      box-shadow: none;
      border: 1px dashed rgba(0, 0, 0, 0.3);
    }
  }

  &-controls {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    @media (max-width: 450px) {
      justify-content: flex-start;
    }
  }

  &-body {
    flex-grow: 1;
    padding: 14px 0px 5px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 10px;

    // .counter {
    //   display: none;
    // }

    @media (max-width: 450px) {
      padding: 10px 30px 10px 15px;
      flex-direction: column;

      .counter {
        display: flex;
      }
    }
  }

  &-image {
    position: relative;
    background-size: cover;
    background-position: center;
    height: 104px;
    // border-radius: 9px 9px 0 0;

    @media (max-width: 450px) {
      min-width: 95px;
      height: 97px;
      // border-radius: 9px 0 0 9px;
    }

    .card-potrions {
      right: 0;
      top: 0;
      position: absolute;
      font-size: 12px;
      color: #979797;
      padding: 0 4.5px;
      height: 22px;
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0 8px 0 8px;
      min-width: 13px;
      // font-family: MontserratSemiBold;
      font-weight: 600;

      @media (max-width: 450px) {
        display: none;
      }
    }
  }

  &-name {
    margin-top: -4px;
    // font-family: MontserratSemiBold;
    font-weight: 400;
    font-size: 16px;
    line-height: 17px;
    // height: 30px;
    // max-height: 30px;
    text-align: center;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;

    @media (max-width: 450px) {
      text-align: start;
      font-size: 18px;
      line-height: 20px;
      height: auto;
      max-height: unset;
    }
  }

  &-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;

    @media (max-width: 450px) {
      margin-top: 10px;
    }
  }

  &-button {
    height: 100%;
    // font-family: MontserratSemiBold;
    font-weight: 600;
    font-size: 14px;
    color: $gray;
    border: none;
    background: white;

    &:hover {
      transition: 0.2s ease-in-out;
      cursor: pointer;
      color: $main-black;
    }
  }

  &-counter-button {
    border: 2px solid black;
    border-radius: 50%;
    width: 22px;
    height: 22px;
  }

  &-counter-value {
    font-weight: 600;
  }
}

.meal-pagination {
  position: relative;
  z-index: 20;
  transform: translateY(16px);

  @media (max-width: 450px) {
    z-index: 40;
  }

  &_sticky {
    z-index: 1;
    margin-top: 100px;
  }

  .pagination-buttons {
    position: absolute;
    top: 16px;
    right: 0;
    display: flex;
    height: 32px;
    z-index: 2;

    &.sticky {
      position: fixed;
      right: 40px;
      top: 207px;
      z-index: 11;
      @media (max-width: (1260px)) {
        right: 40px;
        top: 173px;
      }
      @media (max-width: ($breakpoint-mobile - 1)) {
        right: 10px;
        top: 150px;
      }

      @media (max-width: ($breakpoint-mobile-m - 1)) {
        top: 162px;
        left: 80%;
        width: 100vw;
      }
    }

    .disabled {
      opacity: 0.3;
      pointer-events: none;
    }

    @media (max-width: 450px) {
      justify-content: space-between;
      align-items: center;
      width: 230px;
      height: 51px;
      right: 50%;
      top: -44px;
      background-color: #fff;
      transform: translateX(50%);
      display: none;
    }

    button {
      background: none;
      border: none;
      padding: 0 15px;

      @media (max-width: 450px) {
        height: 32px;
        padding: 0 30px;
      }

      &:first-child img {
        transform: rotate(180deg);
      }

      img {
        &.hidden {
          display: none;
        }
      }
    }
  }
}

.fullscreen-modal {
  transition: 0.3s ease-in-out;
  visibility: hidden;
  opacity: 0;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 100vh;
  max-height: 100%;
  background-color: white;
  z-index: 99;
  overflow-y: scroll;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  .scrollable-layer {
    padding: 40px 0;
    display: flex;
    justify-content: center;
    min-height: calc(100% - 80px);

    @media (max-width: $breakpoint-mobile-m) {
      padding: 20px 0;
      min-height: calc(100% - 40px);
    }
  }

  &.visible {
    visibility: visible;
    opacity: 1;
  }

  .button-container {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    top: 40px;
    // left: 100%;
    // transform: translateX(-50%);
    z-index: 3;
    width: calc(100% - 80px);
    max-width: 1200px;

    // @media (max-width: $breakpoint-laptop) {
    //   margin-bottom: 20px;
    // }

    @media (max-width: $breakpoint-mobile) {
      width: calc(100% - 40px);
    }

    &.with-action {
      @media (max-width: $breakpoint-laptop) {
        justify-content: end;

        .close-button {
          width: 26px;
          height: 26px;
          background-color: black;
          border-radius: 100%;
          margin-bottom: 10px;
        }

        .mobile-action {
          display: flex;
        }
      }
    }

    .close-button {
      position: fixed;
      top: 20px;
      right: 20px;
      width: 26px;
      height: 26px;
      background-color: black;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
    }

    .mobile-action {
      display: none;
    }
  }

  .content {
    height: auto;
    max-height: 100%;
    width: 100%;
    padding: 80px 40px 0;
    max-width: 1200px;

    @media (max-width: $breakpoint-mobile) {
      padding: 60px 20px 0;
    }

    @media (max-width: $breakpoint-mobile-m) {
      padding: 80px 20px 0;
    }
  }
}

.add-recipe {
  margin: 0 !important;
  display: flex;

  .sidebar {
    min-width: 410px;
    background: #2E4741;
    z-index: 1;
    .section-header {
      height: 40px;
      margin-bottom: 30px;
    }
    .tabs {
      z-index: 2;
    }
    @media (max-width: 1199px) {
      position: relative;
      width: 100%;
      max-width: 280px;
      margin: 10px auto 0;
      .section-header,
      .filter-statistics {
        display: none;
      }
    }

    &-modal {
      @media (max-width: 1199px) {
        min-width: auto;
        width: 100vw;
        max-width: 100vw;
        padding: 80px 15px 15px;
        background: #EEEEEE;
      }
    }
  }
  .content-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-left: 25px;
    @media (max-width: 1199px) {
      &.hidden {
        display: none;
      }
    }
    .section-header {
      margin-bottom: 25px;

      @media (min-width: $breakpoint-laptop) {
        margin-bottom: 0;
      }

      @media (max-width: $breakpoint-mobile-m) {
        margin-bottom: 10px;
      }
    }
    .search-container {
      display: flex;
      z-index: 2;
      height: 40px !important;
      display: none;
      @media (max-width: 1200px) {
        display: block;
        background-color: #2E4741;
        height: auto !important;
        width: 100%;
        transform: translateX(-20px);
        padding: 20px;
        &.inside-modal {
          width: calc(100vw - 80px);
        }
      }
      @media (max-width: $breakpoint-mobile-m) {
        margin: 0 0 15px;
        &.inside-modal {
          width: calc(100vw - 40px);
        }
      }
      .search-input {
        width: 100%;
      }
      .mobile-search {
        border: none;
        border-radius: 6px;
      }
      .buttons {
        @media screen and (max-width: 1200px) {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 20px;

          .divider {
            height: 14px;
            width: 2px;
            background-color: #fff;
            transform: translateY(-2px);
          }
        }
      }
      .filter-button {
        width: 155px;
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid rgba(0, 0, 0, 0.3);
        border-radius: 20px;
        background: white;
        margin-left: 20px;

        img {
          display: none;
        }

        @media (min-width: $breakpoint-laptop) {
          display: none;
        }

        @media (max-width: $breakpoint-laptop) {
          background-color: transparent;
          border: none;
          color: white;
          width: auto;
          height: auto;
          border-radius: 0;
          margin-left: 0;
          gap: 6px;
          img {
            display: block;
          }
          p {
            text-transform: uppercase;
          }
          img {
            display: block;
            position: relative;
            top: -2px;
          }

          & > .small-uppercase {
            font-size: 14px;
            line-height: 14px;
          }
        }

        span {
          // font-family: AvenirHeavy;
          font-weight: 700;
          margin-right: 10px;
          height: 20px;
          width: 20px;
          border-radius: 10px;
          background-color: $green;
          color: white;
          font-size: 11px;
          display: flex;
          align-items: center;
          justify-content: center;
          letter-spacing: 0;
        }
      }
    }
    .filter-statistics {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 40px;
      display: none;

      @media (max-width: 1199px) {
        display: none;
      }
      .title {
        display: flex;
        align-items: center;
        font-size: 16px;
        letter-spacing: 0.5px;
        .count {
          margin-right: 10px;
          color: black;
          // font-size: 11px;
        }
      }
      .clear-all {
        // font-family: AvenirHeavy;
        font-size: 16px;
        margin-left: 10px;
        padding-left: 10px;
        background-color: inherit;
        border-radius: 0;
        border-left: #000 2px solid;
      }
    }
    .recipes-container {
      width: auto;
      h2 {
        z-index: 2;
        position: fixed;
      }

      @media (max-width: 1199px) {
        padding-left: 0;
      }

      @media screen and (max-width: 475px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 0;
      }
    }
    @media (max-width: 1199px) {
      margin-left: 0;
    }
  }
}
.recipe-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.with-icon {
    justify-content: flex-start;
    gap: 6px;
  }
}
.tabs {
  display: flex;
  margin-bottom: 40px;
  button {
    border: none;
    background: white;
    // font-family: AvenirHeavy;
    font-weight: 700;
    font-size: 13px;
    letter-spacing: 1.7px;
    position: relative;
    padding: 0 2px;
    z-index: 0;
    text-transform: uppercase;

    &:before,
    &:after {
      content: "";
      transition: 0.1s ease-in-out;
      opacity: 0;
      visibility: hidden;
      width: 100%;
      position: absolute;
      bottom: -2px;
      left: 0;
      z-index: -1;
    }

    &:after {
      height: 2px;
      background-color: $main-black;
    }

    &:before {
      height: 10px;
      background-color: rgba($green, 0.3);
    }

    &.active {
      &:before,
      &:after {
        opacity: 1;
        visibility: visible;
      }
    }

    &:not(:last-child) {
      margin-right: 30px;
    }
  }
}

.filter-element {
  margin-bottom: 35px;

  .filter-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    margin: -10px 0 5px 0;

    img {
      transition: 0.3s ease-in-out;

      &.rotate {
        transform: rotate(180deg);
      }
    }

    &:hover {
      cursor: pointer;
    }

    .small-uppercase {
      font-size: 24px;
      line-height: 24px;
      color: white;
      text-transform: uppercase;
      font-weight: 700;

      @media screen and (max-width: 1200px) {
        color: black;
      }
    }
  }

  .options {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;

    &.options-checkboxes {
      grid-template-columns: 1fr;

      .button-link {
        justify-content: flex-start;
      }
    }

    .option {
      height: 50px;
      background-color: white;
      border: none;
      //font-family: MontserratRegular;
      font-weight: 600;
      font-size: 16px;
      line-height: 23px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;

      @media screen and (min-width: 2000px) {
        font-size: 20px;
        line-height: 22px;
      }

      &.active {
        background-color: $main-black;
        color: white;

        @media screen and (max-width: 768px) {
          background-color: #2E4741;
        }

        // .check {
        //   display: block;
        // }
      }

      .check {
        margin-right: 5px;
        display: none;
      }

      .rating-option img {
        margin-right: 2px;
      }
    }
  }
}

.recipes-filter {
  .show-recipes {
    border-radius: 8px;
  }
  .top-options {
    margin-bottom: 40px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;

    .option {
      width: 100%;
      height: 50px;
      background-color: white;
      border: none;
      // font-family: MontserratRegular;
      font-weight: 600;
      font-size: 16px;
      line-height: 23px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;

      @media screen and (min-width: 2000px) {
        font-size: 20px;
      }

      &:before,
      &:after {
        display: none;
      }
      &.active {
        background-color: $main-black;
        color: white;

        @media screen and (max-width: 768px) {
          background-color: #2E4741;
        }

        .check {
          display: block;
        }
      }

      .check {
        margin-right: 5px;
        display: none;
      }

      .rating-option img {
        margin-right: 2px;
      }
    }
  }
  @media (min-width: ($breakpoint-laptop + 1)) {
    // max-height: calc(100vh - 240px);
    overflow-y: scroll;
    overflow-x: visible;
    padding: 40px 32px 50px 32px;

    &::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .filter-statistics {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // font-family: AvenirHeavy;
    font-weight: 700;
    @media (max-width: ($breakpoint-laptop + 1)) {
      margin-bottom: 30px;
    }
    .title {
      display: flex;
      align-items: center;
      font-size: 16px;
      letter-spacing: 0.5px;
      
      @media screen and (min-width: 2000px) {
        font-size: 20px;
      }

      @media (min-width: $breakpoint-laptop) {
        display: none;
      }

      .count {
        margin-left: 10px;
        height: 20px;
        width: 20px;
        border-radius: 10px;
        background-color: $green;
        color: white;
        font-size: 11px;
        display: flex;
        align-items: center;
        justify-content: center;
        letter-spacing: 0;
      }
    }
    .clear-all {
      @media (min-width: $breakpoint-laptop) {
        display: none;
      }
    }
  }

  .slider-container {
    display: flex;
    margin: 0 4px;
    overflow: visible;
    .input-range {
      margin-top: 30px;
      margin-bottom: 20px;
    }
  }
}

.recipe-card {
  box-shadow: 0 0px 10px 0px rgba(#254215, 0.06);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: $breakpoint-mobile-m) {
    height: 90px;
    flex-direction: row;
  }

  &:hover {
    cursor: pointer;
  }

  .image {
    height: 160px;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: flex-end;

    @media screen and (min-width: 2000px) {
      height: 230px;
    }

    @media (max-width: $breakpoint-mobile-m) {
      min-width: 90px;
      height: 100%;
      border-radius: 10px 0 0 10px;

      .info-button {
        display: none;
      }
    }
  }

  .info-container {
    .title {
      font-size: 18px;
      line-height: 18px;
      font-weight: 600;

      @media screen and (min-width: 2000px) {
        font-size: 24px;
        line-height: 26px;
      }
    }

    .title:hover {
      cursor: pointer;
    }
    @media (max-width: $breakpoint-mobile-m) {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }
  }

  .info-button {
    height: 40px;
    width: 40px;
    background-color: black;
    border: none;
  }

  .info {
    padding: 15px;
    display: flex;
    align-items: center;
    line-height: 18px;
    min-height: 36px;

    @media (max-width: $breakpoint-mobile-m) {
      align-items: flex-start;
      font-size: 14px;
      line-height: 15px;
      min-height: 30px;
      padding-bottom: 10px;
      padding-top: 18px;
      padding-right: 8.5px;

      .heart {
        width: 13.5px;
        margin-left: 8px !important;
      }
    }

    p {
      flex-grow: 1;
    }

    .heart {
      margin-left: 15px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .statistics {
    padding: 5px 15px 25px;
    display: flex;
    flex-grow: 1;
    align-items: flex-end;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;

    .info-button {
      display: none;
      height: 30px;
      width: 30px;
      align-items: center;
      justify-content: center;
      right: 0;
      bottom: 0;

      img {
        height: 12px;
      }
    }

    @media (max-width: $breakpoint-mobile-m) {
      padding: 0 15px 18px;

      .text-muted {
        font-size: 11px;
      }

      .info-button {
        position: absolute;
        display: flex;
      }
    }

    .element {
      display: flex;
      align-items: center;
      white-space: nowrap;

      img {
        position: relative;
        top: -2px;
        height: 15px;
        width: 13px;
        margin-right: 5px;

        @media (max-width: $breakpoint-mobile-m) {
          height: 10px;
          width: auto;
        }
      }

      .text-muted {
        color: black;

        @media screen and (min-width: 2000px) {
          font-size: 18px;
        }
      }

      &:last-child {
        margin-right: 20px;
      }

      @media screen and (max-width: 475px) {
        &:nth-child(2) {
          margin-right: 20px;
        }
      }
    }
  }
}

.shopping-cart-block {
  @media (min-width: $breakpoint-laptop) {
    margin-bottom: -40px;
  }

  .small-uppercase {
    margin-bottom: 20px;
  }

  .shopping-cart-element {
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: $breakpoint-laptop) {
      padding: 5px 15px;
      // border-radius: 10px;
      // border: 1px solid rgba(106, 187, 61, 0.25);
    }
    &:not(:last-child) {
      margin-bottom: 15px;
    }
    .fraction {
      position: relative;
      margin: 0 6px;
      .numerator {
        position: absolute;
        top: 1px;
        left: -5px;
        font-size: 8px;
        font-weight: 600;
      }
      .denominator {
        position: absolute;
        top: 4px;
        left: 2px;
        font-size: 8px;
        font-weight: 600;
      }
      @media (max-width: $breakpoint-mobile-m) {
        .numerator {
          position: absolute;
          top: 1px;
          left: -5px;
          font-size: 8px;
          font-weight: 600;
        }
        .denominator {
          position: absolute;
          top: 4px;
          left: 2px;
          font-size: 8px;
          font-weight: 600;
        }
      }
    }
  }
}

.shopping-cart-modal {
  max-width: 1200px;
  position: relative;
  top: 0;
  margin: 0 auto;

  @media (max-width: $breakpoint-mobile-m) {
    margin-top: 36px;
  }

  @media (max-width: 475px) {
    padding: 0 30px 30px;
    border-radius: 0;
    border: none;
  }

  &.withPDF {
    height: 700px;
  }

  .title {
    // font-family: MontserratSemiBold;
    font-size: 22px;
    line-height: 27px;
    padding: 0;
    background-color: white;
    margin: -23px 0 25px;
    width: fit-content;
    font-weight: 700;

    @media (max-width: 768px) {
      margin-bottom: 20px;
      font-size: 22px;
    }

    @media (max-width: 475px) {
      text-align: center;
      font-size: 20px;
    }
  }

  .info-alert {
    max-width: 630px;
    text-align: center;
    margin: 0 auto 15px;
    font-size: 15px;
    background-color: rgba($green, 0.3);
    padding: 5px;
  }

  .plain {
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    margin: 0;
    padding: 0;

    @media (max-width: 768px) {
      // font-family: MontserratLightItalic;
      font-size: 14px;
    }

    @media (max-width: 475px) {
      padding-top: 15px;
      line-height: 18px;
      text-align: left;
    }
  }

  .button-container {
    display: flex;
    justify-content: center;
  }

  .button-field {
    height: 100px;
  }

  .deliver-button {
    margin: 50px auto 0;
    width: 243px;
    color: white;
    font-size: 15px;
    line-height: 20px;
    text-transform: uppercase;
    height: 50px;
    border-radius: 8px;
    border: none;
    // font-family: MontserratSemiBold;
    font-weight: 600;
    letter-spacing: 1px;

    @media (max-width: 768px) {
      margin: 25px auto 0;
    }

    @media (max-width: 475px) {
      font-size: 15px;
      font-weight: 600;
      letter-spacing: 1px;
      width: calc(100vw - 40px);
      left: 0;
      right: 0;
      position: fixed;
      bottom: 40px;
      z-index: 2;
    }
  }

  @media print {
    .section-header {
      display: none;
    }

    .button-container {
      display: none;
    }
  }

  .plain.wider {
    max-width: 725px;
  }

  .configuration {
    margin-top: 50px;

    .content-container {
      max-width: 725px;
      margin: 0;
    }

    h2 {
      font-size: 22px;
      line-height: 30px;
      font-weight: 800;
      letter-spacing: 0.54px;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    h3 {
      margin-top: 45px;
      margin-bottom: 15px;
      font-size: 18px;
    }

    p {
      //font-family: MontserratRegular;
      font-weight: 400;
    }

    .variants-container {
      display: flex;
      flex-wrap: wrap;
    }

    .variant-button {
      width: 170px;
      height: 40px;
      border-radius: 10px;
      background-color: #e5e5e5;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      // font-family: MontserratMedium;
      font-weight: 500;
      font-size: 16px;
      color: #292929;
      margin-bottom: 10px;

      @media (max-width: 768px) {
        flex: 1;
        min-width: 40%;
      }

      &.active {
        background-color: #000000;
        color: white;

        img {
          display: block;
        }
      }

      img {
        display: none;
        margin-right: 7px;
        width: 14px;
      }
    }

    .checkbox {
      margin-top: 10px;
      &.radio {
        font-size: 16px;
      }
    }

    .button {
      margin: 0 !important;
      width: 243px;
      height: 50px;
      @media (max-width: 475px) {
        font-size: 15px;
        font-weight: 600;
        letter-spacing: 1px;
        width: calc(100vw - 40px);
        margin: 0;
        padding: 0;
        position: fixed;
        bottom: 40px;
        z-index: 2;
      }
    }

    .buttons {
      display: flex;
      justify-content: center;
      margin-top: 40px;
      padding-top: 60px;
      border-top: 1px solid #000;

      @media (max-width: 475px) {
        border: none;
        margin-top: 35px;
        padding: 0;
      }

      .button:first-child {
        margin-right: 15px;
      }
    }
  }

  .sent-alert {
    padding: 10px;
    background: rgba($green, 0.1);
    border-radius: 5px;
    text-align: center;
  }
}

.shopping-cart-buttons {
  position: fixed;
  width: 100%;
  max-width: 1200px;
  right: 30px;
  top: 30px;
  transform: translateX(-50%);
  display: flex;
  justify-content: flex-end;

  @media print {
    display: none;
  }

  @media (max-width: 1280px) {
    max-width: calc(100vw - 80px);
  }

  button {
    width: 64px;
    height: 64px;
    border-radius: 32px;
    background: $green;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;

    &:last-child {
      margin-left: 20px;
    }
  }

  @media (max-width: $breakpoint-mobile-m) {
    max-width: calc(100vw - 40px);

    button {
      width: 50px;
      height: 50px;

      &:last-child {
        margin-left: 15px;
      }
    }
  }
}

.tooltip-recipes {
  width: 172px;

  .recipe {
    font-size: 12px;
    &:not(:last-child) {
      margin-bottom: 15px;
    }

    .title {
      color: $green;
      margin-bottom: 5px;
    }

    .product {
      //font-family: MontserratRegular;
      font-weight: 400;
    }
  }
}

.recipe-page {
  max-width: 1000px;
  margin: 0 auto;
  @media (max-width: 1024px) {
    margin: 0 20px;
  }

  @media screen and (min-width: 2000px) {
    max-width: 1920px;
  }
  .title-row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 30px;

    .recipe-header-new {
      font-size: 32px;
      line-height: 40px;
      font-weight: 600;

      @media screen and (min-width: 2000px) {
        font-size: 44px;
        line-height: 48px;
      }
    }

    @media (max-width: 600px) {
      flex-direction: column-reverse;
      justify-content: flex-start;

      @media print {
        flex-direction: column;
      }
    }

    .title-buttons {
      display: flex;
      margin-left: 30px;

      @media (max-width: 600px) {
        margin-left: 0;
        margin-bottom: 20px;

        @media print {
          margin-bottom: 0;
          margin-top: 10px;
        }
      }

      @media print {
        button {
          display: none;
        }
      }

      .servings {
        display: flex;
        align-items: center;

        @media print {
          display: none;
        }

        p {
          // // font-family: MontserratMedium;
          margin-right: 20px;
          font-weight: 500;
          font-size: 16px;

          @media screen and (min-width: 2000px) {
            font-size: 26px;
          }

          @media print {
            margin-right: 0;
          }
        }
      }

      .heart img {
        width: 28px;
      }

      & > * {
        padding: 5px 30px;
        border-radius: 0;
        border-right: 1px solid rgba(0, 0, 0, 0.3);

        @media (max-width: 600px) {
          border: none;
          padding: 0 15px;
        }

        &:last-child {
          padding-right: 0;
          border: none;
        }

        &:first-child {
          padding-left: 0;
        }
      }
    }
  }

  .title {
    display: flex;
    justify-content: space-between;

    .heart {
      margin-top: 3px;
      background: white;
      border: none;
      margin-left: 15px;
      height: 37px;
      display: flex;
      align-items: center;

      @media (max-width: 767px) {
        height: 27px;
      }

      img {
        width: 28px;
      }
    }
  }

  .main-image {


    margin-top: -35px;

    @media (max-width: 1260px) {
      margin-top: -20px;
    }

    @media (max-width: 768px) {
      margin-top: 0;
    }

    @media (max-width: 475px) {
      margin-top: -40px;
    }

    @media print {
      display: none;
    }
  }

  .print-info {
    display: none;
    margin: -30px 0 30px;
    font-size: 16px;
    color: $gray;

    span {
      margin-right: 15px;
      margin-left: 5px;
      color: $main-black;
    }

    @media print {
      display: flex;
    }
  }

  .reviews-header {
    @media print {
      display: none;
    }
  }

  .statistics {
    background-color: rgba($green, 0.1);
    padding: 20px 0;
    border-radius: 10px;
    display: flex;

    @media (max-width: $breakpoint-mobile-m) {
      padding: 0;
      background: transparent;
      justify-content: space-between;
    }

    .element {
      height: 50px;
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      .button {
        border-radius: 0;
      }

      @media (max-width: $breakpoint-mobile-m) {
        flex-grow: unset;
        border-right: none !important;
        height: auto;
      }

      &:nth-child(3) {
        @media print {
          display: none;
        }
      }

      &:nth-child(2) {
        @media print {
          border-right: none !important;
        }
      }

      &:not(:last-child) {
        border-right: 1px solid rgba($green, 0.3);
      }

      .text-muted {
        margin-left: 15px;
      }
    }
  }

  .rating {
    display: flex;

    .images {
      display: flex;

      img {
        width: 18px;
        height: 18px;

        &.disabled {
          opacity: 0.3;
        }

        &:not(:last-child) {
          margin-right: 5px;
        }
      }
    }
  }

  .list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    sup {
      position: relative;
      top: 3px;
      font-size: 10px;
    }
    p {
      font-weight: 400;
      font-size: 20px;
      display: inline-block;
      line-height: 1.3;
      max-width: 90%;
      padding: 0px;
      margin: 0;

      @media screen and (min-width: 2000px) {
        font-size: 28px;
        line-height: 30px;
      }
    }
    h4 {
      &.ingredient-heading {
        margin-top: 25px;
        margin-bottom: 0;
        font-weight: 800;
        font-size: 16px;
        letter-spacing: 2px;
        text-transform: uppercase;
      }
    }
    .list-row {
      margin-top: 30px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      // align-items: center;
      @media print {
        page-break-inside: avoid;
      }
      @media (max-width: 550px) {
        margin-top: 15px;
      }

      &:first-child {
        margin-top: 0;
      }

      .index {
        margin-right: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        color: black;
        text-align: center;
        min-width: 20px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        // background-color: #000000;
        font-weight: 700;
        margin-top: 2px;

      }
    }
  }

  .content-container {
    display: flex;
    margin-bottom: 120px;
    gap: 30px;

    @media print {
      margin-bottom: 0;
    }

    @media (max-width: $breakpoint-mobile) {
      flex-direction: column;
      margin-bottom: 0;
    }
  }

  .ingredients {
    flex: 1;
    font-size: 16px;
    .list p {
      margin-top: 10px;

      @media (max-width: 550px) {
        margin-top: 7px;
      }

      &:first-child {
        margin-top: 0;
      }
      sup {
        line-height: 0;
      }
      sub {
        line-height: 0;
      }
    }

    .ingredients-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      // margin-bottom: 15px;
    }
  }

  .instructions {
    flex: 2;

    .nutrition {
      display: none;
      margin: 50px 0;
    }

    @media (max-width: $breakpoint-mobile) {
      margin-top: 30px;

      .nutrition {
        display: flex;
      }
    }

    .small-uppercase {
      margin-bottom: 15px;
    }

    .notes {
      margin-top: 40px;
      // p {
      //   // font-weight: 800 !important;
      // }
    }
  }
}

.reviews-header {
  display: flex;
  align-items: center;
  font-size: 32px;
  font-weight: 600;

  
  @media screen and (min-width: 2000px) {
    font-size: 44px;
  }

  @media print {
    display: none;
  }

  &:after {
    content: "";
    height: 4px;
    background-color: #000;
    flex: 1;
    margin-left: 15px;

    @media (max-width: $breakpoint-mobile-m) {
      display: none;
    }
  }
}

.review-area {
  margin-top: 20px;

  @media (max-width: $breakpoint-mobile-m) {
    padding-bottom: 20px;
    border-bottom: 4px solid #000;
    margin-top: 10px;
  }

  @media print {
    display: none;
  }

  .title {
    display: flex;
    .small-uppercase {
      font-size: 16pt;
      font-weight: 800;
      letter-spacing: 2px;
      text-transform: uppercase;

      @media screen and (min-width: 2000px) {
        font-size: 28px;
      }
    }
  }

  .review-rating {
    display: flex;

    img {
      width: 22px;
      height: 20px;
      margin-left: 4px;
    }
  }

  .input {
    // font-family: MontserratRegular !important;
    font-weight: 400;
    background-color: transparent !important;
    font-size: 12px;
    line-height: 14px;
    height: 50px !important;
    padding: 15px !important;
    margin: 15px 0 20px;

    @media (max-width: $breakpoint-mobile-m) {
      margin-bottom: 10px;
    }
  }

  .button-container {
    display: flex;

    @media (max-width: 480px) {
      flex-direction: row-reverse;
      align-items: center;

      .button {
        height: 40px;
        font-size: 11px;
      }

      .review-rating {
        margin-left: 0 !important;
        margin-bottom: 0;
        flex: 1;
        justify-content: center;
        margin-right: 10px;
      }
    }

    .review-rating {
      margin-left: 15px;
    }
  }
}

.review-container {
  display: flex;

  .review-area {
    flex: 1;
    margin-right: 55px;

    @media (max-width: $breakpoint-mobile) {
      margin-right: 0;
    }
    .input {
      line-height: 20px;
      font-size: 15px;

      @media screen and (min-width: 2000px) {
        font-size: 20px;
      }
    }
  }

  .nutrition {
    flex: 1;
    margin-top: 53px;
    height: fit-content;

    @media (max-width: $breakpoint-mobile) {
      display: none;
    }
  }
}

.nutrition {
  background-color: rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 0;
  text-align: center;

  .small-uppercase {
    font-size: 16px;

    @media screen and (min-width: 2000px) {
      font-size: 24px;
    }
  }

  @media print {
    page-break-inside: avoid;
  }
  @media (max-width: $breakpoint-mobile-m) {
    padding: 20px 0;

    .small-uppercase {
      letter-spacing: 1.2px;
    }
  }

  .elements {
    display: flex;
    margin-top: 15px;
    align-items: center;
    @media (max-width: $breakpoint-mobile-m) {
      margin-top: 5px;
    }
    @media (max-width: 319px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 10px;
    }
    .element {
      border-radius: 10px;
      padding: 0 15px;
      width: 54px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @media (max-width: $breakpoint-mobile-m) {
        width: auto;
        flex: 1;
        min-width: 20px;
      }

      &:not(:last-child) {
        margin-right: 15px;

        @media (max-width: $breakpoint-mobile-m) {
          margin-right: 5px;
        }
      }

      .name {
        font-size: 14px;
        color: #000;
        // font-family: MontserratSemiBold;
        font-weight: 600;
        margin-bottom: 5px;

        @media screen and (min-width: 2000px) {
          font-size: 20px;
        }
      }

      .measure {
        // font-family: MontserratMedium;
        font-weight: 500;
        font-size: 14px;
        color: rgba($main-black, 0.25);

        @media screen and (min-width: 2000px) {
          font-size: 20px;
        }
      }

      .amount {
        font-size: 14px;
        // font-family: MontserratSemiBold;
        font-weight: 600;
        white-space: nowrap;

        @media screen and (min-width: 2000px) {
          font-size: 20px;
        }
      }

      .percents {
        width: 100%;
        padding: 4px 0;
        font-size: 11px;
        color: white;
        background: black;
        text-align: center;
        border-radius: 12px;
        margin-top: 10px;
        // font-family: MontserratSemiBold;
        font-weight: 600;
      }
    }
  }
}

.reviews {
  margin-top: 100px;

  @media print {
    display: none;
  }

  @media (max-width: 990px) {
    margin-top: 30px;
  }

  .review {
    margin-bottom: 40px;

    &.deleted {
      display: none;
    }

    @media (max-width: 990px) {
      margin-bottom: 35px;
    }

    .info {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .user {
        display: flex;
        align-items: center;

        .photo {
          width: 60px;
          height: 60px;
          border-radius: 30px;
          background-size: cover;
          background-position: center;
          margin-right: 15px;
        }

        .location {
          display: flex;
          align-items: center;
          font-size: 12px;
          margin-top: 5px;

          @media screen and (min-width: 2000px) {
            font-size: 16px;
          }

          img {
            margin-right: 10px;
          }
        }

        .name {
          font-size: 16px;

          @media screen and (min-width: 2000px) {
            font-size: 20px;
          }
        }
      }

      .date {
        font-size: 12px;
      }
    }

    .review-rating {
      margin: 15px 0;

      img {
        height: 10px;
        margin-right: 2px;
      }
    }

    .message {
      font-size: 20px;
      font-weight: 400;
      margin-top: 20px;
      line-height: 1.3;
      // font-family: MontserratRegular;

      @media screen and (min-width: 2000px) {
        font-size: 24px;
      }
    }

    .meta-container {
      display: flex;

      .edit-button {
        //font-family: MontserratRegular;
        font-weight: 400;
        font-size: 12px;
        border: none;
        background: transparent;
        color: $gray;
        margin-right: 15px;
      }
    }
    .input {
      background-color: transparent;
    }
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba($main-black, 0.6);
  overflow-y: scroll;
  bottom: 0;
  z-index: 99999999;
  transition: 0.3s ease-in-out;
  visibility: hidden;
  opacity: 0;

  &.visible {
    visibility: visible;
    opacity: 1;
  }

  .scrollable-layer {
    padding: 40px 40px;
    display: flex;
    justify-content: center;
  }

  .body {
    background: white;
    padding: 60px 80px;
    width: 320px;
    position: relative;
    &.large {
      width: 500px;
    }

    .close-icon-button {
      position: absolute;
      right: 17px;
      top: 14px;
      border: none;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: $breakpoint-mobile-m) {
        right: 10px;
        top: 10px;
      }
    }

    .title {
      margin: 0 0 10px;
      text-align: center;
      @media (min-width: ($breakpoint-mobile-m + 1)) {
        display: none;
      }
    }
    @media (max-width: $breakpoint-mobile-m) {
      width: calc(100vw - 80px);
      padding: 23px 20px 33px;
    }
    .message {
      text-align: center;
      font-size: 18px;
      //font-family: MontserratRegular;
      font-weight: 700;
      line-height: 25px;
    }
  }

  .button-container {
    display: flex;
    justify-content: end;
    align-items: center;
    margin-bottom: 40px;
    .close-button {
      border: none;
      background-color: black;
      height: 26px;
      width: 26px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.main-image {
  position: relative;
  height: 600px;
  width: 100%;
  background-size: cover;
  background-position: center;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  @media print {
    display: none;
  }
  @media (max-width: $breakpoint-mobile-m) {
    // margin: -20px;
    margin-bottom: 10px !important;
  }

  .close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 26px;
    height: 26px;
    background-color: #000000;
    border: none;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: $breakpoint-mobile-m) {
      top: 0px;
      right: 0px;
      margin: 20px;
    }
    @media print {
      display: none;
    }

    img {
      width: 14px;
    }

    &.big > img {
      width: 50px;
      height: 50px;
    }
  }

  .header-statistics {
    height: 40px;
    background-color: white;
    border-radius: 10px;
    display: flex;
    padding: 10px 0;
    margin-bottom: 30px;

    @media (max-width: $breakpoint-mobile) {
      margin-bottom: 30px;
      transform: translateY(15px);
    }

    @media (max-width: 600px) {
      margin-bottom: 15px;
      transform: none;
    }

    .recipe-info {
      align-items: center;
      padding: 0 50px;
      gap: 12px;

      .divider {
        display: block;
        height: 45%;
        width: 2px;
        background-color: #000;
      }

      & > span {
        font-size: 600;
        font-size: 16px;
        letter-spacing: 0.5px;

        @media screen and(min-width: 2000px) {
          font-size: 18px;
          line-height: 18px;
        }
      }

      .rating {
        color: white;
        display: block;
        padding: 4px;
        background-color: #000;
        font-size: 12px;
        line-height: 12px;

        @media screen and(min-width: 2000px) {
          font-size: 18px;
          line-height: 18px;
        }
      }
    }

    .rating {
      display: flex;
      align-items: center;
      img {
        width: 14px;
      }
    }
    @media (max-width: $breakpoint-mobile-m) {
      height: 20px;

      .text-muted {
        margin-left: 8px;
      }
    }

    .element {
      display: flex;
      align-items: center;
      padding: 0 30px;

      @media (max-width: $breakpoint-mobile-m) {
        padding: 0 25px;
      }

      &:first-child {
        border-right: 1px solid rgba(0, 0, 0, 0.3);
      }

      &:last-child img {
        width: 18px;
      }

      p {
        color: #000;
        // font-family: MontserratMedium;
        font-weight: 500;
        margin-left: 13px;
      }
    }
  }

  @media (max-width: 990px) {
    height: 410px;
  }

  @media (max-width: $breakpoint-mobile-m) {
    height: 320px;
    // margin: -20px;
    border-radius: 0;
  }
}

.rounded-content {
  @media (max-width: $breakpoint-mobile-m) {
    background: white;
    border-radius: 20px;
    margin: 0 -20px;
    padding: 20px 20px 0;

    .section-header:first-child {
      margin: 0 0 15px;
    }
  }
}

.rawkstars-page {
  .main-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;

    .section-header {
      color: white;
    }

    .button {
      text-decoration: none;
      margin-bottom: 124px;

      @media (max-width: 990px) {
        margin-bottom: 70px;
      }
    }
  }

  .map-container {
    display: flex;
    align-items: center;

    @media (max-width: $breakpoint-mobile) {
      flex-direction: column;
    }

    .map {
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      @media (max-width: $breakpoint-mobile) {
        height: calc(100vw / 16 * 9);
        width: 100%;
      }

      .legend {
        display: flex;
        align-items: center;
        margin-top: -40px;

        .gradient {
          flex-grow: 1;
          height: 8px;
          border-radius: 4px;
          background-image: linear-gradient(45deg, rgba($green, 0.1), $green);
          margin: 0 20px;
        }
      }
    }

    .statistics-container {
      @media (max-width: $breakpoint-mobile) {
        display: flex;
        width: 100%;
        align-items: flex-start;
      }
    }

    .statistics {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-left: 50px;
      width: 240px;

      @media (max-width: $breakpoint-mobile) {
        width: auto;
        margin: 30px 0 0;
      }

      .element {
        display: flex;
        align-items: center;

        &:not(:last-child) {
          margin-bottom: 20px;
        }

        .bar-container {
          min-width: 94px;
          margin: 0 15px;

          .bar {
            height: 8px;
            background: $green;
            border-radius: 4px;
          }
        }

        p:not(.text-muted) {
          text-align: right;
          text-transform: capitalize;
        }

        .text-muted {
          min-width: 40px;
          text-align: left;
        }
      }
    }
  }

  .rawkstar-user {
    padding: 40px;
    box-shadow: 0 4px 10px 4px rgba(#254215, 0.06);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 990px) {
      padding: 35px;
    }

    @media (max-width: $breakpoint-mobile-m) {
      padding: 15px;
      flex-direction: row;
    }

    .photo-container {
      @media (max-width: $breakpoint-mobile-m) {
        margin-right: 15px;
      }
    }

    .info-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      @media (max-width: $breakpoint-mobile-m) {
        align-items: flex-start;
        justify-content: center;
      }
    }

    .photo {
      width: 75px;
      height: 75px;
      background-size: cover;
      background-position: center;
      border-radius: 40px;
    }

    .badge {
      background: $main-black;
      padding: 3px 12px;
      // font-family: AvenirHeavy;
      font-weight: 700;
      font-size: 8px;
      color: white;
      border-radius: 10px;
      margin-top: -8px;
      text-align: center;
    }

    .name {
      margin: 7px 0 5px;
      text-align: center;
    }

    .location {
      display: flex;
      align-items: center;
      font-size: 12px;
      // font-family: AvenirHeavy;
      font-weight: 700;

      img {
        margin-right: 10px;
      }
    }

    .passions {
      margin-top: 15px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      @media (max-width: $breakpoint-mobile-m) {
        margin-top: 10px;
      }

      .passion {
        // font-family: AvenirHeavy;
        font-weight: 700;
        font-size: 10px;
        padding: 4px 10px;
        background: rgba($green, 0.1);
        border-radius: 10px;
        margin-bottom: 5px;

        &:not(:last-child) {
          margin-right: 5px;
        }
      }
    }
  }

  .reviews {
    margin-top: 40px !important;
  }
}

.pagination {
  width: 100%;
  margin-top: 40px;
  display: flex;
  justify-content: center;

  .previous,
  .next {
    background: white;
    border: none;

    &.disabled {
      color: #c5c3c3;
    }
  }

  ul {
    display: flex;
    list-style: none;

    li {
      background-color: rgba($green, 0.1);
      border-radius: 5px;
      // font-family: AvenirHeavy;
      font-weight: 700;
      font-size: 12px;

      &:not(:last-child) {
        margin-right: 10px;
      }

      &.active {
        color: white;
        background: $main-black;
      }

      a {
        display: flex;
        line-height: 40px;
        height: 40px;
        width: 40px;
        align-items: center;
        justify-content: center;

        &:hover {
          cursor: pointer;
        }

        &:focus {
          outline: none;
        }
      }
    }
  }
}

.big-tabs {
  width: 100%;
  display: flex;
  flex-direction: column;

  .tab-options {
    display: none;
    background: rgba($green, 0.1);
    height: 70px;
    border-radius: 10px 10px 0 0;
    margin-bottom: -11px;

    &.mobile {
      display: none;
      flex-direction: column;
      height: auto;
      border-radius: 10px;
      margin-bottom: 25px;
      padding: 5px 0;

      // @media (max-width: 580px) {
      //   display: flex;
      // }

      .ReactCollapse--collapse {
        // border-top: 1px solid rgba($gray, .2);
        margin: 0 20px;

        .option {
          margin: 0;
        }
      }

      .option {
        height: 50px;
        background: none !important;
        border: none !important;
        margin: 0 20px;
        text-align: left;
        width: calc(100% - 40px);
        border-radius: 0;
        padding: 0;

        &.active {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        &.disabled {
          display: none;
        }
      }
    }

    .option {
      flex-grow: 1;
      background: transparent;
      border: none;
      border-radius: 10px 10px 0 0;
      padding: 0 15px;
      z-index: 0;
      margin-bottom: 10px;
      // font-family: AvenirMedium;
      font-weight: 500;

      &.active {
        // border: 1px solid rgba($gray, .3);
        border-bottom: none;
        background: white;
        z-index: 2;
        // font-family: AvenirHeavy;
        font-weight: 700;
      }
    }
  }

  & > .body {
    // border: 1px solid rgba($gray, .3);
    z-index: 1;
    background: white;
    border-radius: 0 0 10px 10px;
    padding: 0 120px 65px;

    &.thin {
      padding-left: 80px;
      padding-right: 80px;
    }
    &.full {
      padding: 0;
      background: transparent;
    }

    @media (max-width: $breakpoint-mobile) {
      padding: 30px 60px 35px !important;
    }

    @media (max-width: 580px) {
      padding: 0 !important;
      border: none;
    }

    &.rounded-left {
      border-top-left-radius: 10px;
    }

    &.rounded-right {
      border-top-right-radius: 10px;
    }
  }

  .body {
    width: auto;
  }
}

.account-settings {
  width: 100%;
  @media (max-width: 767px) {
    max-width: unset;
  }
  .section-header {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.78px;
  }
  .section-header:not(.spaceless) {
    margin: 25px 0 20px;
  }

  .user {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    .photo {
      margin-right: 30px;
      width: 120px;
      height: 120px;
      border-radius: 60px;
      background-size: cover;
      background-position: center;

      @media (max-width: $breakpoint-mobile-m) {
        min-width: 90px;
        width: 90px;
        height: 90px;
      }
    }

    .buttons {
      display: flex;
      flex-direction: column;
      width: 180px;

      @media (max-width: $breakpoint-mobile-m) {
        flex-grow: 1;
      }

      .button {
        width: 100%;
        border: none;
        border-radius: 5px;
        font-weight: 600;
        font-size: 15px;
        line-height: 16px;
        text-align: center;
        letter-spacing: 1px;

        @media (max-width: $breakpoint-mobile-m) {
          height: 35px;
        }

        &.primary {
          background-color: $green;
        }

        &:first-child {
          margin-bottom: 10px;

          @media (max-width: $breakpoint-mobile-m) {
            margin-bottom: 5px;
          }
        }
      }
    }
  }

  &.subscription {
    @media (max-width: $breakpoint-mobile) {
      padding-bottom: 48px;
    }

    .section-header {
      font-size: 18px;
      margin-bottom: 20px;
    }

    .upgrade-message {
      margin-top: 20px;
      //font-family: MontserratRegular;
      font-weight: 400;
    }

    .button {
      margin-top: 40px;
      width: 215px;

      @media (max-width: $breakpoint-mobile-m) {
        width: 100%;
      }
    }
  }
  .input-container {
    .input-label {
      @media screen and (min-width: 2000px) {
        font-size: 20px !important;
      }
    }
    
    .input {
      background-color: white;

      @media screen and (min-width: 2000px) {
        font-size: 20px;
      }
    }
  }
  .select-container {
    background-color: white;

    .value-container .value{
      @media screen and (min-width: 2000px) {
        font-size: 18px !important;
      }
    }
  }
}
.account-settings-background {
  &.green {
    background-color: #2e4741;
  }
}
.account-settings-description {
  font-style: italic;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  margin-top: 32px;
  font-family: JosefinSans;
  /* or 128% */
  text-align: center;

  color: #ffffff;

  @media (max-width: $breakpoint-mobile) {
    margin-top: 0;
  }
}
.account-settings-container {
  // max-width: 680px;
  padding-top: 70px;
  @media (max-width: $breakpoint-mobile) {
    padding-top: 30px;
  }
  .trial-message {
    width: calc(100% - 60px);
    border-radius: 10px;
    padding: 30px;
    margin-bottom: 30px;
    text-align: center;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    font-family: JosefinSans;
    &.title {
      margin-bottom: 0;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;

      /* or 120% */
      letter-spacing: 1px;
      text-transform: uppercase;

      color: #ffffff;
      font-family: JosefinSans;
    }
    color: #ffffff;
    &.error {
      background-color: rgb(252, 189, 189);
      color: rgb(148, 32, 32);
    }
  }
}

.subscription-card {
  // transition: border-color 0.3s ease-in-out;
  // padding: 0 35px;

  background-color: #fff;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;

  overflow: hidden;

  padding: 36px 40px 58px;

  @media (min-width: $breakpoint-mobile) {
    &:nth-child(2) {
      order: 3;
    }
    &:nth-child(3) {
      background: #eeeeee;
    }
  }
  @media (max-width: $breakpoint-mobile) {
    &:nth-child(2) {
      order: 2;
      background: #fff;
    }
    &:nth-child(2) {
      order: 3;
      background: #eeeeee;
    }
    &:nth-child(3) {
      order: -1;
      background: #eeeeee;
    }
  }
  // &.hoverable:hover {
  //   cursor: pointer;
  //   border-color: rgba($green, 0.3);
  //   background-color: rgba(93, 247, 211, 0.1);
  // }

  &.yearly {
    background-color: #eee;
  }

  .title {
    color: #2e4741;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 15px;
    font-family: JosefinSans;
    /* or 88% */
    text-align: right;
    text-transform: uppercase;
    margin-bottom: 23px;
    height: 15px;
  }
  .info {
    display: flex;
    flex-direction: column;
    align-items: center;
    // height: 100%;
    font-family: JosefinSans;
    .price {
      color: $main-black;
      font-weight: 700;
      font-size: 49px;
      // line-height: 23px;
      font-family: JosefinSans;
      /* or 47% */
      text-align: center;
      text-transform: capitalize;
    }
    .period {
      font-size: 22px;
      text-transform: uppercase;
      font-weight: 700;
    }
    .duration {
      color: $main-black;
      font-weight: 600;
      font-size: 15px;
      line-height: 23px;
      text-align: center;
      text-transform: uppercase;
      font-family: JosefinSans;
    }

    .sale {
      margin-right: -35px;
      margin-left: 20px;
      width: 54px;
      height: 100%;
      background-color: #ffb900;
      display: flex;
      align-items: center;
      padding-bottom: 5px;
      transform: rotate(-45deg) translateY(-15px) translateX(33px);
      visibility: hidden;

      &.active {
        visibility: visible;
      }

      .sale-amount {
        transform: rotate(90deg);
        margin-left: 5px;
        font-size: 11px;
        color: white;
      }
    }
  }
}
.subscribe-button {
  width: 100%;
  border: none;
  @media (max-width: $breakpoint-mobile) {
  }
  padding: 17px;
  background: #000;
  color: white;
  font-family: JosefinSans;
  margin-top: 33px;
  font-weight: 600;
  font-size: 16px;
}
.wellness-article {
  &:hover {
    cursor: pointer;
  }

  .title {
    font-size: 18px;
    // font-family: AvenirHeavy;
    font-weight: 700;
    margin-bottom: 15px;
  }

  .photo {
    height: 180px;
    border-radius: 10px;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: flex-end;
    overflow: hidden;
  }

  .badge {
    height: 38px;
    min-width: 110px;
    padding: 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $main-black;
    border-bottom-left-radius: 10px;
    font-size: 12px;
    color: white;
  }
}

.wellness-event {
  &:hover {
    cursor: pointer;
  }

  .title {
    // font-family: AvenirHeavy;
    font-weight: 700;
    margin-bottom: 5px;
    margin-top: 15px;
  }

  .photo {
    height: 180px;
    border-radius: 10px;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: flex-end;
    overflow: hidden;
  }

  .badge {
    height: 38px;
    min-width: 110px;
    padding: 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $main-black;
    border-bottom-left-radius: 10px;
    font-size: 12px;
    color: white;
  }

  .location {
    font-size: 12px;
    display: flex;
    align-items: center;

    img {
      margin-right: 10px;
    }
  }
}

.article {
  .article-title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .section-header {
      margin-right: 15px;
    }

    .button {
      margin-left: 15px;

      @media (max-width: $breakpoint-mobile) {
        img {
          width: 22px;
        }
      }
    }

    &.with-info {
      @media (max-width: $breakpoint-mobile) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    .info-container {
      display: flex;
      align-items: center;

      @media (max-width: $breakpoint-mobile) {
        margin-top: 20px;
        margin-bottom: 15px;
      }

      @media (max-width: $breakpoint-mobile-m) {
        margin: 15px 0 0;
      }

      @media (max-width: $breakpoint-mobile-s) {
        justify-content: space-between;
        width: 100%;
      }

      .element {
        display: flex;
        align-items: center;
        color: $gray;
        font-size: 14px;
        font-weight: 700;
        white-space: nowrap;

        @media (max-width: $breakpoint-mobile-m) {
          font-size: 14px;
        }

        &:first-child {
          margin-right: 50px;

          @media (max-width: $breakpoint-mobile-s) {
            margin-right: 0;
          }
        }

        img {
          margin-right: 15px;

          @media (max-width: $breakpoint-mobile-m) {
            height: 20px;
          }
        }
      }
    }
  }

  .article-body {
    p {
      //font-family: MontserratRegular;
      font-weight: 400;
      line-height: 20px;
    }

    h2,
    h3,
    h4 {
      // font-family: MontserratSemiBold;
      font-weight: 600;
    }

    h2 {
      font-size: 26px !important;
    }

    h3 {
      font-size: 23px;
    }

    h4 {
      font-size: 20px;
    }

    h6 {
      margin: 40px 0 15px;
      font-weight: 700;
      font-size: 18px;
    }

    p {
      margin-bottom: 8px;
    }

    .with-image {
      margin: 40px 0;
      display: flex;

      @media (max-width: $breakpoint-mobile) {
        margin: 30px 0;
      }

      @media (max-width: 640px) {
        flex-direction: column;
        margin: 0;
      }

      .image {
        min-width: calc(50% - 20px);
        margin-right: 40px;
        height: 370px;
        background-position: center;
        background-size: cover;
        border-radius: 20px;

        @media (max-width: $breakpoint-mobile) {
          height: 240px;
          min-width: calc(50% - 15px);
          margin-right: 30px;
        }

        @media (max-width: 640px) {
          margin: 20px 0;
        }
      }
    }
  }
}

.add-to-plan {
  > * + * {
    margin-top: 15px;
  }

  .plan-label {
    //font-family: MontserratRegular;
    font-weight: 400;
    font-size: 12px;

    &:hover {
      cursor: pointer;
    }

    &.active {
      // font-family: MontserratSemiBold;
      font-weight: 600;
      color: $green;
    }
  }

  .date {
    //font-family: MontserratRegular;
    font-weight: 400;
    font-size: 12px;
  }
}

.loader {
  margin: 0 auto;
  width: 20px;
  animation-name: rotate;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-duration: 1s;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}

.withHeight {
  .body {
    height: 260px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
}

.save-plan-modal {
  .footer-buttons {
    display: flex;
    margin: 15px -15px 0;

    .button {
      margin: 0 15px;
    }
  }
  .modal-save-button {
    width: 100%;
    margin-top: 45px;
    border-radius: 0px;
    padding: 25px 0;
    height: auto;
  }
  .short-padding {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }

  .message {
    margin-bottom: 30px;
  }

  .body {
    padding: 80px 80px 60px;
  }

  &.visibleSuccess .body {
    padding: 30px 80px 70px;
  }

  &_error {
    font-weight: 500;
    color: #E20808;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    font-family: JosefinSans;
    text-transform: none;
    display: block;
    width: 375px;
    margin-top: 10px;
    transform: translateX(-26px);

    @media (max-width: $breakpoint-mobile-m) {
      width: auto;
      transform: translateX(0);
    }
  }
}

.aacp-modal {
  .body {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    box-shadow: 10px 10px 24px 0 rgba($main-black, 0.25) ;

    @media (max-width: 640px) {
      padding-top: 50px;
    }
  }

  .aacp-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    max-width: 250px;

    &.small {
      max-width: 200px;
    }
  }

  .message {
    font-weight: 400 !important;
  }

  .footer-buttons {
    .button {
      font-size: 18px;
      line-height: 23px;
      padding: 0 50px;
      margin-top: 20px;
    }
  }

  .footer {
    margin-top: 10px;
    text-align: center;
    max-width: 200px;
    line-height: 20px;

    & > a {
      color: rgb(39, 39, 170);
    }
  }
}

.print-modal {
  .body {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
    padding: 60px 60px 60px;
    width: 418px;

    @media screen and (max-width: 475px) {
      width: calc(100vw - 114px);
      padding: 90px 40px 30px;
      overflow: hidden;
    }
  }

  @media screen and (max-width: 475px) {
    .scrollable-layer {
      padding: 0;
    }
  }

  .variants-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 28px;
    padding-bottom: 10px;
  }

  .close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 0;
    width: 50px;
    height: 50px;
    display: flex;
    border-radius: 100%;
    justify-content: center;
    align-items: center;
    background-color: black;
    border: none;

    @media screen and (max-width: 475px) {
      top: 30px;
    }
  }

  .print-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 39px;
    text-align: center;
    text-transform: uppercase;

    @media screen and (max-width: 475px) {
      font-size: 20px;
      line-height: 32px;
      text-align: start;
    }
  }

  .print-button {
    background-color: black;
    width: 80%;
    padding: 20px 0;
    height: 74px;

    @media screen and (max-width: 475px) {
      width: 100%;
      padding: 0;
      height: 54px;
    }
  }

  .divider {
    height: 1px;
    width: 100%;
    background-color: black;
    margin-top: 30px;

    @media screen and (max-width: 475px) {
      margin-top: 20px;
    }
  }

  .info {
    text-align: center;
    font-size: 14px;
    line-height: 20px;
  }
}

.aacp-header-title {
  font-weight: 600;
  font-size: 30px;
  line-height: 30px;

  @media screen and (max-width: 768px) {
    color: white;
  }
}

.success-message-modal {
  .button-container {
    justify-content: flex-end;
    margin-bottom: 20px;
  }
}

.not-found {
  margin: 169px auto 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1199px) {
    margin-top: 243px;
  }

  @media (max-width: $breakpoint-mobile-m) {
    margin-top: 141px;
  }

  p {
    color: $gray;
    //font-family: MontserratRegular;
    font-weight: 400;
    font-size: 16px;
  }
}

.country-select {
  width: 100%;

  .option {
    &:hover {
      cursor: pointer;
      background-color: rgba($green, 0.1);
    }
  }
}

.contact-box {
  @media (max-width: $breakpoint-mobile) {
    width: calc(100vw - 40px);
  }
}

.event-photo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  .button {
    margin-bottom: 124px;
  }
}
.recipe-title {
  font-size: 16px;
}
.recipe-description {
  p {
    font-weight: 400;
    font-size: 20px;
    line-height: 1.3;
  }

  a {
    color: #000;
    text-decoration: underline;
    // font-family: MontserratMedium;
    font-weight: 500;
  }
}

.recipe-modal-container {
  // overflow-y: scroll;
  position: fixed;
  top: 0px;
  left: 0px;
  width: calc(100% - 60px);
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 999999999999;
  padding: 0 30px;

  @media (max-width: 550px) {
    padding: 0;
    width: 100%;
  }

  .recipe-modal-scroll {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    max-height: 100%;
    overflow: scroll;

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .recipe-info {
    display: flex;
    flex-direction: column;
    position: relative;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    width: calc(100% - 60px);
    // overflow-y: auto;
    max-width: 900px;
    padding: 30px;
    // max-height: calc(100vh - 350px);
    height: fit-content;
    margin: 110px 0;

    @media (max-width: $breakpoint-mobile) {
      padding: 10px;
      border-radius: 20px;
      // max-height: calc(100vh - 200px);
      margin: 70px 0;
    }

    @media (max-width: 550px) {
      top: 56px;
      width: 100vw;
      max-width: unset;
      position: fixed;
      bottom: 0;
      border-radius: 0;
      padding: 0;
      margin: 0;
      overflow: scroll;
      max-height: calc(100% - 56px);
      // max-height: unset;

      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }

    .close-button-container {
      position: absolute;
      top: 30px;
      right: 30px;
      height: 26px;
      background-color: #000000;
      display: flex;
      align-items: center;

      @media (max-width: $breakpoint-mobile) {
        top: 0;
        right: 0;
      }

      @media (max-width: 550px) {
        top: 10px;
        right: 10px;
        height: 26px;
      }

      a {
        padding: 0 20px;
        color: white;
        text-decoration: none;
        font-size: 13px;
        // font-family: 'Avenir';
        letter-spacing: 2px;
        font-weight: 600;

        @media (max-width: 550px) {
          font-size: 10px;
          padding: 0 12px;
        }
      }

      .close-button {
        padding: 0 16px;
        background: transparent;
        border: none;
        border-left: 1px solid white;
        display: flex;
        align-items: center;

        @media (max-width: 550px) {
          padding: 0 10px;
          height: 16px;

          img {
            width: 14px;
          }
        }
      }
    }
    header {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      border-radius: 20px 0 20px 20px;
      min-height: 385px;
      margin-bottom: 20px;

      @media (max-width: $breakpoint-mobile) {
        min-height: 0;
        height: 415px;
        margin-bottom: 40px;
      }

      @media (max-width: 550px) {
        border-radius: 0;
        min-height: 280px;
        margin-bottom: 0;
      }

      h1 {
        display: block;
        background: rgba(0, 0, 0, 0.5);
        padding: 10px;
        font-size: 16px;
        color: #fff;
      }

      .mobile-statistics {
        display: none;
        height: 60px;
        background-color: white;
        border-radius: 10px;
        transform: translateY(30px);

        @media (max-width: $breakpoint-mobile) {
          display: flex;
        }

        @media (max-width: 550px) {
          height: 42px;
          transform: none;
          margin-bottom: 15px;
        }

        .element {
          display: flex;
          align-items: center;
          padding: 0 30px;
          margin: 10px 0;

          .rating {
            display: flex;
            align-items: center;

            .images {
              display: flex;
              align-items: center;
            }

            img {
              width: 19px;

              @media (max-width: 550px) {
                width: 14px;
              }
            }

            img + img {
              margin-left: 5px;
            }
          }

          .text-muted {
            font-size: 14px;
            // font-family: MontserratMedium;
            font-weight: 500;
            color: #000000;
            margin-left: 12px;

            @media (max-width: 550px) {
              font-size: 10px;
              margin-left: 8px;
            }
          }

          &.time-element {
            border-left: 1px solid rgba(0, 0, 0, 0.3);

            @media (max-width: 550px) {
              img {
                width: 18px;
              }
            }
          }
        }
      }
    }
    .loading-message {
      height: 200px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-style: italic;
      font-weight: 300 !important;
      //font-family: MontserratRegular;
    }
  }
  .recipe-page {
    padding-left: 10px;
    padding-right: 10px;
    // overflow-y: auto;
    // max-height: 400px;

    @media (max-width: $breakpoint-mobile) {
      // max-height: 300px;
      padding: 0 20px 20px;
    }

    @media (max-width: 550px) {
      flex: 1;
      // max-height: unset;
      padding: 25px 20px;
    }

    .recipe-grid {
      display: flex !important;
      flex-direction: column !important;
      margin-bottom: 0;

      .statistics-container {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 15px;

        @media (max-width: 550px) {
          margin-bottom: 0;
        }

        h1 {
          font-size: 30px;
          font-weight: 700;
          line-height: 38px;
          margin-right: 20px;
          flex: 1;
          min-width: 220px;
          margin-top: 13px;

          @media (max-width: $breakpoint-mobile) {
            font-size: 22px;
          }

          @media (max-width: 550px) {
            font-size: 20px;
            line-height: 27px;
            margin: 0;
            margin-bottom: 35px;
          }
        }
      }

      .small-title {
        margin-bottom: 15px;
      }

      .statistics {
        background: transparent;
        padding-top: 10px;
        padding-bottom: 0;

        .element {
          padding: 0 30px;
          border-color: #efefef;

          .text-muted {
            font-size: 14px;
            // font-family: MontserratMedium;
            font-weight: 500;
          }

          &:not(.servings-evement) .text-muted {
            color: #000000;
          }

          &:first-child {
            padding-left: 0;
          }

          &:last-child {
            padding-right: 0;
          }
        }

        @media (max-width: $breakpoint-mobile) {
          .rating-element,
          .time-element {
            display: none;
          }
        }

        .servings-evement {
          position: relative;

          .text-muted {
            // font-family: MontserratMedium;
            font-weight: 500;
            position: absolute;
            margin: 0;
            transform: translateY(-28px);
          }

          @media (max-width: $breakpoint-mobile) {
            display: flex;
            padding-left: 0;

            .text-muted {
              position: relative;
              transform: none;
              margin-right: 20px;
            }
          }

          @media (max-width: 550px) {
            display: none;
          }
        }
      }

      .counter-button {
        background-color: #ededed;
      }

      .content-container {
        display: flex;

        @media (max-width: 720px) {
          flex-direction: column;
          align-items: flex-start;

          .instructions {
            margin-top: 15px;
          }
        }

        @media (max-width: 550px) {
          .small-uppercase {
            font-size: 11px !important;
            letter-spacing: 1.7px;
            // font-family: AvenirBlack !important;
            font-weight: 700;
          }

          .instructions {
            margin-top: 35px;
          }
        }

        .ingredients {
          .list {
            width: 250px;
            padding-right: 15px;
            //font-family: MontserratRegular;
            font-weight: 400;

            p {
              margin-top: 10px !important;

              @media (max-width: 550px) {
                margin-top: 7px !important;
                line-height: 18px;
              }

              &:first-child {
                margin-top: 0 !important;
              }
            }
          }
        }

        .instructions {
          .list p {
            //font-family: MontserratRegular;
            font-weight: 400;
            margin-top: 2px;

            @media (max-width: $breakpoint-mobile) {
              margin-top: 0;
            }

            @media (max-width: 550px) {
              line-height: 18px;
            }
          }

          .list .list-row:first-child {
            margin-top: 0;
          }

          .list-row .index {
            min-width: 0;
            width: 19px;
            height: 19px;
            border-radius: 10px;
            background-color: #000000;
            // font-family: MontserratBold;
            font-weight: 700;

            @media (max-width: $breakpoint-mobile) {
              width: 15px;
              height: 15px;
            }

            @media (max-width: 550px) {
              margin-right: 10px;
              font-size: 8px;
            }
          }

          .list-row {
            @media (max-width: $breakpoint-mobile) {
              margin-top: 15px;
            }
          }
        }
      }
    }
  }
}

.subscriptions-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  max-width: 666px;
  margin: 0 auto;

  @media (max-width: $breakpoint-mobile) {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 28px;
    max-width: unset;
  }
  .subscriptions-buttons {
    display: flex;

    * + * {
      margin-left: 25px;
    }
  }

  .cancel-link {
    width: auto !important;
  }
}

.payment-form {
  display: flex;
  width: 100%;

  @media (max-width: 1180px) {
    flex-direction: column;
  }

  //@media (max-width: 840px) {
  //  flex-direction: column;
  //
  //  .payment-info {
  //    width: 50% !important;
  //  }
  //
  //  .checkout-form {
  //    padding: 50px 75px 0 !important;
  //    width: 50% !important;
  //  }
  //}

  //@media (max-width: 500px) {
  //  flex-direction: column;
  //
  //  .checkout-form {
  //    padding: 50px 40px 0 !important;
  //    width: calc(100% - 80px) !important;
  //  }
  //}

  .payment-info {
    min-width: 50%;
    display: flex;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    @media (max-width: 1180px) {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    .label {
      width: 190px;
      height: 190px;
      background: #a4ce25;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      flex-direction: column;
      text-align: center;
      margin-left: 15%;
      margin-top: 18%;

      @media (max-width: 1180px) {
        margin: 100px 0;
      }

      p {
        font-size: 12px;
        margin-bottom: -20px;
        margin-top: 10px;
      }
    }

    h2 {
      text-align: center;
    }

    .subscription-details {
      max-width: 400px;
      text-align: center;
    }

    .planer-screenshot {
      max-width: 100%;
      margin: 25px 0;
      box-shadow: 0 5px 25px 10px rgba(0, 0, 0, 0.1);
    }

    .circle-logo {
      width: 130px;
      margin-bottom: 20px;
    }
  }

  .checkout-form {
    background: #ebebeb;
    width: 50%;
    padding: 100px 80px 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    @media (max-width: 800px) {
      width: calc(100% - 160px);
    }

    @media (max-width: 1180px) {
      padding: 50px 25px;
      width: calc(100% - 50px);
    }

    .card-wrapper {
      padding: 60px 40px 80px;
      background: white;

      .subtitle {
        // font-family: MontserratMedium;
        font-weight: 500;
        margin-top: 5px;
      }

      .card-description {
        // font-family: MontserratMedium;
        font-weight: 500;
        max-width: 400px;
        text-align: center;
        margin: 0 auto;
      }
    }

    .subtitle-container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .card-provides {
        display: flex;
        margin-left: 10px;

        * {
          width: 30px;
          height: 20px;
          border-radius: 3px;
          background-size: cover;
          background-position: center;
        }

        * + * {
          margin-left: 5px;
        }
      }
    }

    .card-container {
      margin: 20px 0 0;
      display: flex;
      flex-direction: column;
      border: 1px solid $gray;
      padding: 10px;
      border-radius: 10px;

      .card-number {
        position: relative;
        padding-top: 10px;
        padding-bottom: 10px;
      }

      .card-extra {
        border-top: 1px solid $gray;
        display: flex;
        margin: 0 -10px;
        padding: 20px 10px 0;
        display: flex;
        justify-content: space-between;
        @media (max-width: 414px) {
          flex-direction: column;
          padding: 0px;
          overflow: hidden;
        }
        .extra-cell {
          width: 25%;
          &:first-child {
            width: 45%;
          }
          @media (max-width: 414px) {
            width: 100% !important;
            padding-top: 5px;
            padding-bottom: 5px;
            padding-left: 5px;
            padding-right: 5px;
            &:not(:first-child) {
              border-top: solid 1px #ccc;
            }
          }
          .zip-input {
            max-width: 100%;
            border: none;
            color: $main-black;
            // font-family: Helvetica, sans-serif;
            font-weight: 400;
            font-size: 15px;

            &::placeholder {
              color: $gray;
            }
          }
        }
      }
    }

    .name-input {
      height: 30px;
      margin-top: 10px;
      width: calc(100% - 20px);
      border: 1px solid $gray;
      border-radius: 10px;
      padding: 10px;
      color: $main-black;
      // font-family: Helvetica, sans-serif;
      font-weight: 400;
      font-size: 15px;

      &::placeholder {
        color: $gray;
      }
    }

    .country-select {
      margin-bottom: 20px;

      .select-container {
        padding: 0 10px;
        border-color: $gray;
      }

      .value-container {
        height: 50px;

        input {
          // font-family: Helvetica, sans-serif;
          font-weight: 400;
          font-size: 15px;
        }
      }
    }

    .button-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .error,
      .success {
        margin-top: 10px;
      }

      .success {
        color: $green;
        min-height: 15px;
        font-size: 12px;
        //font-family: MontserratRegular;
        font-weight: 400;
      }

      .button {
        background: #888888;
        border-color: #888888;
        width: 200px;

        &:hover {
          background: $green;
          border-color: $green;
        }
      }
    }
  }
}

.container-payment {
  width: 100% !important;
  padding: 0;
  display: flex;
}

.wellness-trial {
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    margin-bottom: 20px;
  }

  a {
    color: $green;
  }
}

.toast-container {
  top: 115px !important;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @media (max-width: 1260px) {
    top: 100px !important;
  }

  @media (max-width: 767px) {
    top: unset !important;
  }

  @media (max-width: 475px) {
    bottom: 100px !important;
  }

  &-img {
    background-repeat: no-repeat;
    background-color: transparent !important;
    width: 80px !important;
    height: 60px !important;
    box-shadow: none !important;
    right: 50px !important;
    background-repeat: no-repeat !important;

    img {
      width: 40px;
    }

    .Toastify__toast,
    .toast {
      background-color: transparent !important;
      background: transparent !important;
    }
  }
}

.toast {
  display: flex !important;
  align-items: center !important;
  min-height: 50px !important;
  border-radius: 100px !important;
  background-color: $green !important;
  &.error {
    background-color: red !important;
    font-size: 16px;
    line-height: 20px;
  }
  box-shadow: none !important;
  text-align: center;

  .Toastify__toast-body {
    //font-family: MontserratRegular;
    font-weight: 400;
    color: white;
    padding-left: 10px;
  }

  .Toastify__close-button {
    min-height: 100%;
    align-self: center !important;
    svg {
      fill: white;
    }
  }
}
.cc-error {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 20px;
  border-radius: 10px;
  background: rgba(231, 152, 185, 0.4);
  color: rgb(148, 32, 32);
  font-size: 14px;
  //font-family: MontserratRegular;
  font-weight: 300;
  margin-top: 20px;
  justify-content: space-between;
  border: solid 1px rgb(231, 152, 185);
  h1 {
    display: block;
    width: 100%;
    font-size: 16px;
    text-align: center;
  }
  p {
    display: block;
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }
  button {
    padding: 5px !important;
    max-width: 45% !important;
  }
}

.embded-pdf-preview {
  position: absolute;
  width: 100%;
  left: 0;
  top: 50px;
  border: none;
  height: calc(100% - 50px);
  z-index: 1;
  bottom: 0;
}
.warning-alert {
  display: block;
  padding: 20px;
  font-size: 14px;
  //font-family: MontserratRegular;
  font-weight: 300;
  color: #8d4d05;
  border: solid 1px #d46810;
  background: rgba(250, 211, 128, 0.5);
  margin-bottom: 20px;
  border-radius: 5px;
  margin-top: 20px;
}
@media (max-width: 414px) {
  .mobile-hidden {
    display: none !important;
  }
}

.trial-rawkstars {
  .trial-alert-container {
    display: flex;
    justify-content: center;
    margin-bottom: 45px;

    .trial-alert {
      height: 100px;

      @media (max-width: 520px) {
        height: 55px;
      }
    }

    .text {
      margin-left: 15px;

      .title {
        font-size: 13px;
        letter-spacing: 2px;
        // font-family: MontserratSemiBold;
        font-weight: 600;
        text-transform: uppercase;
        margin-bottom: 5px;
        margin-top: 13px;

        @media (max-width: 520px) {
          font-size: 11px;
          letter-spacing: 1.7px;
          margin-top: 6px;
          margin-bottom: 1px;
        }
      }

      .message {
        // font-family: 'Times New Roman', Times, serif;
        font-weight: 400;
        font-size: 28px;
        letter-spacing: 1px;

        @media (max-width: 520px) {
          font-size: 24px;
          letter-spacing: 0.8px;
        }
      }
    }
  }

  .unlock-title {
    text-align: center;
    font-size: 18px;
    letter-spacing: 2.7px;
    margin-bottom: 18px;
    // font-family: MontserratBold;
    font-weight: 700;

    @media (max-width: $breakpoint-mobile) {
      font-size: 16px;
    }
  }

  .advantages-container {
    padding: 45px 90px 145px;
    border-radius: 20px;
    background-color: #f2f2f2;

    @media (max-width: $breakpoint-mobile) {
      margin: 0 -20px;
      padding: 40px;
      padding-bottom: 65px;
      border-radius: 0;
    }

    @media (max-width: 520px) {
      padding: 40px 15px 25px;
    }

    .advantage {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 70px;

      &:first-child {
        margin-top: 0;
      }

      &:nth-child(odd) {
        flex-direction: row-reverse;

        .advantage-content {
          padding-right: 50px;
          padding-left: 0;

          @media (max-width: 520px) {
            padding: 0;
          }
        }
      }

      @media (max-width: 520px) {
        flex-direction: column !important;
        margin-top: 30px;
      }

      .advantage-content {
        flex: 1;
        padding-left: 50px;

        @media (max-width: 520px) {
          padding: 0;
        }
      }

      .advantage-title {
        font-size: 18px;
        letter-spacing: 0.5px;
        // font-family: MontserratBold;
        font-weight: 700;
        margin-bottom: 20px;

        @media (max-width: 520px) {
          margin-bottom: 10px;
        }
      }

      .advantage-text {
        font-size: 14px;
        //font-family: MontserratRegular;
        font-weight: 400;
        line-height: 20px;
      }

      .advantage-image {
        flex: 1;
        height: 285px;
        border-radius: 10px;
        min-width: 50%;
        background-size: cover;
        background-position: center;

        @media (max-width: $breakpoint-mobile) {
          height: 207px;
          min-width: 0;
        }

        @media (max-width: 520px) {
          margin-bottom: 15px;
          width: 100%;
          min-height: 187px;
        }
      }
    }
  }

  .callout {
    width: 600px;
    max-width: calc(100vw - 60px);
    padding: 35px 0;
    background-color: white;
    border-radius: 10px;
    margin: 0 auto 30px;
    transform: translateY(-75px);
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: $breakpoint-mobile) {
      transform: none;
      margin-bottom: 0;
      padding: 35px 0 25px 0;
    }

    @media (max-width: 520px) {
      padding-left: 20px;
      padding-right: 20px;
      width: auto;
    }

    .callout-title {
      margin-bottom: 30px;
      font-size: 18px;
      // font-family: MontserratBold;
      font-weight: 700;
      text-align: center;

      @media (max-width: 520px) {
        margin-bottom: 15px;
      }
    }

    .button {
      max-width: 215px;
      text-decoration: none;
    }
  }

  .reviews-container {
    border-radius: 10px;
    background-color: #f2f2f2;
    padding: 63px 45px 48px;
    display: flex;

    @media (max-width: $breakpoint-mobile) {
      margin: 0 -20px;
      border-radius: 0;
    }

    @media (max-width: 520px) {
      display: none;
    }

    .review {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 63px;

      &:last-child {
        margin-right: 0;
      }

      @media (max-width: $breakpoint-tablet) {
        &:nth-child(2) {
          display: none;
        }
      }

      .review-image {
        width: 193px;
        height: 193px;
        border-radius: 100px;
        background-size: cover;
        background-position: center;
        margin-bottom: -97px;
        z-index: 1;
      }

      .review-content {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding: 120px 30px 15px;
        background-color: white;
      }

      .review-title {
        font-size: 18px;
        // font-family: MontserratBold;
        font-weight: 700;
        text-align: center;
        padding: 0 20px;
        margin-bottom: 20px;
      }

      .review-text {
        font-size: 13px;
        line-height: 20px;
        //font-family: MontserratRegular;
        font-weight: 400;
        margin-bottom: 35px;
        flex: 1;
      }

      .review-name {
        text-align: center;
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 2px;
        // font-family: MontserratBold;
        font-weight: 700;
      }
    }
  }
}

.affiliate-page {
  .title {
    // font-family: MontserratBold;
    font-weight: 700;
    font-size: 24px;
    letter-spacing: 0.7px;
    color: #0d0c0c;
    text-transform: lowercase;

    @media (max-width: $breakpoint-mobile) {
      font-size: 18px;
      letter-spacing: 0.5px;
    }
  }

  .affiliate-header {
    height: 600px;
    background-size: cover;
    background-position: center;
    border-radius: 30px;
    max-width: 1170px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    margin: 0 auto 80px;

    @media (max-width: $breakpoint-mobile) {
      height: 388px;
      margin-bottom: 40px;
    }

    @media (max-width: 580px) {
      border-radius: 0;
      margin: -20px -20px 40px -20px;
    }

    @media (max-width: $breakpoint-mobile-m) {
      margin-bottom: 80px;
    }

    .distinct {
      // font-family: Distinct;
      font-weight: 400;
      font-size: 131px;

      @media (max-width: $breakpoint-mobile) {
        font-size: 75px;
      }
    }

    .header-label {
      height: 100px;
      transform: translateY(30px);
      padding: 0 50px;
      background-color: white;
      border-radius: 20px;
      display: flex;
      align-items: center;

      @media (max-width: $breakpoint-mobile) {
        padding: 0 20px;
        height: 60px;
        transform: translateY(17px);
      }

      @media (max-width: $breakpoint-mobile-m) {
        flex-direction: column;
      }

      .rewards-label {
        // font-family: MontserratBold;
        font-weight: 700;
        font-size: 18px;
        letter-spacing: 2.8px;
        text-transform: uppercase;

        @media (max-width: $breakpoint-mobile) {
          font-size: 14px;
          letter-spacing: 2.16px;
        }

        @media (max-width: $breakpoint-mobile-m) {
          margin-top: -15px;
        }
      }
    }
  }

  .fit-section {
    max-width: 1030px;
    margin: 0 auto;

    .instructions-section {
      display: flex;
      margin-bottom: 75px;

      @media (max-width: $breakpoint-mobile) {
        flex-direction: column;
        max-width: 550px;
        margin: 0 auto;
      }

      .text,
      .link {
        flex: 1;
      }

      .text {
        box-sizing: border-box;
        max-width: 490px;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 30px 55px 0 0;

        @media (max-width: $breakpoint-mobile) {
          max-width: none;
          padding: 0;
        }

        .title {
          margin-bottom: 30px;

          @media (max-width: $breakpoint-mobile) {
            display: none;
          }
        }

        .message {
          // font-family: MontserratSemiBold;
          font-weight: 600;
          font-size: 16px;
          line-height: 33px;
          color: #292929;
          text-align: center;

          @media (max-width: $breakpoint-mobile) {
            padding: 0 30px;
            font-size: 14px;
            line-height: 28px;
            margin-bottom: 45px;
          }

          @media (max-width: 580px) {
            margin-bottom: 40px;
            padding: 0;
          }
        }
      }

      .link {
        padding: 0 30px;
        background: #f2f2f2;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (max-width: $breakpoint-mobile) {
          padding: 0 60px;
        }

        @media (max-width: 580px) {
          border-radius: 0;
          margin: 0 -20px;
          padding: 0 26px;
        }

        .link-title {
          height: 44px;
          padding: 0 25px;
          background-color: #000;
          display: flex;
          align-items: center;
          margin-bottom: 50px;

          @media (max-width: $breakpoint-mobile) {
            margin-top: -15px;
            padding: 0 40px;
            margin-bottom: 35px;
          }

          @media (max-width: 580px) {
            margin-bottom: 15px;
            margin-top: 35px;
            padding: 0;
            background: transparent;
            height: auto;
          }

          p {
            // font-family: MontserratBold;
            font-weight: 700;
            font-size: 15px;
            letter-spacing: 4px;
            color: white;
            text-transform: uppercase;

            @media (max-width: $breakpoint-mobile) {
              font-size: 14px;
              letter-spacing: 3.5px;
            }

            @media (max-width: 580px) {
              color: #000;
            }
          }
        }

        .url-container {
          padding: 0 10px 0 20px;
          height: 57px;
          background-color: white;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          max-width: 100%;
          margin-bottom: 70px;
          box-sizing: border-box;

          @media (max-width: $breakpoint-mobile) {
            margin-bottom: 55px;
          }

          @media (max-width: 580px) {
            margin-bottom: 35px;
          }

          p {
            // font-family: MontserratBold;
            font-weight: 700;
            font-size: 14px;
            letter-spacing: 1.5px;
            text-transform: uppercase;

            @media (max-width: $breakpoint-mobile) {
              font-size: 12px;
              letter-spacing: 1.2px;
            }
          }

          .copy-button {
            background: transparent;
            border: none;
            padding: 10px;
          }
        }
      }
    }
  }

  .share-ways {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f2f2f2;
    border-radius: 20px;
    padding: 50px 100px;
    margin-bottom: 85px;

    @media (max-width: $breakpoint-mobile) {
      padding: 0;
      background-color: transparent;
      margin-top: 50px;
      margin-bottom: 50px;
    }

    .subtitle {
      margin-top: 20px;
      // font-family: MontserratSemiBold;
      font-weight: 600;
      font-size: 16px;
      line-height: 28px;
      color: #292929;
      max-width: 650px;
      text-align: center;
      margin-bottom: 40px;

      @media (max-width: $breakpoint-mobile) {
        font-size: 14px;
        max-width: 590px;
      }
    }

    .text-examples {
      display: flex;
      justify-content: space-between;

      @media (max-width: 580px) {
        flex-direction: column;
        align-items: center;
      }

      .divider {
        height: 115px;
        width: 3px;
        background: #000;

        @media (max-width: 580px) {
          height: 1px;
          width: 114px;
          margin: 35px 0;
        }
      }

      p {
        font-size: 16px;
        line-height: 25px;
        //font-family: MontserratMediumItalic;
        font-weight: 500;
        font-style: italic;
        text-align: center;
        padding: 0 50px;

        @media (max-width: $breakpoint-mobile) {
          font-size: 14px;
          padding: 0 40px;
        }

        @media (max-width: 580px) {
          padding: 0;
        }

        br {
          display: block;
          margin: 4px 0;
          line-height: 8px;
          content: "";
        }
      }
    }
  }

  .rewards-header {
    margin-bottom: 0;

    @media (max-width: 580px) {
      margin-top: 0;
    }
  }

  .rewards-container {
    width: 475px;
    margin: 0 auto;
    padding: 25px 30px;
    border-radius: 20px;
    background-color: white;
    margin-top: -70px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: $breakpoint-mobile) {
      width: 300px;
      margin-top: -43px;
      padding: 20px 15px;
    }

    .subtitle {
      margin-top: 10px;
      // font-family: MontserratSemiBold;
      font-weight: 600;
      font-size: 16px;
      line-height: 28px;
      color: #292929;
      text-align: center;
      margin-bottom: 20px;

      @media (max-width: $breakpoint-mobile) {
        font-size: 14px;
      }
    }

    .list {
      display: flex;
      flex-direction: column;
      width: 100%;

      .reward {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 0;
        border-bottom: 2px solid #000;
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }

        .amount {
          // font-family: MontserratExtraBold;
          font-weight: 800;
          font-size: 24px;
          text-transform: uppercase;
          letter-spacing: 0.7px;

          @media (max-width: $breakpoint-mobile) {
            font-size: 18px;
          }
        }

        .date {
          // font-family: MontserratBold;
          font-weight: 700;
          font-size: 14px;
          letter-spacing: 1.4px;
        }
      }
    }
  }
}

.sales-page {
  display: flex;
  flex-direction: column;

  h2 {
    // font-family: MontserratSemiBold;
    font-weight: 600;
    font-size: 36px;
    letter-spacing: 1px;

    @media (max-width: $breakpoint-mobile) {
      font-size: 30px;
    }

    @media (max-width: $breakpoint-mobile-m) {
      font-size: 26px;
      letter-spacing: 0.8px;
    }
  }

  button,
  a {
    text-align: center;
    text-decoration: none;
  }

  .sales-content-container {
    max-width: 1240px;
    width: 100%;
    padding: 0 40px;

    @media (max-width: $breakpoint-mobile) {
      padding: 0 30px;
    }

    @media (max-width: $breakpoint-mobile-m) {
      padding: 0 15px;
    }
  }

  .sales-header {
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    background-color: white;
    box-shadow: 0 32px 24px -24px rgba(#000000, 0.05);
    z-index: 10;

    @media (max-width: $breakpoint-mobile) {
      height: 85px;
    }

    @media (max-width: $breakpoint-mobile-m) {
      height: 54px;
    }

    .header-content {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .logo {
        height: 45px;

        @media (max-width: $breakpoint-mobile) {
          height: 21px;
        }
      }

      .buttons-container {
        display: flex;
        align-items: center;
        width: auto;
      }
      .menu-burger {
        background-color: transparent;
        border: none;
        img {
          height: 14px;
          width: 18px;
          object-fit: cover;
        }
        @media (min-width: ($breakpoint-mobile + 1)) {
          display: none;
        }
      }

      .login-button {
        font-size: 16px;
        color: $main-black;
        margin-right: 20px;
        display: flex;
        align-items: center;
        font-size: 12px;
        text-transform: uppercase;
        padding-right: 20px;
        border-right: 1px solid $main-black;
        letter-spacing: 1px;

        @media (max-width: 768px) {
          font-size: 14px;
          border: none;
        }

        @media (max-width: $breakpoint-mobile-m) {
          font-size: 10px;
          line-height: 12px;
          font-weight: 700;
          letter-spacing: 0.9px;
          color: $main-black;
          // font-family: MontserratBold;
          padding-right: 10px;
          margin-right: 10px;
        }
        @media (max-width: $breakpoint-laptop) {
          font-size: 10px;
          letter-spacing: 0.9px;
        }
        .login-icon {
          width: 21px;
          margin-right: 10px;

          @media (max-width: $breakpoint-mobile-m) {
            display: none;
          }
        }
      }

      .action-button {
        padding: 15px 50px;
        background-color: #fff;
        // font-family: MontserratBold;
        font-weight: 700;
        color: $main-black;
        font-size: 14px;
        letter-spacing: 1px;
        text-transform: uppercase;
        border-radius: 50px;
        border: 1px solid $main-black;
        margin: 0;
        width: auto;
        text-decoration: none;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: $breakpoint-tablet) {
          padding: 15px;
        }
        @media (max-width: $breakpoint-mobile) {
          display: none;
        }
      }

      .links {
        display: flex;
        a {
          display: flex;
          align-items: center;
          text-decoration: none;
          color: $main-black;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 12px;
          margin-right: 42px;

          &:not(:last-child) {
            margin-right: 40px;
          }
          &.active {
            color: $green;
          }
        }
        @media (max-width: $breakpoint-laptop) {
          a {
            font-size: 13px;
            line-height: 12px;
            margin-right: 30px;
            &:not(:last-child) {
              margin-right: 20px;
            }
          }
        }
        @media (max-width: $breakpoint-tablet) {
          a {
            font-size: 11px;
            line-height: 10px;
            margin-right: 10px;
            &:not(:last-child) {
              margin-right: 10px;
            }
          }
        }
        @media (max-width: $breakpoint-mobile) {
          display: none;
        }
      }
    }
  }

  .sales-modal {
    background-color: white;
    position: fixed;
    margin-top: 54px;
    height: 100%;
    width: 100%;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .links {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin: 30px;
      a {
        margin-top: 40px;
        align-items: flex-start;
        text-decoration: none;
        color: $main-black;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 12px;
        &.active {
          color: $green;
        }
      }
    }
    .action-button {
      margin: 0 30px 84px;
      background-color: $main-black;
      // font-family: MontserratBold;
      color: white;
      font-size: 14px;
      letter-spacing: 1px;
      text-transform: uppercase;
      border-radius: 40px;
      border: 1px solid $main-black;
      height: 50px;
      width: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      font-size: 13px;
      line-height: 12px;
      letter-spacing: 0.9px;

      @media (min-width: $breakpoint-mobile-m) {
        display: none;
      }
    }
  }

  .sales-jumbotron {
    height: auto;
    position: relative;
    max-width: 100vw;
    margin-top: 100px;
    // font-family: Gotham;

    @media (max-width: $breakpoint-laptop) {
      // height: 630px;
      height: auto;
    }

    @media (max-width: $breakpoint-mobile) {
      height: auto;
      margin-top: 45px;
    }

    .carousel {
      .slide {
        height: calc(100vh - 100px);
        width: 100%;
        position: relative;
        .banner-1 {
          position: absolute;
          top: 165px;
          left: 122px;
          width: 720px;
          text-align: left;
          color: white;
          display: flex;
          flex-direction: column;
          .text {
            width: 670px;
            font-weight: 500;
            font-size: 24px;
            line-height: 39px;
            letter-spacing: 3px;
            margin-top: 30px;
          }
        }
        .banner-2 {
          position: absolute;
          top: 168px;
          left: 122px;
          width: 720px;
          text-align: left;
          color: white;
          display: flex;
          flex-direction: column;
          .text {
            font-weight: 500;
            font-size: 28px;
            line-height: 39px;
            letter-spacing: 3px;
            margin-top: 30px;
            span {
              font-size: 20px;
              font-weight: 400;
            }
          }
        }
        .banner-3 {
          position: absolute;
          top: 114px;
          left: 122px;
          width: 730px;
          text-align: left;
          color: white;
          display: flex;
          flex-direction: column;
          .text {
            font-weight: 500;
            font-size: 28px;
            line-height: 39px;
            letter-spacing: 3px;
            margin-top: 30px;
          }
        }
        .banner-header {
          font-weight: 500;
          font-size: 65px;
          line-height: 63px;
          letter-spacing: 3px;
          // font-family: Gotham;
          span {
            // font-family: Quentin;
            font-weight: 400;
            font-size: 77px;
            line-height: 79px;
            letter-spacing: 1px;
          }
        }
        @media (max-width: $breakpoint-mobile) {
          height: calc(100vh - 45px);
          width: auto;
          .banner-1,
          .banner-2,
          .banner-3 {
            width: auto;
            top: 300px;
            left: 0;
            right: 0;
            margin: 0 30px;
            .text {
              width: auto;
              font-size: 14px;
              line-height: 24px;
              letter-spacing: 0.48px;
              margin: 5px 0;
              span {
                font-size: 14px;
                line-height: 24px;
                letter-spacing: 0.48px;
                font-weight: 400;
              }
            }
          }
          .banner-header {
            width: calc(100% - 60px);
            font-size: 22px;
            line-height: 21px;
            letter-spacing: 1px;
            span {
              font-size: 40px;
              line-height: 79px;
              letter-spacing: 1px;
            }
          }
          img {
            height: calc(100vh - 45px);
            object-fit: cover;
            object-position: 82%;
          }
        }
        @media (min-width: $breakpoint-mobile) {
          height: auto;
          width: auto;
          .banner-1,
          .banner-2,
          .banner-3 {
            width: 500px;
            top: 100px;
            left: 100px;
            right: 0;
            margin: 0 30px;
            .text {
              width: auto;
              font-size: 14px;
              line-height: 24px;
              letter-spacing: 0.48px;
              margin: 5px 0;
              span {
                font-size: 14px;
                line-height: 24px;
                letter-spacing: 0.48px;
                font-weight: 400;
              }
            }
          }
          .banner-header {
            width: calc(100% - 60px);
            font-size: 40px;
            line-height: 42px;
            letter-spacing: 1px;
            span {
              font-size: 55px;
              line-height: 79px;
              letter-spacing: 1px;
            }
          }
        }
      }
      @media (max-width: $breakpoint-mobile) {
        .control-dots {
          margin-bottom: 100px;
        }
      }
    }
    .action-button {
      padding: 17px 27px;
      background-color: #1cb05e;
      border-radius: 50px;
      // font-family: MontserratSemiBold;
      font-size: 13px;
      letter-spacing: 2px;
      color: white;
      border: none;
      width: 256px;
      position: absolute;
      bottom: 160px;
      left: 122px;

      @media (max-width: $breakpoint-tablet) {
        bottom: 50px;
      }
      @media (max-width: 700px) {
        margin-bottom: 35px;
      }

      @media (max-width: $breakpoint-mobile) {
        position: fixed;
        margin: 30px auto;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 9;
      }
    }
  }

  .sales-features {
    background-color: #f2f2f2;
    display: flex;
    justify-content: center;

    @media (max-width: $breakpoint-mobile) {
      background: white;
    }

    .sales-container {
      padding-top: 90px;
      padding-bottom: 75px;

      @media (max-width: $breakpoint-mobile) {
        padding-top: 60px;
        padding-bottom: 40px;
      }

      @media (max-width: $breakpoint-mobile-m) {
        padding-top: 0;
      }

      .title {
        max-width: 810px;
        margin: 0 auto 45px auto;
        text-align: center;
        background-size: 0;

        @media (max-width: $breakpoint-mobile) {
          margin-bottom: 30px;
        }

        @media (max-width: $breakpoint-mobile-m) {
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;
          height: 345px;
          background-size: auto 545px;
          background-repeat: no-repeat;
          background-position: 88% 78%;
          margin: 0 -15px 30px;
          font-size: 16px;
          padding-left: 75px;
          text-align: left;
          padding-bottom: 15px;
          padding-right: 15px;
          letter-spacing: 0.5px;
          color: white;
        }
      }

      .features-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: 60px;
        grid-column-gap: 75px;

        @media (max-width: 1000px) {
          grid-template-columns: 1fr;
          max-width: 560px;
          margin: 0 auto;
        }

        @media (max-width: 540px) {
          grid-row-gap: 30px;
        }

        .feature {
          display: flex;
          align-items: center;

          &:last-child {
            display: none;

            @media (max-width: 540px) {
              display: flex;
            }
          }

          @media (max-width: 540px) {
            flex-direction: column;
          }

          .image {
            min-width: 207px;
            height: 207px;
            border-radius: 105px;
            overflow: hidden;
            margin-right: 20px;

            @media (max-width: 540px) {
              margin: 0 0 20px;
            }
          }

          .feature-title {
            // font-family: MontserratBold;
            font-weight: 700;
            font-size: 18px;
            letter-spacing: 0.5px;
            text-transform: uppercase;
            color: #0d0c0c;
            line-height: 30px;

            @media (max-width: $breakpoint-mobile) {
              font-size: 15px;
            }
          }

          .body {
            // font-family: MontserratMedium;
            font-weight: 500;
            font-size: 15px;
            color: #0d0c0c;
            letter-spacing: 0.5px;
            line-height: 23px;

            @media (max-width: $breakpoint-mobile) {
              font-size: 15px;
            }

            .italic {
              //font-family: MontserratMediumItalic;
              font-weight: 500;
              font-style: italic;
            }
          }
        }
      }
    }
  }

  .sales-logos {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 10px 0 15px;
    max-width: 100vw;
    overflow: hidden;

    @media (max-width: $breakpoint-mobile) {
      padding: 20px 0;
    }

    .mobile-title {
      display: none;
      //font-family: MontserratMediumItalic;
      font-weight: 500;
      font-style: italic;
      font-size: 12px;
      letter-spacing: 0.3px;
      color: #0d0c0c;

      @media (max-width: $breakpoint-mobile-m) {
        display: block;
      }
    }

    .logos-container {
      max-width: calc(1040px - 80px);
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media (max-width: 680px) {
        flex-wrap: wrap;
        justify-content: space-around;
      }

      img {
        max-width: 170px;
        max-height: 77px;

        @media (max-width: 970px) {
          max-width: 115px;
          max-height: 50px;
        }

        @media (max-width: $breakpoint-mobile-m) {
          &:last-child {
            display: none;
          }
        }
      }
    }
  }

  .sales-advantages {
    background-size: cover;
    background-position: center;
    padding-top: 123px;
    padding-bottom: 75px;
    display: flex;
    justify-content: center;
    z-index: 2;

    @media (max-width: $breakpoint-mobile) {
      padding-top: 150px;
      padding-bottom: 103px;
    }

    @media (max-width: $breakpoint-mobile-m) {
      padding-bottom: 0;
      padding-top: 285px;
      background-size: 100% 280px;
      background-repeat: no-repeat;
      background-position: top center;
    }

    .advantages-wrapper {
      padding: 25px;
      max-width: 1070px;
      background-color: rgba(255, 255, 255, 0.67);
      border-radius: 53px;

      @media (max-width: $breakpoint-mobile) {
        padding: 0 30px;
        background: transparent;
      }

      @media (max-width: $breakpoint-mobile-m) {
        padding: 0;
      }
    }

    .advantages-container {
      background: #ffffff;
      border-radius: 50px;
      padding: 75px 45px;

      @media (max-width: $breakpoint-mobile) {
        padding: 70px 60px 35px;
      }

      @media (max-width: $breakpoint-mobile-m) {
        border-radius: 0;
        padding: 25px 20px 0;

        h2 {
          text-align: center;
        }
      }

      .subtitle {
        //font-family: MontserratMediumItalic;
        font-weight: 500;
        font-style: italic;
        font-size: 16px;
        letter-spacing: 0.5px;
        color: #0d0c0c;
        line-height: 30px;
        margin: 25px 0 45px;
        max-width: 830px;
      }

      .advantages-list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: 10px;
        grid-column-gap: 65px;
        margin-bottom: 50px;

        @media (max-width: $breakpoint-mobile) {
          grid-template-columns: 1fr;
          grid-row-gap: 30px;
          margin-bottom: 30px;
        }

        @media (max-width: $breakpoint-mobile-m) {
          grid-row-gap: 20px;
          margin-bottom: 15px;
        }

        .advantage {
          display: flex;
          margin-bottom: 18px;

          &:last-child {
            margin-bottom: 0;
          }

          .check-container {
            min-width: 21px;
            height: 21px;
            background-color: #000;
            border-radius: 11px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 15px;

            img {
              width: 11px;
            }
          }

          .title {
            // font-family: MontserratSemiBold;
            font-weight: 600;
            font-size: 18px;
            color: #292929;
          }

          .description {
            margin-top: 10px;
            //font-family: MontserratMediumItalic;
            font-weight: 500;
            font-style: italic;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.5px;
            color: #0d0c0c;
          }
        }
      }

      .button-container {
        display: flex;
        justify-content: center;

        .action-button {
          padding: 17px 60px;
          background-color: #0d0c0c;
          border: none;
          border-radius: 50px;
          // font-family: MontserratSemiBold;
          font-weight: 600;
          font-size: 13px;
          letter-spacing: 2px;
          color: #fff;
          text-transform: uppercase;
          margin-top: 0;
          width: auto;
        }

        @media (max-width: $breakpoint-mobile-m) {
          transform: translateY(25px);
          margin-top: -25px;
        }
      }
    }
  }

  .sales-also-included {
    display: flex;
    justify-content: center;

    @media (max-width: $breakpoint-mobile-m) {
      background-color: #f2f2f2;
    }

    .also-included-container {
      padding-top: 55px;
      padding-bottom: 100px;
      max-width: 1070px;
      width: calc(100% - 80px);

      @media (max-width: $breakpoint-mobile) {
        padding-top: 75px;
        padding-bottom: 80px;
      }

      @media (max-width: $breakpoint-mobile-m) {
        padding-top: 60px;
        padding-bottom: 50px;
        width: calc(100% - 50px);
      }

      h2 {
        text-align: center;
        margin-bottom: 45px;
      }

      .includes-list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-row-gap: 50px;
        grid-column-gap: 25px;

        @media (max-width: $breakpoint-mobile) {
          grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width: $breakpoint-mobile-m) {
          grid-template-columns: 1fr;
          grid-row-gap: 90px;
        }

        .include-container {
          display: flex;

          &:nth-child(4n) {
            justify-content: flex-start;
          }

          &:nth-child(3n + 2) {
            justify-content: center;
          }

          &:nth-child(3n + 3) {
            justify-content: flex-end;
          }

          @media (max-width: $breakpoint-mobile) {
            justify-content: center !important;
          }

          .include {
            max-width: 250px;
            display: flex;
            flex-direction: column;
            align-items: center;

            @media (max-width: $breakpoint-mobile-m) {
              background-color: white;
              max-width: 280px;
              padding: 0 30px 25px;
            }

            img {
              height: 170px;

              @media (max-width: $breakpoint-mobile-m) {
                margin-top: -50px;
              }
            }

            .title {
              width: 100%;
              text-align: left;
              // font-family: MontserratSemiBold;
              font-weight: 600;
              font-size: 18px;
              color: #292929;
              margin-bottom: 14px;
              margin-top: 10px;

              @media (max-width: $breakpoint-mobile) {
                text-align: center;
              }
            }

            .description {
              //font-family: MontserratMediumItalic;
              font-weight: 500;
              font-style: italic;
              font-size: 14px;
              letter-spacing: 0.4px;
              line-height: 20px;
              color: #0d0c0c;

              @media (max-width: $breakpoint-mobile) {
                text-align: center;
              }

              @media (max-width: $breakpoint-mobile-m) {
                text-align: left;
              }
            }
          }
        }
      }
    }
  }

  .sales-oprah {
    height: 700px;
    background-size: cover;
    background-position: center;
    display: flex;

    @media (max-width: $breakpoint-mobile) {
      height: 645px;
    }

    @media (max-width: $breakpoint-mobile-m) {
      display: none;
    }

    .content {
      width: 430px;
      display: flex;
      flex-direction: column;
      margin-top: 200px;
      margin-left: 80px;

      @media (max-width: $breakpoint-mobile) {
        margin-top: 170px;
        margin-left: 30px;
      }

      .quote {
        // font-family: MontserratSemiBold;
        font-weight: 600;
        font-size: 27px;
        line-height: 40px;
        letter-spacing: 0.8px;
        color: #fff;

        text-align: center;
        text-shadow: 2px 3px 7px rgba(0, 0, 0, 0.47577);
        margin-bottom: 20px;
      }

      .author {
        //font-family: MontserratItalic;
        font-weight: 400;
        font-style: italic;
        font-size: 20px;
        letter-spacing: 0.6px;
        color: #fff;
        text-shadow: 2px 3px 7px rgba(0, 0, 0, 0.47577);
        text-align: center;
      }
    }
  }

  .sales-personalized {
    height: 730px;
    background-color: #f2f2f2;
    display: flex;
    justify-content: center;
    position: relative;

    @media (max-width: 1439px) {
      height: 650px;
    }

    @media (max-width: 1290px) {
      height: 600px;
    }

    @media (max-width: 1190px) {
      height: 550px;
    }

    @media (max-width: 1090px) {
      height: 500px;
    }

    @media (max-width: 990px) {
      height: 480px;
    }

    @media (max-width: $breakpoint-mobile) {
      height: auto;
      padding: 45px 0;
    }

    @media (max-width: 660px) {
      padding: 70px 0 30px;
    }

    .content-container {
      display: flex;
      justify-content: flex-end;
      max-width: 1440px;
      width: 100%;

      @media (max-width: 1439px) {
        justify-content: center;
      }

      @media (max-width: 990px) {
        align-items: center;
      }

      @media (max-width: 660px) {
        flex-direction: column-reverse;
      }
    }

    .content-wrapper {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media (max-width: 660px) {
        width: 100vw;
        padding-top: 40px;
      }

      .content {
        padding-left: 185px;
        padding-right: 115px;

        @media (max-width: 1290px) {
          padding-left: 30px;
          padding-right: 30px;
        }

        h2 {
          @media (max-width: 660px) {
            position: absolute;
            top: 0;
            text-align: center;
            width: 100vw;
            left: 0;
            padding: 0 20px;
            box-sizing: border-box;
            background-color: white;
          }
        }

        .subtitle {
          // font-family: MontserratMedium;
          font-weight: 500;
          font-size: 20px;
          color: #0d0c0c;
          line-height: 30px;
          letter-spacing: 0.6px;
          margin-top: 30px;
          padding-bottom: 30px;
          border-bottom: 3px solid #000;

          @media (max-width: 880px) {
            font-size: 16px;
          }

          @media (max-width: $breakpoint-mobile) {
            margin-top: 10px;
          }

          @media (max-width: 660px) {
            border-bottom: none;
            padding-bottom: 0;
            margin-bottom: 50px;
            margin-top: 0;
          }
        }

        .callout {
          margin-top: 35px;
          display: flex;
          margin-bottom: 40px;

          @media (max-width: 990px) {
            margin-bottom: 0;
          }

          @media (max-width: 660px) {
            align-items: center;
            margin-bottom: 40px;
          }

          @media (max-width: $breakpoint-mobile-m) {
            align-items: center;
            margin-bottom: 30px;
          }

          img {
            margin-right: 12px;
          }

          .description {
            //font-family: MontserratItalic;
            font-weight: 400;
            font-style: italic;
            font-size: 17px;
            color: #0d0c0c;
            letter-spacing: 0.5px;
            line-height: 23px;
            max-width: 320px;

            @media (max-width: 990px) {
              font-size: 14px;
            }

            @media (max-width: 660px) {
              max-width: 100%;
            }
          }
        }

        .button-container {
          display: flex;
          justify-content: center;
        }

        .action-button {
          padding: 17px 55px;
          background-color: #0d0c0c;
          border-radius: 50px;
          border: none;
          // font-family: MontserratSemiBold;
          font-weight: 600;
          font-size: 13px;
          letter-spacing: 2px;
          text-transform: uppercase;
          color: white;

          @media (max-width: 990px) {
            display: none;
          }

          @media (max-width: 660px) {
            display: flex;
          }
        }
      }
    }

    .video {
      z-index: 0;
      position: relative;
      width: 50%;
      height: 100%;

      @media (max-width: 990px) {
        max-height: 380px;
      }

      @media (max-width: $breakpoint-mobile) {
        height: calc(100vw / 2 - 50px);
      }

      @media (max-width: 660px) {
        min-height: calc(100vw * 0.9815);
        width: 100vw;
      }

      iframe {
        width: 100%;
        height: 100%;
      }
    }
  }

  .sales-wellness {
    display: flex;
    justify-content: center;

    .sales-wellness-mobile {
      display: none;
      align-items: center;
      justify-content: center;
      height: 290px;
      width: 100%;
      background-size: cover;
      padding: 10px 0;
      margin: -15px 0;

      @media (max-width: 520px) {
        display: flex;
      }

      button {
        padding: 15px 0;
        border-radius: 50px;
        background-color: #000;
        border: none;
        margin: 0;
        color: white;
        // font-family: MontserratSemiBold;
        font-weight: 600;
        font-size: 13px;
        text-transform: uppercase;
        letter-spacing: 2px;
      }
    }

    .sales-wellness-container {
      padding-top: 120px;
      max-width: 950px;

      @media (max-width: $breakpoint-mobile) {
        padding-top: 40px;
      }

      @media (max-width: 520px) {
        display: none;
      }

      .advantages-container {
        padding: 0;
        background: transparent;
        margin-bottom: 100px;

        @media (max-width: $breakpoint-mobile) {
          margin-left: 0;
          margin-right: 0;
          margin-bottom: 45px;
        }

        .advantage {
          .advantage-text {
            max-width: 389px;
          }

          &:not(:first-child) {
            margin-top: 90px;
          }

          &:nth-child(even) {
            .advantage-content {
              padding-left: 70px;

              @media (max-width: $breakpoint-mobile) {
                padding-left: 20px;
              }
            }
          }

          &:nth-child(odd) {
            .advantage-content {
              padding-right: 70px;

              @media (max-width: $breakpoint-mobile) {
                padding-right: 20px;
              }
            }
          }
        }

        .advantage-image {
          min-width: 0;

          @media (max-width: $breakpoint-mobile) {
            height: 285px;
          }
        }
      }
    }
  }

  .sales-reviews {
    display: flex;
    justify-content: center;
    background-color: #f2f2f2;

    .sales-reviews-container {
      max-width: 990px;
      padding-top: 75px;
      padding-bottom: 70px;

      @media (max-width: $breakpoint-mobile) {
        padding-top: 52px;
      }

      @media (max-width: 520px) {
        padding-bottom: 15px;
      }

      .title {
        text-align: center;
        margin-bottom: 35px;

        @media (max-width: $breakpoint-mobile) {
          margin-bottom: 25px;
        }

        @media (max-width: 520px) {
          display: none;
        }
      }

      .subtitle {
        max-width: 865px;
        //font-family: MontserratMediumItalic;
        font-weight: 500;
        font-style: italic;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: 0.6px;
        color: #0d0c0c;
        text-align: center;
        margin: 0 auto 40px;

        @media (max-width: $breakpoint-mobile) {
          font-size: 16px;
          letter-spacing: 0.4px;
        }

        @media (max-width: 520px) {
          display: none;
        }
      }

      .reviews-container {
        padding: 0;
        background: transparent;
        position: relative;

        @media (max-width: $breakpoint-mobile) {
          margin-left: 0;
          margin-right: 0;
        }

        @media (max-width: 520px) {
          display: flex;
          flex-direction: column;
        }

        .review {
          z-index: 2;

          @media (max-width: 520px) {
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 33px;
            max-width: 290px;

            &:nth-child(2) {
              display: flex;
            }

            &:nth-child(3) {
              display: none;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }

        .review-content {
          border-radius: 20px;
          padding-bottom: 25px;

          @media (max-width: $breakpoint-mobile) {
            border-radius: 0;
            padding-bottom: 15px;
          }
        }

        .review-title {
          padding: 0 15px;
        }

        .review-text {
          font-size: 14px;
          line-height: 22px;
          margin-bottom: 15px;
        }

        .floating-shape {
          position: absolute;
          z-index: 0;
          width: 584px;
          height: 357px;
          bottom: 12px;
          left: 50%;
          transform: translateX(-50%);

          @media (max-width: $breakpoint-tablet) {
            display: none;
          }
        }
      }
    }
  }

  .sales-expanded-reviews {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: relative;

    .mobile-title {
      display: none;
    }

    &.withMobileTitle {
      .mobile-title {
        display: none;
        text-align: center;
        font-size: 26px;
        margin-bottom: 15px;

        @media (max-width: 700px) {
          display: block;
        }
      }

      @media (max-width: $breakpoint-mobile-m) {
        padding-top: 10px !important;
      }
    }

    &.dark {
      background-color: #f2f2f2;
    }

    @media (max-width: 700px) {
      background-color: #f2f2f2;
    }

    &.withAngles {
      overflow: hidden;
      padding: 70px 0;

      @media (max-width: $breakpoint-mobile) {
        padding: 50px 0;
      }

      .angle-top,
      .angle-bottom {
        background-color: #f2f2f2;
        width: 200vw;
        height: 200px;
        position: absolute;
        z-index: -1;
        left: 50%;

        @media (max-width: 700px) {
          display: none;
        }
      }

      .angle-top {
        top: -110px;
        transform: translateX(-50%) rotate(-3deg);

        @media (max-width: $breakpoint-mobile) {
          top: -155px;
          transform: translateX(-50%) rotate(-4deg);
        }
      }

      .angle-bottom {
        transform: translateX(-50%) rotate(3deg);
        bottom: -110px;

        @media (max-width: $breakpoint-mobile) {
          transform: translateX(-50%) rotate(4deg);
          bottom: -155px;
        }
      }
    }

    &.kavli-review {
      padding-top: 20px;
      padding-bottom: 50px;

      @media (max-width: $breakpoint-mobile) {
        padding-top: 0;
        padding-bottom: 40px;
      }

      @media (max-width: 880px) {
        .shape {
          display: none !important;
        }
      }

      .content {
        padding-right: 100px;

        @media (max-width: 880px) {
          padding-right: 0;
        }
      }
    }

    &.shannon-review {
      padding-top: 110px;

      @media (max-width: $breakpoint-mobile) {
        padding-top: 75px;
      }

      @media (max-width: $breakpoint-mobile-m) {
        padding-top: 0;
      }
    }

    &.jennifer-review {
      .angle-top {
        @media (max-width: 820px) {
          transform: translateX(-50%) rotate(4deg);
          top: -155px;
        }
      }

      .angle-bottom {
        @media (max-width: 820px) {
          transform: translateX(-50%) rotate(4deg);
          bottom: -155px;
        }
      }

      .image-container {
        margin-left: 110px !important;

        @media (max-width: 820px) {
          margin-left: 25px !important;
        }
      }
    }

    .image-container {
      position: relative;
    }

    .shape {
      display: none;
      position: absolute;
      width: 470px;
      height: 470px;
      bottom: -110px;
      left: -110px;
      z-index: -1;
    }

    &.withShape {
      .shape {
        display: block;

        @media (max-width: 820px) {
          display: none;
        }
      }
    }

    &.withShapeTop {
      .shape {
        display: block;
        top: -140px;
        left: -140px;

        @media (max-width: 820px) {
          display: none;
        }
      }
    }

    .expanded-reviews-container {
      max-width: 990px;
      width: auto;

      .review {
        display: flex;
        align-items: center;

        @media (max-width: 700px) {
          flex-direction: column;
          max-width: 350px;
          margin: 0 auto;
        }

        @media (max-width: $breakpoint-mobile-m) {
          max-width: calc(100vw - 20px);
        }

        &:not(:last-child) {
          margin-bottom: 95px;
        }

        &.reversed {
          flex-direction: row-reverse;

          @media (max-width: 700px) {
            flex-direction: column;
          }

          .image-container {
            margin-left: 50px;
            margin-right: 0;

            @media (max-width: $breakpoint-mobile) {
              margin-left: 25px;
            }

            @media (max-width: 700px) {
              margin: 0 0 20px 0 !important;
            }

            @media (max-width: $breakpoint-mobile-m) {
              width: 100%;
              height: fit-content;
            }
          }
        }

        .image-container {
          min-width: 401px;
          height: 391px;
          margin-right: 50px;
          z-index: 1;

          @media (max-width: $breakpoint-mobile) {
            min-width: 350px;
            height: 350px;
            margin-right: 25px;
          }

          @media (max-width: 700px) {
            margin: 0 0 20px 0;
          }

          @media (max-width: $breakpoint-mobile-m) {
            width: 100%;
            height: fit-content;
            min-width: 0;
          }

          .photo {
            width: 100%;
            height: 100%;
          }
        }

        .title {
          // font-family: MontserratBold;
          font-weight: 700;
          font-size: 18px;
          color: #292929;
          margin-bottom: 15px;

          @media (max-width: $breakpoint-mobile) {
            margin-bottom: 10px;
          }

          @media (max-width: 700px) {
            display: none;
          }
        }

        .body {
          //font-family: MontserratRegular;
          font-weight: 400;
          font-size: 14px;
          color: #292929;
          line-height: 22px;
          margin-bottom: 30px;

          @media (max-width: $breakpoint-mobile) {
            margin-bottom: 15px;
          }
        }

        .author {
          // font-family: MontserratBold;
          font-weight: 700;
          font-size: 12px;
          letter-spacing: 2px;
          color: #000;
          text-transform: uppercase;

          @media (max-width: 700px) {
            text-align: center;
          }
        }
      }
    }
  }

  .sales-charles {
    height: 680px;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;

    @media (max-width: $breakpoint-mobile-m) {
      display: none;
    }

    .charles-content-container {
      max-width: 1115px;
      display: flex;
      align-items: flex-end;
    }

    .content {
      width: 460px;
      display: flex;
      flex-direction: column;
      margin-bottom: 100px;

      @media (max-width: $breakpoint-mobile) {
        width: 320px;
        margin-bottom: 50px;
        margin-left: 20px;
      }

      .quote {
        // font-family: MontserratSemiBold;
        font-weight: 600;
        font-size: 27px;
        line-height: 40px;
        letter-spacing: 0.8px;
        color: #fff;
        text-align: center;
        text-shadow: 2px 3px 7px rgba(0, 0, 0, 0.47577);
        margin-bottom: 20px;

        @media (max-width: $breakpoint-mobile) {
          font-size: 18px;
        }
      }

      .author {
        //font-family: MontserratItalic;
        font-weight: 400;
        font-style: italic;
        font-size: 20px;
        letter-spacing: 0.6px;
        color: #fff;
        text-shadow: 2px 3px 7px rgba(0, 0, 0, 0.47577);
        text-align: center;

        @media (max-width: $breakpoint-mobile) {
          font-size: 18px;
        }
      }
    }
  }

  .sales-pricing {
    background-color: #f2f2f2;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 80px;

    @media (max-width: 590px) {
      background: white;
    }

    @media (max-width: 590px) {
      padding-bottom: 50px;
    }

    .pricing-image {
      width: 100vw;
    }

    .pricing-container {
      width: calc(100vw - 210px - 60px);
      max-width: 830px;
      margin-top: -140px;
      border-radius: 20px;
      background-color: white;
      padding: 60px 105px;

      @media (max-width: 1030px) {
        width: calc(100vw - 100px - 60px);
        padding: 30px 50px 50px;
      }

      @media (max-width: 830px) {
        width: calc(100vw - 40px - 60px);
        padding: 30px 20px 50px;
      }

      @media (max-width: $breakpoint-mobile) {
        margin-top: 15px;
        border-radius: 0;
      }

      @media (max-width: 590px) {
        width: 100vw;
        padding: 0;
      }

      h2 {
        font-size: 42px;
        letter-spacing: 1.2px;
        text-align: center;
        margin-bottom: 70px;

        @media (max-width: $breakpoint-mobile) {
          margin-bottom: 40px;
          font-size: 30px;
        }

        @media (max-width: 590px) {
          display: none;
        }
      }

      .mobile-title {
        display: none;
        padding: 0 15px;
        margin-bottom: 20px;

        .title {
          font-size: 26px;
          border-bottom: 2px solid #000;
          width: 100%;
          text-align: center;
          padding-bottom: 15px;
          margin-bottom: 10px;
        }

        .subheader {
          font-size: 14px;
          color: #8c8c8c;
        }

        @media (max-width: 590px) {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }

      .list {
        padding: 0 25px 50px;
        display: flex;
        flex-wrap: wrap;
        border-bottom: 5px solid #f2f2f2;
        margin-bottom: 60px;

        @media (max-width: $breakpoint-mobile) {
          padding: 0 0 50px;
          margin-bottom: 45px;
        }

        @media (max-width: 590px) {
          display: none;
        }

        .element {
          width: 50%;
          display: flex;
          align-items: center;
          margin-bottom: 10px;

          .check-container {
            min-width: 29px;
            height: 29px;
            background-color: #000;
            border-radius: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 20px;

            img {
              width: 15px;
            }
          }

          p {
            // font-family: MontserratSemiBold;
            font-weight: 600;
            font-size: 14px;
            letter-spacing: 1px;

            @media (max-width: $breakpoint-mobile) {
              font-size: 12px;
            }
          }
        }
      }

      .plans-title {
        text-align: center;
        // font-family: MontserratSemiBold;
        font-weight: 600;
        font-size: 20px;
        letter-spacing: 0.2px;
        color: #0d0c0c;
        margin-bottom: 50px;

        @media (max-width: $breakpoint-mobile) {
          margin-bottom: 30px;
        }

        @media (max-width: 590px) {
          display: none;
        }
      }

      .plans {
        padding: 0 45px;
        display: flex;
        margin-bottom: 70px;

        @media (max-width: $breakpoint-mobile) {
          margin-bottom: 50px;
          padding: 0 40px;
        }

        @media (max-width: 710px) {
          padding: 0;
        }

        @media (max-width: 590px) {
          flex-direction: column;
          align-items: center;
          margin-bottom: 30px;
        }

        .plan {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 3px 0;
          border-right: 5px solid #f2f2f2;

          @media (max-width: 590px) {
            max-width: 165px;
            border-right: none;
            border-bottom: 5px solid #f2f2f2;
            padding-bottom: 20px;
            margin-bottom: 30px;
          }

          &:last-child {
            border-right: 0;
            border-bottom: 0;
            margin-bottom: 0;
          }

          .price {
            font-size: 36px;
            letter-spacing: 1px;
            color: #0d0c0c;

            @media (max-width: 590px) {
              font-size: 20px;
            }

            span {
              font-size: 22px;
              letter-spacing: 0.6px;
            }
          }

          .name {
            font-size: 12px;
            letter-spacing: 1.3px;
            color: #8c8c8c;
            margin-top: 8px;
          }
        }
      }

      .button-container {
        display: flex;
        justify-content: center;

        .action-button {
          padding: 17px 40px;
          border-radius: 50px;
          background-color: #0d0c0c;
          border: none;
          // font-family: MontserratSemiBold;
          font-weight: 600;
          font-size: 13px;
          letter-spacing: 2px;
          color: #fff;
          width: auto;
          margin: 0;
        }
      }
    }
  }

  .sales-forecast {
    height: 630px;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;

    @media (max-width: $breakpoint-mobile-m) {
      display: none;
    }

    .charles-content-container {
      max-width: 1200px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
    }

    .content {
      width: 370px;
      display: flex;
      flex-direction: column;
      margin-top: 170px;

      @media (max-width: $breakpoint-mobile) {
        margin-top: 90px;
      }

      .quote {
        // font-family: MontserratSemiBold;
        font-weight: 600;
        font-size: 27px;
        line-height: 40px;
        letter-spacing: 0.8px;
        color: #fff;
        text-align: center;
        text-shadow: 2px 3px 7px rgba(0, 0, 0, 0.47577);
        margin-bottom: 20px;

        @media (max-width: $breakpoint-mobile) {
          font-size: 20px;
        }
      }
    }
  }

  .sales-reviews-slider {
    display: flex;
    justify-content: center;

    @media (max-width: $breakpoint-mobile-m) {
      background-color: #f2f2f2;
    }

    .reviews-slider-container {
      padding: 65px 0;
      width: calc(100vw - 130px);

      @media (max-width: $breakpoint-mobile) {
        width: calc(100vw - 50px);
        padding: 40px 25px 30px;
      }

      @media (max-width: $breakpoint-mobile-m) {
        padding: 40px 15px 0;
        width: calc(100vw - 30px);
      }

      h2 {
        padding-bottom: 30px;
        border-bottom: 3px solid #000;
        margin-bottom: 65px;

        @media (max-width: $breakpoint-mobile-m) {
          padding-bottom: 0;
          border: none;
          margin-bottom: 25px;
          text-align: center;
        }
      }

      .carousel {
        height: fit-content;
      }

      .sales-review {
        margin-bottom: 30px;
        padding: 0 10px;

        @media (max-width: $breakpoint-mobile-m) {
          padding: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }

        .review-header {
          display: flex;
          align-items: center;
          margin-bottom: 8px;

          .photo {
            width: 60px;
            height: 60px;
            border-radius: 30px;
            background-size: cover;
            background-position: center;
            margin-right: 15px;
          }

          .name {
            // font-family: MontserratSemiBold;
            font-weight: 600;
            font-size: 14px;
            color: #0d0c0c;
          }
        }

        .review-body {
          //font-family: MontserratRegular;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #292929;
        }

        .rating {
          margin-top: 5px;
          img {
            width: 11px;
            margin-right: 2px;
          }

          p {
            display: none;
          }

          .disabled {
            opacity: 0.25;
          }
        }
      }

      .slider-navigation {
        height: 76px;
        background-color: #f2f2f2;
        width: fit-content;
        margin: 80px auto 0;
        display: flex;
        align-items: center;
        padding: 0 30px;

        @media (max-width: $breakpoint-mobile) {
          margin-top: 30px;
        }

        @media (max-width: $breakpoint-mobile-m) {
          height: fit-content;
          padding: 13px 0 20px;
          margin-top: 20px;
          border-top: 2px solid #000;
          width: 100%;
          display: flex;
          justify-content: space-between;
        }

        p {
          margin: 0 30px;
          // font-family: MontserratSemiBold;
          font-weight: 600;
          font-size: 15px;
          letter-spacing: 2.3px;
          text-transform: uppercase;
        }

        button {
          background: transparent;
          border: none;

          &:last-child {
            img {
              transform: rotate(180deg);
            }
          }
        }
      }
    }
  }

  .sales-florence {
    height: 630px;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;

    @media (max-width: $breakpoint-mobile-m) {
      height: 430px;
    }

    .florence-content-container {
      max-width: 940px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;

      @media (max-width: $breakpoint-mobile-m) {
        height: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        padding: 0;
      }
    }

    .content {
      max-width: 450px;
      display: flex;
      flex-direction: column;
      margin-top: 120px;

      @media (max-width: $breakpoint-mobile) {
        margin-top: 90px;
      }

      @media (max-width: $breakpoint-mobile-m) {
        margin-top: 90px;
        background-color: #000;
        padding: 22px 0 14px;
        width: 100%;
        align-items: center;
        max-width: none;
      }

      .quote {
        // font-family: MontserratSemiBold;
        font-weight: 600;
        font-size: 27px;
        line-height: 40px;
        letter-spacing: 0.8px;
        color: #fff;
        text-align: center;
        text-shadow: 2px 3px 7px rgba(0, 0, 0, 0.47577);
        margin-bottom: 20px;

        @media (max-width: $breakpoint-mobile-m) {
          font-size: 16px;
          letter-spacing: 0.5px;
          line-height: 25px;
          max-width: 280px;
        }
      }

      .author {
        //font-family: MontserratItalic;
        font-weight: 400;
        font-style: italic;
        font-size: 20px;
        letter-spacing: 0.6px;
        color: #fff;
        text-shadow: 2px 3px 7px rgba(0, 0, 0, 0.47577);
        text-align: center;

        @media (max-width: $breakpoint-mobile-m) {
          font-size: 16px;
        }
      }
    }
  }

  .sales-why {
    display: flex;
    justify-content: center;
    background-color: #f2f2f2;

    @media (max-width: $breakpoint-mobile-m) {
      background: transparent;
    }

    .why-container {
      padding-top: 105px;
      padding-bottom: 90px;
      max-width: 1070px;

      @media (max-width: $breakpoint-mobile) {
        padding-top: 40px;
        padding-bottom: 30px;
      }

      @media (max-width: $breakpoint-mobile-m) {
        padding-top: 35px;
        padding-bottom: 40px;
      }

      h2 {
        margin-bottom: 35px;

        @media (max-width: $breakpoint-mobile-m) {
          font-size: 22px;
          letter-spacing: 0.8px;
          max-width: 260px;
          margin: 0 auto 25px;
          text-align: center;
        }
      }

      p + p {
        margin-top: 22px;
      }

      p {
        // font-family: MontserratMedium;
        font-weight: 500;
        letter-spacing: 0.4px;
        color: #0d0c0c;

        &.bold,
        .bold {
          // font-family: MontserratBold;
          font-weight: 700;
          letter-spacing: 0.4px;
          color: #0d0c0c;
        }
      }

      .video {
        min-width: 655px;
        padding-bottom: 368px;

        @media (max-width: $breakpoint-mobile) {
          min-width: 0;
          width: 100%;
          padding-bottom: calc(100% * 0.56);
        }

        &.mobile-video {
          display: none;
        }

        @media (max-width: $breakpoint-mobile-m) {
          margin-bottom: 20px;
          display: none;
          padding-bottom: calc(100% * 0.56);

          &.mobile-video {
            display: block;
          }
        }

        .shape {
          position: absolute;
          left: -158px;
          bottom: -50px;

          @media (max-width: $breakpoint-mobile-m) {
            display: none;
          }
        }
      }

      .video-container {
        display: flex;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 80px;

        @media (max-width: 1120px) {
          flex-direction: column;
          margin-bottom: 22px;
          margin-top: 22px;
        }

        .content {
          padding-left: 30px;

          @media (max-width: 1120px) {
            padding: 0;
            margin-top: 22px;
          }

          @media (max-width: $breakpoint-mobile-m) {
            margin-top: 0;
          }
        }
      }
    }
  }

  .sales-faq {
    display: flex;
    justify-content: center;

    .faq-container {
      max-width: 1070px;
      padding-top: 120px;
      padding-bottom: 75px;

      @media (max-width: $breakpoint-mobile) {
        padding-top: 35px;
        padding-bottom: 50px;
      }

      @media (max-width: $breakpoint-mobile-m) {
        padding-bottom: 45px;
      }

      h2 {
        margin-bottom: 40px;

        @media (max-width: $breakpoint-mobile) {
          margin-bottom: 30px;
        }

        @media (max-width: $breakpoint-mobile-m) {
          margin-bottom: 20px;
        }
      }

      .questions {
        .question {
          margin-bottom: 45px;

          @media (max-width: $breakpoint-mobile) {
            margin-bottom: 40px;
          }

          &:last-child {
            margin-bottom: 0;
          }

          .title {
            // font-family: MontserratBold;
            font-weight: 700;
            font-size: 14px;
            letter-spacing: 0.4px;
            color: #0d0c0c;
          }

          .body {
            // font-family: MontserratMedium;
            font-weight: 500;
            font-size: 14px;
            color: #0d0c0c;
            letter-spacing: 0.4px;

            p {
              margin-bottom: 30px;

              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }

  .sales-footer {
    display: flex;
    justify-content: center;
    height: 150px;
    background-color: #000;

    @media (max-width: 660px) {
      height: auto;
    }

    .footer-container {
      max-width: 1220px;
      display: flex;
      align-items: center;

      @media (max-width: 660px) {
        flex-direction: column;
        padding: 15px 15px 30px;
      }

      img {
        margin-right: 25px;

        @media (max-width: $breakpoint-mobile) {
          margin-right: 50px;
        }

        @media (max-width: 660px) {
          margin-right: 0;
          margin-bottom: 20px;
        }

        @media (max-width: $breakpoint-mobile-m) {
          width: 68px;
        }
      }

      p {
        // font-family: MontserratMedium;
        font-weight: 500;
        font-size: 15px;
        letter-spacing: 0.5px;
        color: white;
        margin-top: 20px;

        @media (max-width: $breakpoint-mobile) {
          margin-top: 0;
        }

        @media (max-width: 660px) {
          font-size: 13px;
        }

        @media (max-width: $breakpoint-mobile-m) {
          font-size: 10px;
        }
      }
    }
  }
}
.recipe-wrapper{
  width: 100%;
}
.recipeContainer {
  width: 100%;
  height: 100%;
  flex-grow: 1;
  max-width: 100%;
  padding: 0;
  z-index: 0;

  &.lessPadding {
    padding: 0 0 60px;
  }

  @media print {
    padding-top: 30px !important;
  }

  &.fluid {
    max-width: 100%;
  }

  &.wide {
    max-width: 1200px;
  }

  &.medium {
    max-width: 1080px;
  }

  &.center {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: 1260px) {
    padding:0px;

    &.lessPadding {
      padding: 0 40px 40px;
    }
  }

  @media (max-width: 767px) {
    // padding: 76px 20px 40px;

    &.lessPadding {
      padding: 0 20px 93px;
    }
  }
}

.fraction {
  position: relative;
  margin: 0 4px;
  .numerator {
    position: absolute;

    top: -4px;
    left: -4px;
    font-size: 13px;
    font-weight: 600;

    @media screen and (min-width: 2000px) {
      font-size: 16px;
    }
  }
  .denominator {
    position: absolute;
    top: 5px;
    left: 8px;
    font-size: 13px;
    font-weight: 600;

    @media screen and (min-width: 2000px) {
      font-size: 16px;
    }
  }
  .sup-denominator {
    position: absolute;
    top: 9px;
    left: 1.5px;
    font-size: 7px;
    font-weight: 600;

    @media screen and (min-width: 2000px) {
      font-size: 12px;
    }
  }
  .sup-numerator {
    position: absolute;
    top: 7px;
    left: -4px;
    font-size: 7px;
    font-weight: 600;

    @media screen and (min-width: 2000px) {
      font-size: 12px;
    }
  }
  @media (max-width: $breakpoint-mobile-m) {
    .numerator {
      position: absolute;
      top: -4px;
      left: -4px;
      font-size: 12px;
    }
    .denominator {
      position: absolute;
      top: 6px;
      left: 7px;
      font-size: 12px;
    }
    .sup-numerator {
      position: absolute;
      top: 6px;
      left: -4px;
      font-size: 7px;
      font-weight: 600;
    }
    .sup-denominator {
      position: absolute;
      top: 9px;
      left: 2px;
      font-size: 7px;
      font-weight: 600;
    }
  }
}
.top-recipe {
  &-navigation {
    margin: 0 auto;
    margin-top: 20px;
  }
  &.gray {
    background: #eeeeee;
  }
  &--container {
    padding-bottom: 56px !important;
  }
  padding: 28px 0px 0px;
  & > h2 {
    font-size: 22px;
    font-weight: 800;
    font-size: 22px;
    line-height: 30px;
    letter-spacing: 0.54px;

    color: #0d0c0c;
  }
}
.recipe {
  &:hover {
    cursor: pointer;
  }

  &-h2 {
    margin-bottom: 19px;
    font-weight: 800;
    font-size: 22px;
    line-height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-transform: uppercase;
    /* identical to box height */
    letter-spacing: 0.54px;
  }
  &-card-main {
    overflow: hidden;
    width: 100%;
    height: 327px;
    margin-bottom: 4px;
    position: relative;
    
    @media screen and (min-width: 2000px) {
      height: 400px;
    }

    &.wrap {
      @media screen and (max-width: 475px) {
        height: 235px;
        &.embla__slide {
          flex: 0 0 48%;
        }
      }
    }
  }
  &-image {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    // width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;

    &.mobile {
      @media screen and (max-width: 475px) {
        background-size: 100% 150px;
        background-position: 0 0;
        position: relative;
      }
    }
  }
  &-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    letter-spacing: 0;
    color: #0d0c0c;
    margin-bottom: 16px;

    @media screen and (min-width: 2000px) {
      font-size: 24px;
      line-height: 27px;
    }

    &.recipe-page-title {
      text-align: left;
      text-transform: uppercase;
      font-size: 16px;
      font-weight: 800;
      letter-spacing: 2px;

      @media screen and (min-width: 2000px) {
        font-size: 28px;
        line-height: 30px;
      }
    }

    &.mobile {
      @media screen and (max-width: 475px) {
        text-align: start;
        align-self: flex-start;
        margin-bottom: auto;
        margin-top: 10px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }
  &-info {
    display: flex;
    gap: 8px;
    font-weight: 400;
    font-size: 12px;

    @media screen and (min-width: 2000px) {
      font-size: 18px;
    }

    @media (max-width: $breakpoint-mobile) {
      font-size: 14px;
    }
    & > span > img {
      @media (max-width: $breakpoint-mobile) {
        width: 8px;
        aspect-ratio: 1/1;
      }
      @media (max-width: $breakpoint-mobile-m) {
        width: 12px;
        aspect-ratio: 1/1;
      }
    }
  }
  &-time {
    display: flex;
    align-items: center;
    gap: 6px;

    & img {
      transform: translateY(-2px);
    }
  }
  &-rating {
    background: #000;
    padding: 7px 6px;
    color: white;
    font-size: 12px;
    font-weight: 600;
    transform: translate(-50%,-50%);
    position: absolute;
    top: 0;
    left: 50%;

    @media screen and (min-width: 2000px) {
      font-size: 18px;
    }

    &.mobile {
      @media screen and (max-width: 475px) {
        left: unset;
        right: 5px;
        top: 5px;
        transform: translate(0, 0);
      }
    }
  }
  &-content {
    background: rgba(255, 255, 255, 1);
    border-radius: 0;
    // width: fit-content;

    border: none;
    padding: 14px;
    width: 100%;
    min-height: 90px;
    justify-content: center;

    @media (max-width: $breakpoint-mobile) {
      font-size: 10px;
      align-items: flex-start;
    }
    @media (max-width: $breakpoint-mobile-m) {
      padding: 0;
      padding-bottom: 20px;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    &.mobile {
      @media screen and (max-width: 475px) {
        background: transparent;
        justify-content: flex-end;
        position: static;
        height: 100%;
      }
    }
  
    &-big {
      width: max-content;
      padding: 14px 35px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);

      @media screen and (max-width: 475px) {
        width: 100%;
        padding: 0;
        position: static;
        transform: translate(0,0);
      }
    }
  }
}
.plans-up-margin {
  margin-top: 99px;
  @media (max-width: $breakpoint-mobile) {
    margin-top: 49px;
  }
}
.create-plan-title {
  font-size: 18px;
  font-weight: 700;
  text-transform: none;
  width: 70%;
}
.create-plan-button {
  width: max-content;
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  & > span {
    // font-family: "MontserratRegular";
    margin-right: 16px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;

    /* identical to box height */
    letter-spacing: 0.54px;
    text-transform: uppercase;

    color: #000000;

    @media (max-width: $breakpoint-mobile-m) {
      display: none;
      visibility: hidden;
    }
  }
}

.login-as-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  background-color: #ffb900;
  color: white;
  font-size: 16px;
  font-weight: 500;
  padding: 20px;
  text-align: center;

  @media (max-width: 767px) {
    flex-direction: column;
  }
}

.plans-list__empty {
  text-align: center;
  color: $gray;
  font-size: 16px;
  min-height: 85px;
}

.plans--slider {
  position: relative;
  &-buttons {
    width: 100%;
    // position: absolute;
    // top: 380px;
    margin-top: 20px;
    display: none;
    justify-content: space-between;
    .disabled {
      opacity: 0.3;
      pointer-events: none;
    }

    button {
      background: none;
      border: none;
      padding: 0 15px;

      @media (max-width: 450px) {
        height: 32px;
        padding: 0 20px;
      }

      &:first-child img {
        transform: rotate(180deg);
      }

      img {
        &.hidden {
          display: none;
        }
      }
    }
    @media (max-width: $breakpoint-mobile-m) {
      display: flex;
    }
  }
}
.plans--slider-content {
  display: block;

  @media (max-width: $breakpoint-mobile-m) {
    display: none;
  }
  &-mobile {
    display: none;
    @media (max-width: $breakpoint-mobile-m) {
      display: block;
    }
  }
  @media (max-width: $breakpoint-mobile-m) {
    display: none;
  }
}

.embla {
  @media (max-width: $breakpoint-mobile) {
    padding-top: 23px !important;
  }
}
.embla__viewport_default {
  @media (max-width: $breakpoint-mobile) {
    overflow: hidden;

    &.tooltip {
      overflow: visible;
    }
  }
}
.embla__viewport {
  overflow: hidden;

  &.tooltip {
    overflow: visible;
  }
}
.embla__container_default {
  @media (max-width: $breakpoint-mobile) {
    display: flex !important;
    will-change: transform;

    &.wrap {
      flex-wrap: wrap;
      gap: 0 !important;
      justify-content: space-between;
    }
  }
}

.embla__container {
  display: flex !important;
  will-change: transform;

  &.wrap {
    flex-wrap: wrap;
    gap: 0 !important;
    justify-content: space-between;
  }
}

.embla__slide_default {
  @media (max-width: $breakpoint-mobile) {
    position: relative;

    width: 100%;

    flex: 0 0 45%;
  }
    
  @media (max-width: $breakpoint-mobile-m) {
    flex: 0 0 100%;
  }
}

.embla__slide {
  position: relative;

  width: 100%;

  flex: 0 0 45%;
    
  @media (max-width: $breakpoint-mobile-m) {
    flex: 0 0 100%;
  }
}

.slider {
  &-navigation {
    display: flex;
    width: fit-content;
    gap: 24px;
  }
  &-button {
    width: 32px;
    aspect-ratio: 1/1;
    background: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 50%;
    &.disabled {
      background: #0101018c;
    }
    &:last-child {
      transform: rotateY(180deg);
    }
  }
}
.upgrade-dropdown {
  position: fixed;
  top: 80px;
  right: 26%;
  transform: translateX(22%);
  background: white;
  width: 376px;
  aspect-ratio: 376/291;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 10px 10px 24px rgba(0, 0, 0, 0.25);
  z-index: 5;
  @media (max-width: $breakpoint-mobile) {
    bottom: 30px;
    top: auto;
    right: 22%;
    width: 100%;
    height: 291px;
    z-index: 1;
  }
  &--content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &--title {
    margin-bottom: 21px;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 0.06em;
    text-transform: uppercase;

    color: #000000;
  }
  &--description {
    width: 251px;
    text-align: center;
    margin-bottom: 17px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    color: #000000;
  }
  &--close {
    position: absolute;
    width: 50px;
    aspect-ratio: 1/1;
    border-radius: 50%;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 15px;
    right: 15px;
    background: #000;
    & > img {
      width: 16.67px;
      aspect-ratio: 1/1;
    }
  }
}

.signup-wrapper {

  input.input {
    max-width: 100%;
    height: 52px;

    &::placeholder {
      color: #A6A6A6;
      font-size: 14px;
      line-height: 19px;
      font-weight: 400;
    }
  }

  button.button {
    width: unset;
    background-color: $green-200;
    border-radius: 6px;

    padding-top: 2px !important;
    span {
    }

    &-md {
      height: 24px;
      font-size: 10px;
      letter-spacing: 1px;
      font-weight: 600;
      padding: 0 18px;
    }

    &-lg {
      height: 45px;
      padding: 0 40px;
      font-size: 13px;
      line-height: 13px;
      letter-spacing: 1px;
      font-weight: 600;
    }
  }

  &__mobile {
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 3;

    &__container {
      padding: 24px;
      background-color: white;
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }
  // max-width: 1440px;
  display: grid;
  // gap: 78px;
  background-color: white;
  // padding: 86px 90px 0 90px;
  // margin: 0 auto;
  grid-template-columns: repeat(2, 1fr);
  height: calc(100vh);

  &__footer {
    display: flex;
  }

  @media (max-width: $breakpoint-tablet) {
    padding: unset;
    grid-template-columns: 1fr;
  }
  &__right {
    width: 100%;
    padding: 98px 0 32px;

    &__terms {
      font-size: 12px;
      line-height: 16px;
      font-weight: 400;
      color: #9C9C9C;
      margin-top: 5px;
    }

    &__login {
      text-align: center;
      margin-bottom: 40px;

      &-text {
        color: #9C9C9C;
        font-size: 14px !important;
        line-height: 19px !important;
        font-weight: 400;

        a {
          color: $green-200;
        }
      }
    }

    .comment {
      background-color: #e5e5e5;
      padding: 38px 16px 26px 16px;
      margin-bottom: 41px;
    }
    @media (max-width: $breakpoint-tablet) {
      padding: 32px 20px;
      padding-bottom: 24px;

    }
  }

  &__container {
    max-width: 560px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 18px;
    color: #FFFFFF;

    &-black {
      color: red;
    }

    @media (max-width: $breakpoint-laptop) {
    //  padding: 0 20px;
      max-width: 460px;
    }
  }

  &__upsell-products {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &__left {
    min-width: 0;
    background-color: $green-600;
    width: 100%;
    padding: 32px 0;

    &__container {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__header {
      display: flex;
      align-items: center;
      gap: 6px;
      padding: 8px 0;

      @media (max-width: $breakpoint-tablet) {
        padding: 0;
      }

      &__button {
        padding: 16px 18px;
        height: 24px;
        width: unset;
        font-size: 10px;
        line-height: 14px;
        font-weight: 600;

        display: flex;
        gap: 10px;

        img {
          width: 12px;
          margin-right: 0;
          margin-bottom: 3px;
        }

      }

      &-img {
        height: 32px;
        width: 32px;
        border-radius: 100%;
      }

      &-name {
        font-size: 14px;
        line-height: 12px;
        letter-spacing: 0.56px;
        font-weight: 500;
      }
    }

    &__banner {
      width: 100%;
      aspect-ratio: 460/233;
      border-radius: 6px;

      @media (max-width: $breakpoint-tablet) {
        aspect-ratio: 172/100;
        margin: 0 auto;
      }
    }

    &__top {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
    }

    &__info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 8px;
      padding: 12px 0;
      padding-bottom: 11px;
      color: white;
      font-weight: 700;
      max-width: 364px;
      margin: 0 auto;
      width: 100%;

      @media (max-width: $breakpoint-tablet) {
        margin: 0;
        max-width: 100%;
      }

      &-black {
        color: black;
      }


      &__title {
        font-size: 20px;
        line-height: 21px;
      }

      &__description {
        font-size: 12px;
        line-height: 16.34px;
        font-weight: 400;
        color: $green-100;
        font-family: OpenSansRegular;

        &-black {
          color: #565555;
        }
      }

      &-left {
        // max-width: 290px;
      }

      &-right {
        font-size: 16px;
        line-height: 28px;
        text-align: right;
        min-width: fit-content;
      }
    }

    &__summary {
      display: flex;
      justify-content: end;

    
    }

    .divider {
      height: 1px;
      width: 100%;
      background-color: $green-300;
    }

    .banner {
      margin-bottom: 59px;
      max-width: 677px;
      width: 100%;
      display: block;
    }

    @media (max-width: $breakpoint-tablet) {
      padding: 24px;
      padding-bottom: 40px;
    }
    .comments {
      padding: 0 28px;
      display: flex;
      flex-direction: column;
      row-gap: 44px;
      .decoration {
        width: 75%;
        height: 2px;
        background: #2e4741;
        margin: 40px auto;
      }
      @media (max-width: $breakpoint-tablet) {
        padding: 0;
        row-gap: 10px;
        background-color: #e5e5e5;
        padding: 38px 16px 26px 16px;
      }
    }
  }
}

.sign-comment {
  display: flex;
  gap: 38px;
  width: 100%;
  &.mobile {
    flex-direction: column;
    gap: 16px;
  }
  &__description {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: left;

    /* or 125% */

    color: #ffffff;
    @media (max-width: $breakpoint-tablet) {
      color: black;
      text-align: center;
    }
  }
  &__name {
    font-weight: 700;
    font-size: 15px;
    line-height: 120%;
    text-align: center;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    text-align: justify;

    @media (max-width: $breakpoint-tablet) {
      color: black;
    }
    color: #ffffff;
  }
  &__avatar {
    width: 100%;
    height: 100%;
    // min-width: 68px;
    width: 126px;
    aspect-ratio: 1/1;
    border-radius: 50%;
    object-fit: cover;
    &.mobile {
      width: 68px;
      height: 68px;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    &.mobile {
      flex-direction: row;
      justify-content: center;
      gap: 20px;
      align-items: center;
    }
  }
}

.sign-up-form {
  .input-container {
    margin-bottom: 0;
  }

  &__form-error {
    // display: none;
  }

  &__country {
    margin-top: 10px;
    margin-bottom: 5px;
  }

  &__select {
    max-width: unset !important;

    &-input {
      width: 100%;
      border: none !important;
      max-width: unset !important;
      padding: 0 !important;
    }
  }

&__button {
  margin-top: 21px;
}

  color: $main-black;

  @media (max-width: $breakpoint-tablet) {
    padding: 0;
  }

  &__title {
    &-wrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    &-button {
      background: transparent;
      border: none;
      margin-bottom: 18px;
      color: $gray;
      display: flex;
      align-items: center;
      gap: 6px;

      @media (max-width: $breakpoint-tablet) {
        margin-bottom: 24px;
      }

      &-icon {
        width: 20px;
        height: 16px;

        @media (max-width: $breakpoint-tablet) {
          position: relative;
          top: -2px;
          width: 16px;
          height: 16px;
        }
      }
    }

    margin-bottom: 18px;
    padding: 0 0 16px;
    font-size: 20px;
    line-height: 20px;
    font-weight: 600;
    display: flex;
    align-items: center;

    @media screen and (min-width: 2000px) {
      font-size: 26px;
      line-height: 26px;
    }

      @media (max-width: $breakpoint-tablet) {
        line-height: 27px;
        margin-bottom: 24px;
        height: auto;
        padding: 0;
      }
  }
  &__info {
    display: flex;
    margin-bottom: 15px;
    @media (max-width: $breakpoint-tablet) {
      // margin-bottom: 24px;
    }
  }
  &__radio {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
  }
  &__form {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 15px;

    &-label {
      font-size: 14px !important;
      line-height: 19px !important;
      margin-bottom: 8px !important;
      font-weight: 400 !important;
    }
    &-root {
      width: 100%;
    }
    &-input {
      font-style: normal;
      font-weight: 600 !important;
      font-size: 18px !important;
      line-height: 30px;
      width: 100%;
      /* or 136% */

      color: #000000;
      border-radius: unset !important;
    }
    &-error {
      font-size: 14px !important;
    }
  }

  &__form-input {
    border-radius: 4px !important;
    border: 1px solid #A6A6A6 !important;
    background-color: transparent !important;

    &-left {
      border-right: none !important;
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
    &-right {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }

    &-error {
      border: 1px solid #F44141 !important;
      color: #F44141 !important;
    }
  }
}
.payment-details {

  .card-number {
    position: relative;

  }

  &__header {
    display: flex;
    justify-content: space-between;
    color: $main-black;
    margin-bottom: 8px;

    @media (max-width: $breakpoint-tablet) {
      flex-direction: column-reverse;
      align-items: start;
      gap: 24px;
    }

    h3, p {
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
    }

  }

  &__title {
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 15px;

    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 13px;
    @media (max-width: $breakpoint-mobile-m) {
      font-size: 16px;
      margin-bottom: 8px;
    }

  }
  &__description {
    display: flex;
    gap: 4px;
    flex: 1;
    align-items: center;
    justify-content: end;

    .lock {
      width: 15px;
      padding-bottom: 4px;
    }

    &-text {
      color: #9C9C9C;
      font-size: 12px;
      line-height: 16px;
    }

    @media (max-width: $breakpoint-mobile-m) {
      font-size: 13px;
    }
  }
  &__submit {
    margin: 40px auto 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 40px;
    .submit-button {
      width: 241px;
    }
    .submit-info {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;

      /* or 129% */
      text-align: center;
      @media (max-width: $breakpoint-mobile-m) {
        width: 230px;
      }
      color: #7d9688;
      & > a {
        color: #7d9688;
        text-decoration: underline;
      }
    }
  }
  &__card {
    background: #fff;
    display: flex;
    flex-direction: column;
    // padding: 25px 20px;

    .card-field {
      display: flex;
      flex-direction: column;
      row-gap: 2px;
      &.promocode {
        margin-top: 20px;
      }

      .error {
        display: none;
      }
      &__input {
        font-family: JosefinSans;
        font-style: normal;
        font-size: 14px;
        line-height: 19px;
        height: 52px;
        &::placeholder {
          color: #A6A6A6;
        }

        // border-radius: 4px !important;
        border: 1px solid #A6A6A6;
        background-color: transparent !important;
        padding: 16px;

        &-top {
          border-top-right-radius: 4px;
          border-top-left-radius: 4px;
          border-bottom: none;
        }

        &-bottom-left {
          border-bottom-left-radius: 4px;
          border-right: 0;
        }

        &-bottom-right {
          border-bottom-right-radius: 4px;
        }

      }
    }
    .card-cards {
      // display: flex;
      // justify-content: space-between;
      position: absolute;
      top: 14px;
      right: 14px;
      &__cards {
        display: flex;
        // width: 52%;
        // overflow-x: hidden;

        justify-content: space-between;
        // @media (min-width: $breakpoint-mobile-m) {
        //   // width: 62%;
        // }
        // @media (min-width: $breakpoint-tablet) {
        //   justify-content: space-between;
        //   // width: 62%;
        // }
        gap: 9px;
        &.item {
          width: 35px;
          min-width: 35px;
          max-height: 30px;
        }
      }
    }
    .card-title {
      font-style: normal;
      font-size: 14px;
      font-weight: 400;
      line-height: 19px;
      white-space: nowrap;
      margin-bottom: 8px;

      /* or 123% */

      color: $main-black;
    }
    .card-cvc,
    .card-expiry {
      width: 100%;
    }
    .second-line {
      display: grid;
      margin-bottom: 15px;
      // gap: 36px;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      // @media (max-width: 319px) {
      //   grid-template-columns: repeat(1, minmax(0, 1fr));
      // }
    }
  }
}
.sign-radio {
  display: flex;
  flex-direction: column;
  gap: 6px;
  z-index: 2;


  @media (max-width: $breakpoint-mobile) {
    gap: 8px;
  }
  align-items: center;
  cursor: pointer;
  &__dot {
    appearance: none;
    border-radius: 100%;
    background: $green-700;
    min-width: 18px;
    height: 18px;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    @media (max-width: $breakpoint-mobile) {
      min-width: 15px;
      height: 15px;
    }
    &::before {
      content: "";
      width: 0.75em;
      height: 0.75em;
      border-radius: 100%;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      background-color: $green-black;
    }
    &:checked {
      background-color: white;
    }
    &:checked::before {
      transform: scale(1);
      width: 12px;
      height: 12px;
      background-color: $green-800;
    }
  }
  &__content {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 35px;

    @media (max-width: $breakpoint-mobile) {
      font-size: 15px;
      line-height: 30px;
    }

    color: #ffffff;
  }
}
.sign-input__wrapper {
  position: relative;
  flex: 1;
  .show-feature {
    background: transparent;
    border: none;
    position: absolute;
    right: 16px;
    top: 45px;
  }
  .show-label {
    font-size: 14px;
  }
}

.upgrade-success-modal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 48px;
}

.subscription-plan-select {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;

  &__radioButtons {
    display: flex;
    align-items: center;
  }

  &__prices {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }


  &__item {
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex: 1;


    &-start {
      text-align: start;
      align-items: start;

    }
    &-center {
      text-align: center;
      align-items: center;
    }
    &-end {
      text-align: end;
      align-items: end;
    }
  }

  &__period {
    font-size: 12px;
    line-height: 16px;
    color: $green-100;


  }

  &__price {
    font-size: 16px;
    line-height: 12px;
    font-weight: 600;
    color: white;
  }

  &__discount {
    background-color: $main-black;
    padding: 2px 6px;
    border-radius: 10px;
   
    width: fit-content;

    span {
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 0.56px;
      color: white;
    }
  }

  &__line {
    flex: 1;
    height: 12px;
    background-color: $green-300;
    transform: scaleX(1.1);
  }
}

.cart-summary {
  margin: 0 auto;
  max-width: 364px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 18px;
  color: white;
  font-size: 16px;
  line-height: 16px;
  font-weight: 600;

  @media (max-width: $breakpoint-tablet) {
    max-width: unset;
  }

  &__totals {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__products {
    display: flex;
    flex-direction: column;
    gap: 18px;
  }

  &-black {
    color: $main-black;
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: $green-700;
  }

  &__button {
    width: 210px !important;
    height: 36px;
    font-size: 12px;
    line-height: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    margin-bottom: -4px;

   &-mobile {
    width: 96px !important;
    min-width: fit-content;
    margin: 0 auto;
   }
  }

  &__promocode {
    position: relative;
    &__button {
      position: absolute;
      right: 14px;
      top: 9px;
    }
  }

  &__input {
    height: 42px !important;
    border: 2px solid #A6A6A6 !important;
    font-size: 14px;
    line-height: 19px;
    font-weight: 400;
    color: #565555;
    padding-right: 100px !important;
    background-color: white !important;

    @media (max-width: $breakpoint-tablet) {
      border: 2px solid rgba(28, 176, 94, 0.5) !important;
    }

    &-error {
      margin-top: 8px;
      color: red;
      font-weight: 400;
      font-size: 14px;
    }
  }

  &__applied-promocode {
    display: flex;
    flex-direction: column;
    gap: 4px;
    color: #97AFA1;
    font-weight: 600;
    margin-bottom: -4px;

    &-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-tag {
      background-color: $green-400;
      padding: 9px 18px;
      border-radius: 6px;
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 12px;
      line-height: 14.5px;
      letter-spacing: 1px;
      height: 36px;
    }

    &-amount {
      font-size: 16px;
      line-height: 16px;
    }

    &-percentage {
      font-size: 10px;
      line-height: 12px;
    }

    &-close {
      width: 24px;
      height: 24px;
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
    }
  }
}

.cart-summary-item {
  display: grid;
  grid-template-columns: 2fr 1fr;

  &__end {
    display: flex;
    justify-content: end;
    gap: 4px;
    align-items: center;
  }

  &__subtotal {
    text-decoration: line-through;
    font-weight: 400;
    color: $green-100;
    font-weight: 14px;
  }

  &__left {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  &__remove {

    width: 18px;
    height: 18px;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
  }
}

.upsell-product {
  width: 100%;
  background-color: #E3E3E3;
  border-radius: 6px;
  padding: 12px;
  display: flex;
  gap: 12px;
  margin-top: 4px;
  min-height: 101px;

  @media (max-width: $breakpoint-mobile) {
    min-height: 120px;
  }

  &__content {
    display: flex;
    gap: 12px;

    @media (max-width: $breakpoint-mobile) {
      display: none;
    }

    &-mobile {
      display: none;

      @media (max-width: $breakpoint-mobile) {
      display: flex;
      flex-direction: column;
      gap: 4px;
      width: 100%;
      color: $main-black;
      }
    }

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  @media (max-width: $breakpoint-mobile) {
    gap: 8px;
  }

  &__image {
    width: 64px;
    height: 64px;
    border-radius: 6px;
  }

  &__info {
    color: $main-black;
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex: 1;
  }

  &__name {
    font-size: 16px;
    line-height: 21px;
    font-weight: 700;

    @media screen and (min-width: 2000px) {
      font-size: 20px;
    }
  }

  &__description {
    font-size: 12px;
    line-height: 16px;

    @media screen and (min-width: 2000px) {
      font-size: 16px;
    }
  }

  &__right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: end;
  }
  &__price {
    font-size: 16px;
    line-height: 28px;
    font-weight: 600;
    color: $main-black;

    @media screen and (min-width: 2000px) {
      font-size: 20px;
    }
  }

  &__button {
    padding: 0 15px !important;

    @media (max-width: $breakpoint-mobile) {
      margin-top: 4px;
      align-self: flex-end;
    }

    @media screen and (min-width: 2000px) {
      font-size: 16px !important;
    }
  }

  &__added-label {
    background-color: white;
    color: $green-200;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    height: 24px;
    font-size: 10px;
    letter-spacing: 1px;
    font-weight: 600;
    padding-top: 2px !important;
  }
}

.card_country_select {

  &__input {
    color: #0d0c0c;

    &-error {

      .select-container {
        border: 1px solid #F44141 !important;
      }
    }
    
    .select-container {
      background-color: white;

      @media (min-width: $breakpoint-mobile) {
        .ReactCollapse--collapse {
          left: -1px !important;
          bottom: -8px !important;
          border-radius: 4px !important;
          border: 1px solid #A6A6A6 !important;
          width: calc(100% + 2px);
        
          .option {
            padding: 10px 15px !important;
            margin: 0;
          }
        }
      }
     
    }

    .value-container {
      height: 52px;

      input {
        font-weight: 600 !important;
        font-size: 18px !important;
        line-height: 30px;

        &::placeholder {
          color: #A6A6A6;
          font-size: 14px;
          line-height: 19px;
          font-weight: 400;
        }
      }
    }

    &-hidden {
      display: none !important;
    }
  }
}

.switch-container {
  width: 100%;
  height: 24px;
  display: flex;
  border-radius: 40px;
  overflow: hidden;
  background-color: $green-300;

  &_button {
    flex: 1;
    color: white;
    font-size: 12px;
    line-height: 16.34px;
    font-weight: 700;
    border: none;
    background-color: $green-300;
    font-family: OpenSansRegular;
  }

  &_button.active {
    background-color: #94B3AB;
    border-radius: 40px;

  }
}

.subscription-plan-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  margin: 0 auto;
  max-width: 364px;

 

  @media (max-width: $breakpoint-tablet) {
    max-width: unset;
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-family: OpenSansBold;
    font-size: 12px;
    line-height: 16.34px;

    &-sale {
      font-size: 9px;
      line-height: 20px;
      font-weight: 700;
      padding-top: 2px;
      font-family: OpenSansBold;
    }
  }

  &__subtitle {
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.8px;
    font-weight: 600;
    font-family: JosefinSans;
    transform: translateY(-4px);
  }

  &__total {
    font-family: JosefinSans;
    font-weight: 600;
    margin-top: -20px;
  }

  &__price {
    font-size: 33px;
    font-weight: 600;
    font-family: JosefinSans;
    padding-top: 6px;
    padding-right: 14px;

    span {
      font-size: 9px;
      line-height: 12px;
    }
  }
}

.grocery-header {
  background: #2E4741;
  height: 226px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  gap: 24px;
  padding-bottom: 10px;

  .close-button {
    position: absolute;
    right: 20px;
    top: 20px;
    border-radius: 100%;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: $breakpoint-mobile-m) {
      width: 50px;
      height: 50px;
      position: fixed;
      right: 10px;
      top: 70px;
      z-index: 1;
      background-color: black;
    }
    @media (min-width: ($breakpoint-mobile-m + 1)) and (max-width: $breakpoint-laptop) {
      background-color: black;
      position: absolute;
      right: 10px;
      top: 10px;
    }
  }

  .title {
    font-weight: 700;
    font-size: 24px;
    line-height: 39px;
    color: white;
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  .plain {
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    color: white;
    padding: 0;
    max-width: 520px;

    @media screen and (max-width: 450px) {
      padding: 0 20px;
    }
  }

  .complete {
    color: white;
    font-size: 13px;
    line-height: 23px;
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  @media screen and (max-width: 500px) {
    height: 240px;
    padding-top: 63px;
    box-sizing: border-box;
  }
}

.filters-search {
  display: flex;
  flex-direction: column;
  gap: 28px;
  margin-bottom: 28px;

  @media screen and (max-width: 1200px) {
    display: none;
  }

  .search-input > .input-container > .wrapper > input {
    height: 50px;
    
    @media screen and (min-width: 2000px) {
      font-size: 20px;
    }
  }

  .filter-statistics {
    justify-content: center;
    align-items: center;
    gap: 10px;

    .divider {
      height: 15px;
      width: 2px;
      background-color: #fff;
      transform: translateY(-3px);
    }

    .title {
      font-size: 14px;
      line-height: 14px;
      text-transform: uppercase;
      color: white;
      @media (min-width: $breakpoint-laptop) {
        display: flex;
      }
      
      .count {
        background-color: transparent !important;
        font-size: 14px;
        line-height: 14px;
        text-transform: uppercase;
        margin-left: 5px;
      }
    }
    .clear-all {
      font-size: 14px;
      line-height: 14px;
      text-transform: uppercase;
      text-decoration: none;
      background-color: transparent;
      color: white;
      transition: color 0.25s ease-in;
      @media (min-width: $breakpoint-laptop) {
        display: block;
      }

      &:hover {
        color: rgb(112, 175, 16);
      }
    }
  }

}

.single-range {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-x: visible;
  position: relative;

  @media screen and (max-width: 1200px) {
    align-items: center;
  }

  .value {
    font-size: 16px;
    font-weight: 600;
    line-height: 14px;
    color: white;
    position: absolute;
    width: max-content;
    top: 13px;

    @media screen and (max-width: 1200px) {
      color: black;
    }
  }

  .range-thumb {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background: white;

    @media screen and (max-width: 1200px) {
      background: black;
    }
  }
  
  .range-track {
    width: 100%;
    height: 3px;
    border-radius: 6px;
    background-color: #fff;

    @media screen and (max-width: 1200px) {
      width: 88%;
      background-color: black;
    }
  }
}

.add-recipe-title {
  color: white;
  margin: 32px 0 0 32px !important;
}

.recipes-grid-container {
  .recipes-grid {
    margin-top: 10px !important;
  }
}

.custom-select {
  width: max-content;
  display: flex;
  align-items: center;
  gap: 10px;

  .label {
    margin: 0;
    font-size: 13px;
    line-height: 23px;
    font-weight: 700;
    color: black;
    text-transform: uppercase;

    @media screen and (min-width: 2000px) {
      font-size: 18px;
      line-height: 23px;
    }
  }

  .select {
    position: relative;
    
    .toggle {
      border: none;
      outline: none;
      background-color: transparent;
      font-size: 13px;
      line-height: 23px;
      font-weight: 700;
      color: black;
      text-decoration: underline;
      text-transform: uppercase;

      @media screen and (min-width: 2000px) {
        font-size: 18px;
        line-height: 23px;
      }
    }

    .options-wrapper {
      width: 110px;
      position: absolute;
      z-index: 10;
      left: 0;
      top: 100%;
      background-color: #fff;
      padding: 10px;
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      box-shadow: 10px 10px 24px rgba(0, 0, 0, 0.25);

      @media screen and (min-width: 2000px) {
        width: 140px;
      }

      .option {
        text-transform: uppercase;
        font-size: 13px;
        line-height: 23px;
        font-weight: 600;
        color: black;
        transition: font-weight 0.25s ease-out;
        cursor: pointer;

        @media screen and (min-width: 2000px) {
          font-size: 18px;
          line-height: 23px;
        }

        &:hover {
          font-weight: 900;
        }
      }
    }
  }
}

.self-end {
  margin-left: auto;

  @media screen and (max-width: 475px) {
    margin-left: 0;
  }
}

.layer {
  background-color: #EEEEEE;
}

.header-pagination {
  width: 230px;
  min-height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  transform: translateY(2px);
  display: none;

  @media screen and (max-width: 475px) {
    display: flex;
  }

  .text {
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 700;
  }

  & > button {
    background-color: transparent;
    border: none;
    outline: none;
    padding: 0 30px;
  }

  & > button:first-child {
    transform: rotate(180deg) translateY(3px);
  }
}

.z-1 {
  position: relative;
  z-index: -1;
}

.checkbox-span {
  font-size: 24px;
}

.title-icon {
  width: 22px;
  height: 22px;
  position: relative;
  top: -2px;
}

.grocery-list-checkbox {
  font-weight: 400;
  font-size: 18px;
}

.tootip-absolute {
  position: absolute;
  right: 0px;
  bottom: 2px;
}

.card-25 {
  flex: 0 0 23.8%;

  @media screen and (max-width: 768px) {
    flex: 0 0 50%;
  }

  @media screen and (min-width: 2000px) {
    flex: 0 0 24.3%;
  }
}

.card-50 {
  flex: 0 0 50%;

  @media screen and (max-width: 768px) {
    flex: 0 0 100%;
  }
}

.recipe-image-new {
  width: 100%;
  height: 150px;
  background-size: cover;
  background-position: center center;
}

.no-padding-modal {
  padding: 0 !important;
}

.input-label {
  font-size: 16px !important;

  @media screen and (min-width: 2000px) {
    font-size: 20px !important;
  }
}

.xs-full {
  @media screen and (max-width: 475px) {
    width: 100%;
  }
}

.aacp-save {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;

  &_title {
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    line-height: 25px;
  }

  &_text {
    text-transform: none;
    font-size: 18px;
    font-weight: 700;

    &-90 {
      width: 90%;
    }
  }
}

.success-modal-mock {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;

  &-gap {
    gap: 40.5px;
  }

  &_icon {
    width: 70px;
    height: 70px;
  }

  &_message {
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    line-height: 25px;
    text-transform: uppercase;
  }

  &_text {
    text-transform: none;
    font-size: 18px;
    font-weight: 700;
    text-align: center;

    &_80 {
      width: 70%;
      line-height: 22px;
    }
  }

  &_divider {
    width: 100%;
    height: 1px;
    background-color: black;
  }

  &_close {
    text-transform: none;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
  }
}