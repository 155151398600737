.mb-24 {
  margin-bottom: 24px;
}

.mt-39 {
  margin-top: 39px;
}

.g-gap10 {
  grid-gap: 10px !important;
}

.g-gap25 {
  grid-gap: 25px !important;
}

.g-gap18 {
  grid-gap: 18px !important;
}

.g-gap25 {
  grid-gap: 25px !important;
}

.pt-70 {
  padding-top: 70px !important;

  @media screen and (max-width: 475px) {
    padding-top: 30px !important;
  }
}

.pb-30 {
  @media screen and (max-width: 475px) {
    padding-bottom: 30px !important;
  }
}