* {
  padding: 0;
  margin: 0;
  outline: none;
  font-family: JosefinSans;
}

body {
  // font-family: MontserratSemiBold;
  font-family: JosefinSans;
  font-weight: 600;
  overflow-x: hidden;
  & > iframe {
    pointer-events: none;
  }
  // @media print {
  //     width: 210mm !important;
  //     height: 297mm !important;
  //     margin: 0 !important;
  //     padding:0 !important;
  //     border:0 !important;
  //     outline:0 !important;

  // }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: JosefinSans;
}

p {
  font-size: 14px;
}

a,
button {
  color: black;
  &:hover {
    cursor: pointer;
  }
}

@media print {
  body {
    -webkit-print-color-adjust: exact;
  }
}

// @page {
//   size: auto;
//   margin: 0;
// }
// @media print {

//   @page :footer {
//       display: none
//   }

//   @page :header {
//       display: none
//   }

//   @page {
//       margin-top: 0;
//       margin-bottom: 0;
//   }
// }

.pointer {
  cursor: pointer;
}

