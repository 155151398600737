.plans-grid,
.recipes-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 15px;
  margin-top: 40px;
  @media (max-width: 475px) {
    grid-template-columns: 1fr;
  }

  &.grid-4 {
    grid-template-columns: repeat(4, 1fr);
  }

  &.grid-2-2 {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
}
  
.recipes-big-grid {
  grid-template-columns: repeat(2, 1fr);
}

.recipes-grid {
  margin-top: 0;
}

.plan-grid {
  display: grid;
  grid-gap: 25px;
  grid-template-columns: repeat(7, 1fr);

  @media (max-width: 1150px) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media (max-width: 890px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 660px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 505px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 450px) {
    grid-template-columns: 1fr;
  }

  .plan-column {
    @media (max-width: 450px) {
      padding: 0 10px;
    }

    &:last-child .line {
      display: none;
    }
  }

  .row-title {
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 2px;
    margin-top: 30px;
    margin-bottom: -15px;
    min-height: 27px;
    display: flex;
    align-items: center;
    text-transform: uppercase;

    @media (max-width: 450px) {
      font-size: 17px;
      font-weight: 700;
      margin-top: 25px;
      margin-bottom: -20px;
    }
  }
}

.add-recipe {
  display: flex;
  @media (max-width: 1199px) {
    grid-template-columns: 1fr;

    & > div {
      display: none;

      &.visible {
        display: flex;
        flex-direction: column;
      }
    }
  }

  .recipes-grid {
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    width: auto;
    @media (max-width: 930px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: 700px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 475px) {
      grid-template-columns: 1fr;
    }

    &.grid-4 {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}

.shopping-cart-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 60px;
  grid-column-gap: 120px;
  position: relative;
  padding-top: 54px;

  @media (max-width: 768px) {
    border: 0;
    padding: 0;
    margin-top: 45px;
  }

  @media (max-width: 475px) {
    margin-top: 30px;
    grid-gap: 30px;
  }

  @media (max-width: 1200px) {
    grid-gap: 40px;
  }

  @media (max-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    grid-gap: 30px;
  }
}

.recipe-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 80px;
  grid-row-gap: 40px;
  margin-bottom: 60px;

  @media (max-width: 990px) {
    grid-template-columns: 1fr;
    grid-row-gap: 30px;
  }

  @media (max-width: 475px) {
    grid-row-gap: 25px;
  }
}

.rawkstars-user-grid {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 30px;

  @media (max-width: 990px) {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 475px) {
    grid-template-columns: 1fr;
  }
}

.wellness-grid {
  display: grid;
  grid-gap: 40px;
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: 1100px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    grid-gap: 30px;
  }
}
