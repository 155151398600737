
@font-face {
  font-family: MontserratRegular;
  src: url('../fonts/Montserrat-Regular.ttf');
}

@font-face {
  font-family: JosefinSans;
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/JosefinSans-Regular.ttf');
}

@font-face {
  font-family: JosefinSans;
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/JosefinSans-Medium.ttf');
}
@font-face {
  font-family: JosefinSans;
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/JosefinSans-SemiBold.ttf');
}
@font-face {
  font-family: JosefinSans;
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/JosefinSans-Bold.ttf');
}

// @font-face {
//   font-family: MontserratMedium;
//   src: url('../fonts/Montserrat-Medium.ttf');
// }

// @font-face {
//   font-family: MontserratMediumItalic;
//   src: url('../fonts/Montserrat-MediumItalic.ttf');
// }

// @font-face {
//   font-family: MontserratExtraBold;
//   src: url('../fonts/Montserrat-ExtraBold.ttf');
// }

// @font-face {
//   font-family: MontserratSemiBold;
//   src: url('../fonts/Montserrat-SemiBold.ttf');
// }

// @font-face {
//   font-family: MontserratLightItalic;
//   src: url('../fonts/Montserrat-LightItalic.ttf');
// }

// @font-face {
//   font-family: MontserratMediumItalic;
//   src: url('../fonts/Montserrat-MediumItalic.ttf');
// }

// @font-face {
//   font-family: MontserratItalic;
//   src: url('../fonts/Montserrat-Italic.ttf');
// }

// @font-face {
//   font-family: MontserratBold;
//   src: url('../fonts/Montserrat-Bold.ttf');
// }

// @font-face {
//   font-family: AvenirHeavy;
//   src: url('../fonts/Avenir-Heavy.ttf');
// }

// @font-face {
//   font-family: AvenirBlack;
//   src: url('../fonts/Avenir-Black.otf');
// }

// @font-face {
//   font-family: AvenirMedium;
//   src: url('../fonts/Avenir-Medium.otf');
// }

// @font-face {
//   font-family: AvenirBook;
//   src: url('../fonts/Avenir-Book.ttf');
// }

// @font-face {
//   font-family: WarnockSemiboldCapt;
//   src: url('../fonts/WarnockPro-SemiboldCapt.otf');
// }

// @font-face {
//   font-family: Distinct;
//   src: url('../fonts/DistinctStyleScript.otf');
// }

@font-face {
  font-family: OpenSansRegular;
  src: url('../fonts/OpenSans-Regular.ttf');
}
@font-face {
  font-family: OpenSansBold;
  src: url('../fonts/OpenSans-Bold.ttf');
}

@font-face {
  font-family: "Quentin";
  src: url('../fonts/Quentin.otf');
}

@font-face {
  font-family: "Gotham";
  src: url('../fonts/Gotham.ttf');
}
