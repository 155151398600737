.input-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  position: relative;

  .wrapper {
    display: flex;
    align-items: center;
    position: relative;

    &.disabled {
      opacity: 0.5;
    }

    .postfix {
      position: absolute;
      right: 15px;
      width: 30px;
      text-align: right;
      // font-family: MontserratMedium;
      font-weight: 500;
      font-size: 12px;
    }
  }

  label {
    margin-bottom: 10px;
    // font-family: MontserratSemiBold;
    font-weight: 600;
    font-size: 14px;
    color: black;
    @media (max-width: $breakpoint-mobile) {
      font-size: 16px;
    }
    @media screen and (min-width: 2000px) {
      font-size: 20px !important;
    }
  }

  input,
  .input {
    height: 50px;
    background-color: $light-gray;
    border: 1px solid rgba(0, 0, 0, 0.3);
    padding: 0 15px;
    flex-grow: 1;
    // font-family: MontserratMedium;
    font-weight: 500;
    max-width: calc(100% - 30px);

    font-size: 14px;

    @media screen and (min-width: 2000px) {
      font-size: 20px !important;
    }

    &.textarea {
      height: auto;
      padding: 15px;
    }

    &::placeholder {
      color: $gray;
    }

    &.with-postfix {
      padding-right: 60px;
      max-width: calc(100% - 75px);
    }
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    transition: background-color 5000s ease-in-out 0s;
    background: #fff;

    @media screen and (min-width: 2000px) {
      font-size: 20px;
    }
  }
}

.error {
  min-height: 15px;
  font-size: 10px;
  //font-family: MontserratRegular;
  line-height: 10px;
  font-weight: 400;
  margin-top: 5px;
  color: red;
}

.search-input,
.search-input-home {
  position: relative;
  display: flex;
  align-items: center;

  .input-container {
    width: 100%;
    margin-bottom: 0;

    .input {
      background-color: white;
      height: 40px;
      border: 1px solid #eaeaea;
      padding-right: 50px;

      &::placeholder {
        color: black;
      }
    }

    .error {
      display: none;
    }
  }

  .mark {
    position: absolute;
    right: 20px;
    width: 20px;
    aspect-ratio: 1/1;
    @media (max-width: 475px) {
      width: 12px;
    }
  }
}
.search-input-home {
  position: relative;
  width: 60%;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border: none;

  .input-container {
    width: 100%;
    margin-bottom: 0;
    @media (max-width: 475px) {
      font-size: 12px;
    }
    .input {
      border: none;
      padding-top: 7px;
      padding-bottom: 7px;

      &::placeholder {
        color: #000000;
        font-weight: 400;
        font-size: 16px;
        @media (max-width: 475px) {
          font-size: 12px;
        }
      }
    }
  }
}

.checkbox {
  display: flex;
  align-items: center;
  //font-family: MontserratRegular;
  font-weight: 500;
  font-size: 16px;
  text-transform: capitalize;
  sup {
    position: relative;
    top: 3px;
    font-size: 10px;
  }
  &.lowercase {
    text-transform: lowercase;
  }

  &.active {
    .box {
      background-color: $light-gray;
      border-color: transparent;
    }

    img {
      visibility: visible;
    }
  }

  &:hover {
    cursor: pointer;
  }
  .circle {
    min-width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid black;
    border-radius: 100%;
    margin-right: 15px;
  }
  .box {
    min-width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba($gray, 0.3);
    border-radius: 5px;
    margin-right: 15px;
  }

  img {
    visibility: hidden;
  }
}

.select-container {
  padding: 0 15px;
  border: 1px solid rgba(#000, 0.3);
  background-color: $light-gray;
  border-radius: 5px;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;

  &.open {
    @media (max-width: 768px) {
      box-shadow: 0 4px 10px 4px rgba($green, 0.06);
      border: 1px solid rgba($green, 0.1);
    }

    .arrow {
      transform: rotate(180deg);
    }
  }

  &.hoverable {
    .option:hover {
      background-color: rgba($green, 0.1);
      cursor: pointer;
    }
  }

  .value-container {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .arrow {
      transition: 0.3s ease-in-out;
    }

    .value {
      font-size: 12px;
      // font-family: MontserratMedium;
      font-weight: 500;
    }

    input {
      font-size: 12px;
      // font-family: MontserratMedium;
      font-weight: 500;
      height: 100%;
      width: 100%;
      margin-left: -15px;
      padding-left: 15px;
      border: none;
      background: transparent;

      &::placeholder {
        color: $gray;
      }
    }
  }

  .ReactCollapse--collapse {
    z-index: 2;

    @media (min-width: 768px) {
      position: absolute;
      width: 100%;
      left: -1px;
      bottom: -15px;
      background: white;
      transform: translateY(100%);
      border-radius: 10px;
      border: 1px solid rgba($gray, 0.3);
    }
  }

  .options {
    padding: 10px 0;

    @media (max-width: 768px) {
      border-top: 1px solid rgba($gray, 0.3);
    }

    @media (min-width: 768px) {
      max-height: 222px;
      overflow-y: scroll;
    }

    .option {
      padding: 10px 15px;
      font-size: 12px;
      //font-family: MontserratRegular;
      font-weight: 400;

      @media (max-width: 768px) {
        margin: 0 -15px;
      }
    }
  }
}
.little-notice {
  font-size: 15px;
  text-transform: lowercase;
  color: #000;
  // font-family: AvenirMedium;
  font-weight: 500;
  letter-spacing: 1.5px;

  @media screen and (min-width: 2000px) {
    font-size: 18px;
  }
}
p.plain {
  // font-family: MontserratMedium;
  font-weight: 300 !important;
  padding-top: 20px;
}

.new-input {
  &-wrapper {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    border-bottom: 2px solid black;
    padding: 0px 10px;

    &_active {
      border-bottom: 4px solid black;
    }

    & img {
      width: 19px;
      height: 19px;
    }
  }

  &-input {
    width: 100%;
    border: none;
    height: 40px;
    font-family: 'JosefinSans';
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
  }
}